import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DedicacionesModel } from '../models/dedicaciones/dedicaciones';
import { DedicacionesService } from '../services/dedicaciones.service';

@Component({
  selector: 'app-dedicaciones',
  templateUrl: './dedicaciones.component.html',
  styleUrls: ['./dedicaciones.component.css'],
})
export class DedicacionesComponent implements OnInit {
  currentTab: number = 0;
  Disabled: boolean = false;
  @ViewChild('btnModalError') btnModalError: ElementRef;
  @ViewChild('btnModalPre') btnModalPre: ElementRef;
  constructor(
    private spinner: NgxSpinnerService,
    private dedicacionesService: DedicacionesService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.showTab(this.currentTab);
    this.onCantidadDedicaciones();
  }

  nextPrev(value: number) {
    var x = document.getElementsByClassName('tab') as HTMLCollection;
    var element = x[this.currentTab] as HTMLElement;
    if (value == 1 && !this.validateForm()) return false;
    element.style.display = 'none';
    this.currentTab = this.currentTab + value;
    if (this.currentTab >= x.length) {
      return false;
    }
    this.showTab(this.currentTab);
  }
  showTab(n: number) {
    var x = document.getElementsByClassName('tab') as HTMLCollection;
    var element = x[n] as HTMLElement;
    element.style.display = 'block';
    if (n == 0) {
      document.getElementById('prevBtn').style.display = 'none';
    } else {
      document.getElementById('prevBtn').style.display = 'inline';
    }
    if (n == x.length - 1) {
      document.getElementById('page1Submit').style.display = 'inline';
      document.getElementById('nextBtn').style.display = 'none';
    } else {
      document.getElementById('nextBtn').innerHTML = 'Siguiente';
      document.getElementById('page1Submit').style.display = 'none';
      document.getElementById('nextBtn').style.display = 'inline';
    }
    this.fixStepIndicator(n);
  }
  validateForm() {
    var x,
      y,
      i,
      valid = true;
    x = document.getElementsByClassName('tab');
    y = x[this.currentTab].getElementsByTagName('input');
    for (i = 0; i < y.length; i++) {
      y[i].classList.remove('invalid');
      if (y[i].value == '' && y[i].required) {
        y[i].className += ' invalid';
        valid = false;
      }
    }
    y = x[this.currentTab].getElementsByTagName('select');
    for (i = 0; i < y.length; i++) {
      y[i].classList.remove('invalid');
      if ((y[i].value == '' || y[i].value == '-1') && y[i].required) {
        y[i].className += ' invalid';
        valid = false;
      }
    }
    if (valid) {
      document.getElementsByClassName('step')[this.currentTab].className +=
        ' finish';
    }
    return valid;
  }
  fixStepIndicator(n: number) {
    var i,
      x = document.getElementsByClassName('step');
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(' active', '');
    }
    x[n].className += ' active';
  }
  onRegistrar() {
    if (this.validateForm()) {
      this.onCrearSolicitud();
    }
  }
  onCantidadDedicaciones() {
    this.spinner.show();
    this.dedicacionesService.Get().subscribe((datos) => {
      if (datos >= 6) {
        this.Disabled = true;
        this.btnModalError.nativeElement.click();
        this.toastr.error(
          'Ya se completó las dedicaciones para esta semana, intente nuevamente la siguiente semana',
          '¡Atención!'
        );
      }
      this.spinner.hide();
    });
  }
  onCrearSolicitud() {
    this.spinner.show();
    var fatherName = (document.getElementById('fatherName') as HTMLInputElement)
      .value;
    var fatherEmail = (
      document.getElementById('fatherEmail') as HTMLInputElement
    ).value;
    var fatherPhone = (
      document.getElementById('fatherPhone') as HTMLInputElement
    ).value;
    var childrenName1 = (
      document.getElementById('childrenName1') as HTMLInputElement
    ).value;
    var childrenAge1 = (
      document.getElementById('childrenAge1') as HTMLSelectElement
    ).value;
    var childrenName2 = (
      document.getElementById('childrenName2') as HTMLInputElement
    ).value;
    var childrenAge2 = (
      document.getElementById('childrenAge2') as HTMLInputElement
    ).value;
    var childrenName3 = (
      document.getElementById('childrenName3') as HTMLInputElement
    ).value;
    var childrenAge3 = (
      document.getElementById('childrenAge3') as HTMLInputElement
    ).value;
    var dedicaciones = new DedicacionesModel();
    dedicaciones.fatherName = fatherName;
    dedicaciones.fatherEmail = fatherEmail;
    dedicaciones.fatherPhone = fatherPhone;
    dedicaciones.childrenName1 = childrenName1;
    dedicaciones.childrenAge1 = childrenAge1;
    dedicaciones.childrenName2 = childrenName2 == '' ? null : childrenName2;
    dedicaciones.childrenAge2 = childrenAge2 == '' ? null : childrenAge2;
    dedicaciones.childrenName3 = childrenName3 == '' ? null : childrenName3;
    dedicaciones.childrenAge3 = childrenAge3 == '' ? null : childrenAge3;
    let request: {} = { dedicaciones };
    this.dedicacionesService.Create(dedicaciones).subscribe((datos) => {
      if(datos != null && datos.codigo == 0){
        (document.getElementById('fatherName') as HTMLInputElement).value = "";
        (document.getElementById('fatherEmail') as HTMLInputElement).value = "";
        (document.getElementById('fatherPhone') as HTMLInputElement).value = "";
        (document.getElementById('childrenName1') as HTMLInputElement).value = "";
        (document.getElementById('childrenAge1') as HTMLInputElement).value = "";
        (document.getElementById('childrenName2') as HTMLInputElement).value = "";
        (document.getElementById('childrenAge2') as HTMLInputElement).value = "";
        (document.getElementById('childrenName3') as HTMLInputElement).value = "";
        (document.getElementById('childrenAge3') as HTMLInputElement).value = "";
        this.nextPrev(-1);
        this.Disabled = true;
        this.btnModalPre.nativeElement.click();
      }else{
        this.toastr.error(
          'Error, vuelva a intentarlo',
          '¡Atención!'
        );
      }
      this.spinner.hide();
    });
  }
}
