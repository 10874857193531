<section class="home-section home-parallax home-fade home-full-height gb-parallax" id="home"
  data-background="assets/images/background/16.jpg"
  style="background-image: url('assets/images/background/16.jpg'); height: 100vh;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
      <div class="col-sm-8 col-md-6 col-lg-6">
        <img src="assets/images/background/logozk.png" />
        <p class="edades" >Grupo de niños hasta los 11 años</p>
        <div class="font-alt mb-10 titan-title-size-2" style="margin-top: 15px;">
          <a target="_blank" href="https://www.facebook.com/zkemmanuel"
            style="color: #000000;font-size: 25px;margin: 2px 10px;"><i class="fa fa-facebook-official"
              aria-hidden="true"></i></a>
          <a target="_blank" href="https://www.instagram.com/zonakidsemmanuel/"
            style="color: #000000;font-size: 25px;margin: 2px 10px;"><i class="fa fa-instagram"
              aria-hidden="true"></i></a>
          <a target="_blank" href="https://www.youtube.com/channel/UCmbM-CZ7sxBf61zglPpXYdw"
            style="color: #000000;font-size: 25px;margin: 2px 10px;"><i class="fa fa-youtube-play"
              aria-hidden="true"></i></a>
        </div>
      </div>
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
    </div>
  </div>
</section>
<div class="main">
  <section class="module zk1 parallax-ibe" id="about"
    style="background-image: url('assets/images/background/17.jpg');">
    <div class="container mb-80">
      <div class="row">
        <div class="col-12">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <p class="reuZKTitulo1">
                ACOMPAÑAMOS CON AMOR Y DIVERSIÓN A LOS NIÑOS
                MIENTRAS CONOCEN A JESÚS Y CONSTRUYEN SU
                IDENTIDAD Y PROPÓSITO EN ÉL.
              </p>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
        <div class="col-12">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <br><br>
              <div class="full">
                <img src="assets/images/background/titulozk0.png" />
              </div>
              <div class="movil">
                <img src="assets/images/background/titulozk1.png" />
              </div>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
        <div class="col-12">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <br>
              <p class="text001zk">
                Los estrenamos en nuestro canal
                de Youtube los segundos y cuartos sábados de
                cada mes, a las 11am (PE) . 
                <br><br>
                Suscríbete para que no te
                pierdas ninguno.
              </p>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
        <div class="col-12">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <a target="_blank" href="https://www.youtube.com/channel/UCmbM-CZ7sxBf61zglPpXYdw" style="color: #000000;font-size: 20px;text-align: center;font-family:
                Montserrat-Italic;font-weight: 600;">
                <p><i class="fa fa-youtube-play"></i> Zona Kids Emmanuel</p>
              </a>
              <div style="text-align: center;">
                <a class="section-scroll btn btn-border-w btn-round"
                  href="https://www.youtube.com/channel/UCmbM-CZ7sxBf61zglPpXYdw?sub_confirmation=1" target="_blank"
                  style="font-family: Montserrat-BoldItalic;font-size: 15px;text-align: center;
                  background: url('assets/images/background/btnzk.jpg') no-repeat;
                  background-size: 100% 100%; border-color: #000000; border-radius: 7px;">SUSCRÍBETE</a>
              </div>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module zk1 parallax-ibe" id="about" style="background-image: url('assets/images/background/18.jpg');">
    <div class="container mb-10">
      <div class="row">
        <div class="col-12">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <br><br>
              <div class="full">
                <img src="assets/images/background/titulozk2.png" />
              </div>
              <div class="movil">
                <img src="assets/images/background/titulozk3.png" />
              </div>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
        <div class="col-12">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <br>
              <p class="text001zk">
                Nos reunimos
                vía Zoom a partir de los 3 años de
                edad los domingos a las:<br>
                9am, 11am y 4pm (PE)
              </p>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
        <div class="col-12">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <div style="text-align: center;">
                <a class="section-scroll btn btn-border-w btn-round" href="https://forms.gle/vgcCXAkF5PYJThfPA" target="_blank" style="font-family: Montserrat-BoldItalic;font-size: 15px;text-align: center;
                  background: url('assets/images/background/btnzk1.jpg') no-repeat;
                  background-size: 100% 100%; border-color: #000000; border-radius: 7px;
                  color: #000000;">INSCRÍBETE</a>
              </div>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module zk1 gb-parallax" id="about"
    style="background-image: url('assets/images/background/19.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-1"></div>
            <div class="col-sm-8 col-md-7">
              <img src="assets/images/background/titulozk4.png" />
            </div>
            <div class="col-sm-2 col-md-4"></div>
          </div>
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-8">
              <br>
              <p class="text002zk">
                ¡Síguenos!, no te pierdas de todos
                los anuncios y eventos que
                tenemos para ti.
              </p>
            </div>
            <div class="col-sm-2 col-md-1"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-1"></div>
            <div class="col-sm-8 col-md-10">
              <br>
              <p class="reuZKTitulo2">
                <a href="https://www.facebook.com/zkemmanuel" target="_blank">
                  zkemmanuel <i class="fa fa-facebook-official" aria-hidden="true"></i>
                </a><br>
                <a href="https://www.instagram.com/zonakidsemmanuel/" target="_blank">
                  zonakidsemmanuel <i class="fa fa-instagram" aria-hidden="true"></i>
                </a><br>
                <a href="https://www.youtube.com/channel/UCmbM-CZ7sxBf61zglPpXYdw" target="_blank">
                  Zona Kids Emmanuel <i class="fa fa-youtube-play" aria-hidden="true"></i>
                </a>
              </p>
            </div>
            <div class="col-sm-2 col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
