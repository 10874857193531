import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConferenciaService } from 'src/app/services/conferencia.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login-conferencia',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private conferenciaService: ConferenciaService,
    @Inject(DOCUMENT) private document: any,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
  }

  btnIngresar() {
    this.spinner.show();
    document.getElementById('campoObligatorio').style.display = 'none';
    document.getElementById('incorrecto').style.display = 'none';
    document.getElementById('errorGenerico').style.display = 'none';
    document.getElementById('errorDoble').style.display = 'none';
    const usuario = (document.getElementById('usuario') as HTMLInputElement).value;
    const password = (document.getElementById('password') as HTMLInputElement).value;
    if (usuario === "" || password === "") {
      this.spinner.hide();
      document.getElementById('campoObligatorio').style.display = 'block';
      return;
    }
    const jsonString = '{"user":"' + usuario.toLowerCase() + '", "pass":"' + password + '"}';
    const request = JSON.parse(jsonString);
    this.conferenciaService.login(request).subscribe(datos => {
      this.spinner.hide();
      if (datos.code === 204) {
        var data = datos.cookies;
        data.forEach((valor, key) => {
          this.cookieService.set(valor.key, valor.value, 2, "/en-vivo/", "emmanuel.pe", true);
        });

        // window.location.replace("http://localhost:4200/reuniones");
        window.location.href = "https://emmanuel.pe/en-vivo";
      }
      else if (datos.code === 203) {
        document.getElementById('errorDoble').style.display = 'block';
      }
      else if (datos.code === 401) {
        document.getElementById('incorrecto').style.display = 'block';
      } else {
        document.getElementById('errorGenerico').style.display = 'block';
      }
    })
  }

}
