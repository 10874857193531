<section class="home-section home-fade backgr" id="home" data-background="assets/images/background/47.jpg"
  style="background-image: url('assets/images/background/47.jpg');">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
      <div class="col-sm-8 col-md-6 col-lg-6">
        <div class="col-md-12">
          <form action="envioCorreo1.php" method="POST" id="emailform">
            <div class="col-md-12 form-row formBackground">
              <div class="form-group col-md-12">
                <input type="text" class="form-control" id="nombreApellido" name="nombreApellido"
                  placeholder="NOMBRES Y APELLIDOS">
              </div>
              <div class="form-group col-md-4">
                <select id="tipoRed" name="tipoRed" class="form-control">
                  <option selected disabled value="0">TIPO DE RED</option>
                  <option value="DAMA">DAMA</option>
                  <option value="VARONES">VARONES</option>
                  <option value="MIXTA">MIXTA</option>
                  <option value="MATRIMONIO">MATRIMONIO</option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <input type="number" class="form-control" id="edad" name="edad" placeholder="EDAD">
              </div>
              <!-- <div class="form-group col-md-4">
                <select id="estadoCivil" name="estadoCivil" class="form-control">
                  <option selected disabled value="0">ESTADO CIVIL</option>
                  <option value="SOLTERO">SOLTERO</option>
                  <option value="CASADO">CASADO</option>
                  <option value="CONVIVIENTE">CONVIVIENTE</option>
                  <option value="DIVORCIADO / SEPARADO">DIVORCIADO SEPARADO</option>
                  <option value="VIUDO">VIUDO</option>
                </select>
              </div> -->
              <!-- <div class="form-group col-md-4">
                <input type="text" class="form-control" id="pais" name="pais" placeholder="PAÍS">
              </div>
              <div class="form-group col-md-4">
                <input type="text" class="form-control" id="departamento" name="departamento" placeholder="CIUDAD / DPTO.">
              </div> -->
              <div class="form-group col-md-4">
                <input type="text" class="form-control" id="distrito" name="distrito" placeholder="DISTRITO">
              </div>
              <div class="form-group col-md-4">
                <input type="text" class="form-control" id="celular" name="celular" placeholder="CELULAR">
              </div>
              <div class="form-group col-md-8">
                <input type="email" class="form-control" id="correo" name="correo" placeholder="CORREO ELECTRÓNICO">
              </div>
              <div class="form-group col-md-12">
                <textarea class="form-control" name="observaciones" id="observaciones"
                  placeholder="OBSERVACIONES"></textarea>
              </div>
              <div class="form-group col-md-12" style="height: auto;
              display: flex;
              align-items: center;">
                <input class="form-check-input checkPP" type="checkbox" id="politicas">
                <label class="form-check-label gridppAA" for="politicas" style="padding-top: 1%;
                text-align: left;
                color: #ffffff;
                padding-left: 10px;">
                  Doy consentimiento para que la Iglesia Emmanuel almacene mis datos, y de esta manera
                  poder contactarse conmigo y facilitar mi próximo paso en la fe.
                </label>
              </div>
              <p style="font-family: 'Montserrat-Medium'; font-size: 13px; color: red;display: none;text-align: left;"
                id="alert">
                Acepte el consentimiento para el almacenamiento de datos
              </p>
              <p style="font-family: 'Montserrat-Medium'; font-size: 13px; color: red;display: none;text-align: left;"
                id="alert1">
                Todos los campos son obligatorios
              </p>
            </div>
            <div class="form-group col-md-12">
              <a (click)="onSubmit()" class="btn btn-primary">ENVIAR</a>
            </div>
          </form>
        </div>
      </div>
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
    </div>
  </div>
</section>
