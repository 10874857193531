<section class="parallax-ibe background" id="home">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-sm-12 col-md-12">
        <div class="row">
          <div class="col-sm-2 col-md-4"></div>
          <div class="col-sm-8 col-md-4">
            <p class="titulo">
              INTERIOR
            </p>
            <div class="row" style="display: flex">
              <div class="col-xs-6 my-auto">
                <p class="subtitulo">
                  13NOV
                </p>
              </div>
              <div class="col-xs-6">
                <p class="subtitulo1">
                  SÁBADO<br>
                  13 DE NOVIEMBRE<br>
                  H-R-E-M-D-D<br>
                  ****N
                </p>
              </div>
            </div>
            <app-formulario-reunion-presencial [idTipoEvento]="16"></app-formulario-reunion-presencial>
          </div>
          <div class="col-sm-2 col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
</section>

