<section class="home-section home-parallax home-fade home-full-height gb-parallax" id="home"
  style="background-color: #F3F3F3; height: 100vh;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
      <div class="col-sm-8 col-md-6 col-lg-6">
        <img src="assets/images/background/bb-singular1.png" />
        <div style="text-align: center;" hidden >
          <a class="section-scroll btn btn-border-w btn-round" target="_blank"
            href="https://bit.ly/3RhcKl5" style="font-family: FeltTipRomHeavy;font-size: 19px;text-align: center; margin: 15px;
            background-color: #1952B6;border: #1952B6; color: #ffffff; padding-left: 7%; padding-right: 7%;border-radius: 5px;
            margin-left: 5%;margin-right: 5%; border-radius: 20px;">Presencial</a>

          <a class="section-scroll btn btn-border-w btn-round" target="_blank"
            href="https://bit.ly/403mKm3" style="font-family: FeltTipRomHeavy;font-size: 19px;text-align: center; margin: 15px;
            background-color: #FE2360;border: #FE2360; color: #ffffff; ;padding-left: 7%; padding-right: 7%;border-radius: 5px;
            margin-left: 5%;margin-right: 5%; border-radius: 20px;">Virtual</a>
        </div>
      </div>
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
    </div>
  </div>
</section>
<div class="main">
  <section class="module" id="about" style="background-image: url('assets/images/background/singular_bg1.jpg'); background-size: cover;">
    <div class="container">
      <div class="row mb-40">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <p class="text01">
                Singular es la celebración que cada año
                realizamos con mujeres de diversas edades y que juntas disfrutamos del amor de Dios.
                Es una linda oportunidad para venir con tus amigas y también poder hacer nuevas
                amistades.
                <br>
                <br>
                ¡Te esperamos en Singular!
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </section>
  <section class="module" id="about"
    style="background-image: url('assets/images/background/singular_bg2.jpg'); background-size: cover;">
    <div class="container">
      <div class="row mb-40">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12">
              <img src="assets/images/background/bb-singular2.png" />
            </div>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <p class="titleHour">
                SÁBADO 04 MARZO
              </p>
              <p class="hour">
                Registro · 9 a.m.<br>
                Inicio · 10 a.m.<br>
                Fin · 9 p.m.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </section>
  <section hidden class="module" id="about" style="background-image: url('assets/images/singular1.jpg'); background-size: cover;">
    <div class="container">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <p class="text03">
                Inscríbete aquí
              </p>
            </div>
            <div class="col-md-12">
              <div style="text-align: center;">
                <a class="section-scroll btn btn-border-w btn-round" target="_blank"
                  href="https://bit.ly/3RhcKl5" style="font-family: FeltTipRomHeavy;font-size: 19px;text-align: center; margin: 15px;
                  background-color: #1952B6;border: #1952B6; color: #ffffff; padding-left: 7%; padding-right: 7%;border-radius: 5px;
                  margin-left: 5%;margin-right: 5%; border-radius: 20px;">Presencial</a>

                <a class="section-scroll btn btn-border-w btn-round" target="_blank"
                  href="https://bit.ly/403mKm3" style="font-family: FeltTipRomHeavy;font-size: 19px;text-align: center; margin: 15px;
                  background-color: #FE2360;border: #FE2360; color: #ffffff; ;padding-left: 7%; padding-right: 7%;border-radius: 5px;
                  margin-left: 5%;margin-right: 5%; border-radius: 20px;">Virtual</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </section>
</div>
