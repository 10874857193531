<div class="contenedor">
  <div class="tab">
    <p class="titulo">Datos de identificación:</p>
    <div class="contenedorInput">
      <select class="form-control" id="evento" style="width: 100%;" (change)="onEscogerEvento()" required>
        <option disabled selected value="-1">Seleccione un evento (*)</option>
      </select>
    </div>
    <div class="contenedorInput">
      <select class="form-control" name="nacionalidad" id="nacionalidad" (change)="onChangeNacionalidad()" required style="width: 100%;">
        <option disabled selected value>Seleccione su nacionalidad (*)</option>
        <option value='175'>Peru</option>
        <option value='3'>Afghanistan, Islamic State of</option>
        <option value='6'>Albania</option>
        <option value='63'>Algeria</option>
        <option value='12'>American Samoa</option>
        <option value='1'>Andorra, Principality of</option>
        <option value='9'>Angola</option>
        <option value='5'>Anguilla</option>
        <option value='10'>Antarctica</option>
        <option value='4'>Antigua and Barbuda</option>
        <option value='11'>Argentina</option>
        <option value='7'>Armenia</option>
        <option value='15'>Aruba</option>
        <option value='14'>Australia</option>
        <option value='13'>Austria</option>
        <option value='17'>Azerbaijan</option>
        <option value='33'>Bahamas</option>
        <option value='24'>Bahrain</option>
        <option value='20'>Bangladesh</option>
        <option value='19'>Barbados</option>
        <option value='37'>Belarus</option>
        <option value='21'>Belgium</option>
        <option value='38'>Belize</option>
        <option value='26'>Benin</option>
        <option value='28'>Bermuda</option>
        <option value='34'>Bhutan</option>
        <option value='30'>Bolivia</option>
        <option value='31'>Bonaire, Sint Eustatius and Saba</option>
        <option value='18'>Bosnia-Herzegovina</option>
        <option value='36'>Botswana</option>
        <option value='35'>Bouvet Island</option>
        <option value='32'>Brazil</option>
        <option value='106'>British Indian Ocean Territory</option>
        <option value='29'>Brunei Darussalam</option>
        <option value='23'>Bulgaria</option>
        <option value='22'>Burkina Faso</option>
        <option value='25'>Burundi</option>
        <option value='117'>Cambodia, Kingdom of</option>
        <option value='48'>Cameroon</option>
        <option value='39'>Canada</option>
        <option value='53'>Cape Verde</option>
        <option value='124'>Cayman Islands</option>
        <option value='41'>Central African Republic</option>
        <option value='216'>Chad</option>
        <option value='47'>Chile</option>
        <option value='49'>China</option>
        <option value='55'>Christmas Island</option>
        <option value='40'>Cocos (Keeling) Islands</option>
        <option value='50'>Colombia</option>
        <option value='119'>Comoros</option>
        <option value='43'>Congo</option>
        <option value='42'>Congo, Democratic Republic of the</option>
        <option value='46'>Cook Islands</option>
        <option value='51'>Costa Rica</option>
        <option value='98'>Croatia</option>
        <option value='52'>Cuba</option>
        <option value='54'>Curaçao</option>
        <option value='56'>Cyprus</option>
        <option value='57'>Czech Republic</option>
        <option value='60'>Denmark</option>
        <option value='59'>Djibouti</option>
        <option value='61'>Dominica</option>
        <option value='62'>Dominican Republic</option>
        <option value='225'>East Timor</option>
        <option value='64'>Ecuador</option>
        <option value='66'>Egypt</option>
        <option value='211'>El Salvador</option>
        <option value='88'>Equatorial Guinea</option>
        <option value='68'>Eritrea</option>
        <option value='65'>Estonia</option>
        <option value='70'>Ethiopia</option>
        <option value='73'>Falkland Islands</option>
        <option value='75'>Faroe Islands</option>
        <option value='72'>Fiji</option>
        <option value='71'>Finland</option>
        <option value='76'>France</option>
        <option value='80'>French Guyana</option>
        <option value='217'>French Southern Territories</option>
        <option value='77'>Gabon</option>
        <option value='85'>Gambia</option>
        <option value='79'>Georgia</option>
        <option value='58'>Germany</option>
        <option value='81'>Ghana</option>
        <option value='82'>Gibraltar</option>
        <option value='89'>Greece</option>
        <option value='84'>Greenland</option>
        <option value='78'>Grenada</option>
        <option value='87'>Guadeloupe (French)</option>
        <option value='92'>Guam (USA)</option>
        <option value='91'>Guatemala</option>
        <option value='83'>Guernsey</option>
        <option value='86'>Guinea</option>
        <option value='93'>Guinea Bissau</option>
        <option value='94'>Guyana</option>
        <option value='99'>Haiti</option>
        <option value='96'>Heard and McDonald Islands</option>
        <option value='238'>Holy See (Vatican City State)</option>
        <option value='97'>Honduras</option>
        <option value='95'>Hong Kong</option>
        <option value='100'>Hungary</option>
        <option value='109'>Iceland</option>
        <option value='105'>India</option>
        <option value='101'>Indonesia</option>
        <option value='108'>Iran</option>
        <option value='107'>Iraq</option>
        <option value='102'>Ireland</option>
        <option value='104'>Isle of Man</option>
        <option value='103'>Israel</option>
        <option value='110'>Italy</option>
        <option value='45'>Ivory Coast (Cote D'Ivoire)</option>
        <option value='112'>Jamaica</option>
        <option value='114'>Japan</option>
        <option value='111'>Jersey</option>
        <option value='113'>Jordan</option>
        <option value='125'>Kazakhstan</option>
        <option value='115'>Kenya</option>
        <option value='118'>Kiribati</option>
        <option value='123'>Kuwait</option>
        <option value='116'>Kyrgyz Republic (Kyrgyzstan)</option>
        <option value='126'>Laos</option>
        <option value='135'>Latvia</option>
        <option value='127'>Lebanon</option>
        <option value='132'>Lesotho</option>
        <option value='131'>Liberia</option>
        <option value='136'>Libya</option>
        <option value='129'>Liechtenstein</option>
        <option value='133'>Lithuania</option>
        <option value='134'>Luxembourg</option>
        <option value='148'>Macau</option>
        <option value='144'>Macedonia, the former Yugoslav Republic of</option>
        <option value='142'>Madagascar</option>
        <option value='156'>Malawi</option>
        <option value='158'>Malaysia</option>
        <option value='155'>Maldives</option>
        <option value='145'>Mali</option>
        <option value='153'>Malta</option>
        <option value='143'>Marshall Islands</option>
        <option value='150'>Martinique (French)</option>
        <option value='151'>Mauritania</option>
        <option value='154'>Mauritius</option>
        <option value='248'>Mayotte</option>
        <option value='157'>Mexico</option>
        <option value='74'>Micronesia</option>
        <option value='139'>Moldavia</option>
        <option value='138'>Monaco</option>
        <option value='147'>Mongolia</option>
        <option value='140'>Montenegro</option>
        <option value='152'>Montserrat</option>
        <option value='137'>Morocco</option>
        <option value='159'>Mozambique</option>
        <option value='146'>Myanmar</option>
        <option value='160'>Namibia</option>
        <option value='169'>Nauru</option>
        <option value='168'>Nepal</option>
        <option value='166'>Netherlands</option>
        <option value='8'>Netherlands Antilles</option>
        <option value='170'>Neutral Zone</option>
        <option value='161'>New Caledonia (French)</option>
        <option value='172'>New Zealand</option>
        <option value='165'>Nicaragua</option>
        <option value='162'>Niger</option>
        <option value='164'>Nigeria</option>
        <option value='171'>Niue</option>
        <option value='163'>Norfolk Island</option>
        <option value='121'>North Korea</option>
        <option value='149'>Northern Mariana Islands</option>
        <option value='167'>Norway</option>
        <option value='173'>Oman</option>
        <option value='179'>Pakistan</option>
        <option value='186'>Palau</option>
        <option value='184'>Palestinian Territory, Occupied</option>
        <option value='174'>Panama</option>
        <option value='177'>Papua New Guinea</option>
        <option value='187'>Paraguay</option>
        <option value='178'>Philippines</option>
        <option value='182'>Pitcairn Island</option>
        <option value='180'>Poland</option>
        <option value='176'>Polynesia (French)</option>
        <option value='185'>Portugal</option>
        <option value='183'>Puerto Rico</option>
        <option value='188'>Qatar</option>
        <option value='189'>Reunion (French)</option>
        <option value='190'>Romania</option>
        <option value='192'>Russian Federation</option>
        <option value='193'>Rwanda</option>
        <option value='27'>Saint Barthélémy</option>
        <option value='200'>Saint Helena</option>
        <option value='120'>Saint Kitts & Nevis Anguilla</option>
        <option value='128'>Saint Lucia</option>
        <option value='141'>Saint Martin (French part)</option>
        <option value='181'>Saint Pierre and Miquelon</option>
        <option value='210'>Saint Tome (Sao Tome) and Principe</option>
        <option value='239'>Saint Vincent & Grenadines</option>
        <option value='246'>Samoa</option>
        <option value='205'>San Marino</option>
        <option value='194'>Saudi Arabia</option>
        <option value='206'>Senegal</option>
        <option value='191'>Serbia</option>
        <option value='196'>Seychelles</option>
        <option value='204'>Sierra Leone</option>
        <option value='199'>Singapore</option>
        <option value='212'>Sint Maarten (Dutch part)</option>
        <option value='203'>Slovakia</option>
        <option value='201'>Slovenia</option>
        <option value='195'>Solomon Islands</option>
        <option value='207'>Somalia</option>
        <option value='250'>South Africa</option>
        <option value='90'>South Georgia and the South Sandwich Islands</option>
        <option value='122'>South Korea</option>
        <option value='209'>South Sudan</option>
        <option value='69'>Spain</option>
        <option value='130'>Sri Lanka</option>
        <option value='197'>Sudan</option>
        <option value='208'>Suriname</option>
        <option value='202'>Svalbard and Jan Mayen Islands</option>
        <option value='214'>Swaziland</option>
        <option value='198'>Sweden</option>
        <option value='44'>Switzerland</option>
        <option value='213'>Syria</option>
        <option value='229'>Taiwan</option>
        <option value='220'>Tajikistan</option>
        <option value='230'>Tanzania</option>
        <option value='219'>Thailand</option>
        <option value='218'>Togo</option>
        <option value='221'>Tokelau</option>
        <option value='224'>Tonga</option>
        <option value='227'>Trinidad and Tobago</option>
        <option value='223'>Tunisia</option>
        <option value='226'>Turkey</option>
        <option value='222'>Turkmenistan</option>
        <option value='215'>Turks and Caicos Islands</option>
        <option value='228'>Tuvalu</option>
        <option value='234'>USA Minor Outlying Islands</option>
        <option value='232'>Uganda</option>
        <option value='231'>Ukraine</option>
        <option value='2'>United Arab Emirates</option>
        <option value='233'>United Kingdom</option>
        <option value='235'>United States</option>
        <option value='236'>Uruguay</option>
        <option value='237'>Uzbekistan</option>
        <option value='244'>Vanuatu</option>
        <option value='240'>Venezuela</option>
        <option value='243'>Vietnam</option>
        <option value='241'>Virgin Islands (British)</option>
        <option value='242'>Virgin Islands (USA)</option>
        <option value='245'>Wallis and Futuna Islands</option>
        <option value='67'>Western Sahara</option>
        <option value='247'>Yemen</option>
        <option value='249'>Yugoslavia</option>
        <option value='252'>Zaire</option>
        <option value='251'>Zambia</option>
        <option value='253'>Zimbabwe</option>
      </select>
    </div>
    <div class="contenedorInput">
      <select class="form-control" name="tipdocident" id="tipdocident" required
        style="width: 100%;">
        <option disabled selected value>Tipo documento (*)</option>
      </select>
    </div>
    <div class="contenedorInput">
      <input type="text" class="form-control" name="numdoc" id="numdoc"
        aria-describedby="numdocHelpId" placeholder="Numero de documento (*)" required style="width: 100%;">
        <p style="text-align: left; color:#ffffff; display: none;" id="notaNumDoc"></p>
    </div>
  </div>
  <div class="tab">
    <p class="titulo">Datos personales:</p>
    <div class="contenedorInput">
      <input type="text" class="form-control" name="apellido_paterno" id="apellido_paterno"
        aria-describedby="apellido_paternoHelpId" placeholder="Apellido Paterno (*)" required>
    </div>
    <div class="contenedorInput">
      <input type="text" class="form-control" name="apellido_materno" id="apellido_materno"
        aria-describedby="apellido_maternoHelpId" placeholder="Apellido Materno (*)" required>
    </div>
    <div class="contenedorInput">
      <input type="text" class="form-control" name="nombre1" id="nombre1" aria-describedby="nombre1HelpId"
        placeholder="Primer Nombre (*)" required>
    </div>
    <div class="contenedorInput">
      <input type="text" class="form-control" name="nombre2" id="nombre2" aria-describedby="nombre2HelpId"
        placeholder="Segundo Nombre">
    </div>
    <div class="contenedorInput">
      <input type="email" class="form-control" name="email" id="email" placeholder="Correo Electrónico (*)"
        aria-describedby="emailHelpId" required>
    </div>
    <div class="contenedorInput">
      <input type="text" class="form-control" name="fecha_nacimiento" id="fecha_nacimiento" placeholder="Fecha de nacimiento (yyyy-mm-dd)"
        required (keyup)="formatoFecha()" maxlength="10">
    </div>
    <!-- <div class="contenedorInput">
      <input type="text" class="form-control" name="mobile" id="mobile" placeholder="Teléfono de contacto"
        aria-describedby="mobileHelpId" pattern="[0-9]{9}">
    </div> -->
    <input type="hidden" id="page1" />
    <input type="hidden" id="persona" value="0" />
    <input type="hidden" id="evento_name" />
    <input type="hidden" id="persona_name" />
  </div>
  <div style="overflow:auto;">
    <div class="col-md-12">
      <div class="row">
        <div class="col-xs-6" style="padding: 0px;">
          <p style="text-align: left; margin: 0px;">
            <button type="button" id="prevBtn" (click)="nextPrev(-1)">Atras</button>
          </p>
        </div>
        <div class="col-xs-6" style="padding: 0px;">
          <p style="text-align: right; margin: 0px;">
            <button type="button" id="nextBtn" (click)="onConsultaPersona()">Siguiente</button>
          </p>
          <p style="text-align: right; margin: 0px;">
            <button type="button" id="page1Submit" (click)="onRegistrar()" style="display: none;">Registrar</button>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div style="text-align:center;margin-top:20px;">
    <span class="step"></span>
    <span class="step"></span>
  </div>
</div>

<ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="#fff" type="ball-running-dots">
  <p style="font-size: 20px; color: #ffffff">Validando...</p>
</ngx-spinner>
<button #btnModalPre class="section-scroll btn btn-border-w btn-round" data-toggle="modal"
  data-target="#exampleModal" style="display: none;">Abrir modal 1</button>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="background-color: #ffffff;
                background-repeat: no-repeat;
                background-size: cover;">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel" style="color: #000000;text-align: center;">
          ¡Felicidades <span id="nombreModal"></span>!
        </h4>
        <button style="color: #ffffff;" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="content-modal">
          <div class="row">
            <div class="col-md-12">
              <p style="font-size: 17px; color: #000000; margin: 0px;">Te registraste correctamente al evento:</p>
              <p style="font-size: 17px; color: #000000;"><b id="tituloNombre">TEST - 2021-11-07
                  de 10.0hrs. a 10.0hrs.</b></p>
              <div class="row">
                <div class="col-xs-2 col-md-4"></div>
                <div class="col-xs-8 col-md-4">
                  <img id="imagenQR" width="100%" />
                </div>
                <div class="col-xs-2 col-md-4"></div>
              </div>
              <div id="entrada_html">
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal"
            style="background-color: #000000;color: #ffffff; border: #ffffff 1px solid;">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>
