import { Component, OnInit } from '@angular/core';
import { SigeService } from '../../services/sige.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {
  datos:any;
  constructor() { }
  // constructor(private sigeService:SigeService) { }

  ngOnInit(): void {
    // const jsonString = '{"tipdocide":"DNI", "numdoc":"70213158", "action":"buscarPersona"}';
    // const request = JSON.parse(jsonString);
    // this.sigeService.consultaUsuarioSige(request).subscribe(datos=>{
    //   console.log(datos);
    //   this.datos = datos;
    // })
  }
}
