export class PersonaModel {
  id: number;
  apellido_paterno: string;
  apellido_materno: string;
  fecha_nacimiento: string;
  nombre1: string;
  nombre2: string;
  email: string;
  nacionalidad: number;
  numdoc: string;
  tipdocident: string;
  country_id: number;
  state_id: number;
  substate_id: number;
  district_id: number;
  tipoanexo: string;
  is_company: boolean;
  codigo_persona: string;
  asiste_iglesia: boolean;
  active: boolean;
  name: string;
}
