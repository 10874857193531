import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http:HttpClient) { }

  envioEmailClaseMagistral(request: any){
    const headers = new HttpHeaders();
		headers.append('Accept', 'application/json');
		headers.append('Content-Type', 'application/json');
    return this.http.post<any>(environment.end_point_backend + "/email/claseMagistral/contacto", request, { headers }).pipe(
      map(datos=>{
        return datos;
      })
    );
  }
}
