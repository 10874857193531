
<div class="fadein">
  <img id="f1" src="assets/images/background/dedicaciones_1.jpg" />
  <img id="f2" src="assets/images/background/dedicaciones_2.jpg" />
  <img id="f3" src="assets/images/background/dedicaciones_3.jpg" />
</div>
<section class="parallax-ibe background" id="home">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-sm-12 col-md-12">
        <div class="row">
          <div class="col-sm-2 col-md-4"></div>
          <div class="col-sm-8 col-md-4">
            <p class="tituloHome">
              Dedicaciones
            </p>
            <div class="contenedor">
              <div class="tab">
                <p class="titulo">Datos de padres o cuidadores:</p>
                <div class="contenedorInput">
                  <input type="text" class="form-control" name="fatherName" id="fatherName"
                    aria-describedby="numdocHelpId" placeholder="Nombre y apellido (*)" required style="width: 100%;" [disabled]="Disabled">
                    <p style="text-align: left; color:#ffffff; display: none;" id="fatherName"></p>
                </div>
                <div class="contenedorInput">
                  <input type="email" class="form-control" name="fatherEmail" id="fatherEmail"
                    aria-describedby="numdocHelpId" placeholder="Correo de contacto (*)" required style="width: 100%;"  [disabled]="Disabled">
                    <p style="text-align: left; color:#ffffff; display: none;" id="fatherEmail"></p>
                </div>
                <div class="contenedorInput">
                  <input type="text" class="form-control" name="fatherPhone" id="fatherPhone"
                    aria-describedby="numdocHelpId" placeholder="Teléfono de contacto (*)" required style="width: 100%;"  [disabled]="Disabled">
                    <p style="text-align: left; color:#ffffff; display: none;" id="fatherPhone"></p>
                </div>
              </div>
              <div class="tab">
                <p class="titulo">Datos de niños:</p>
                <div class="row">
                  <div class="col-md-12">
                    <div class="contenedorInput">
                      <input type="text" class="form-control contenedorInput" name="childrenName1" id="childrenName1" style="width: 80%; float: left;"
                        aria-describedby="apellido_paternoHelpId" placeholder="Nombre del menor 1 (*)" required  [disabled]="Disabled">
                    </div>
                    <div class="contenedorInput">
                      <input type="number" class="form-control contenedorInput" name="childrenAge1" id="childrenAge1" style="width: 20%; float: left;"
                        aria-describedby="apellido_paternoHelpId" placeholder="Edad(*)" required  [disabled]="Disabled">
                    </div>
                    <div class="contenedorInput">
                      <input type="text" class="form-control contenedorInput" name="childrenName2" id="childrenName2" style="width: 80%; float: left;"
                        aria-describedby="apellido_paternoHelpId" placeholder="Nombre del menor 2"  [disabled]="Disabled">
                    </div>
                    <div class="contenedorInput">
                      <input type="number" class="form-control contenedorInput" name="childrenAge2" id="childrenAge2" style="width: 20%; float: left;"
                        aria-describedby="apellido_paternoHelpId" placeholder="Edad"  [disabled]="Disabled">
                    </div>
                    <div class="contenedorInput">
                      <input type="text" class="form-control contenedorInput" name="childrenName3" id="childrenName3" style="width: 80%; float: left;"
                        aria-describedby="apellido_paternoHelpId" placeholder="Nombre del menor 3"  [disabled]="Disabled">
                    </div>
                    <div class="contenedorInput">
                      <input type="number" class="form-control contenedorInput" name="childrenAge3" id="childrenAge3" style="width: 20%; float: left;"
                        aria-describedby="apellido_paternoHelpId" placeholder="Edad"  [disabled]="Disabled">
                    </div>
                  </div>
                </div>
                <!-- <div class="contenedorInput">
                  <input type="text" class="form-control" name="fecha_nacimiento" id="fecha_nacimiento" placeholder="Fecha de nacimiento (yyyy-mm-dd)"
                    required (keyup)="formatoFecha()" maxlength="10">
                </div> -->
                <!-- <div class="contenedorInput">
                  <input type="text" class="form-control" name="mobile" id="mobile" placeholder="Teléfono de contacto"
                    aria-describedby="mobileHelpId" pattern="[0-9]{9}">
                </div> -->
                <input type="hidden" id="page1" />
                <input type="hidden" id="persona" value="0" />
                <input type="hidden" id="evento_name" />
                <input type="hidden" id="persona_name" />
              </div>
              <div style="overflow:auto;">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-xs-6" style="padding: 0px;">
                      <p style="text-align: left; margin: 0px;">
                        <button type="button" id="prevBtn" (click)="nextPrev(-1)"  [disabled]="Disabled">Atras</button>
                      </p>
                    </div>
                    <div class="col-xs-6" style="padding: 0px;">
                      <p style="text-align: right; margin: 0px;">
                        <button type="button" id="nextBtn" (click)="nextPrev(1)"  [disabled]="Disabled">Siguiente</button>
                      </p>
                      <p style="text-align: right; margin: 0px;">
                        <button type="button" id="page1Submit" (click)="onRegistrar()" style="display: none;"  [disabled]="Disabled">Registrar</button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="text-align:center;margin-top:20px;">
                <span class="step"></span>
                <span class="step"></span>
              </div>
            </div>

            <ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="#fff" type="ball-running-dots">
              <p style="font-size: 20px; color: #ffffff">Validando...</p>
            </ngx-spinner>
            <button #btnModalPre class="section-scroll btn btn-border-w btn-round" data-toggle="modal"
              data-target="#exampleModal" style="display: none;">Abrir modal 1</button>
              <button #btnModalError class="section-scroll btn btn-border-w btn-round" data-toggle="modal"
              data-target="#exampleModalError" style="display: none;">Abrir modal 1</button>
            <div class="modal fade" id="exampleModalError" tabindex="-1" role="dialog" aria-labelledby="exampleModalErrorLabel"
              aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content" style="background-color: #ffffff;
                            background-repeat: no-repeat;
                            background-size: cover;">
                  <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalErrorLabel" style="color: #000000;text-align: center;">
                      ¡Atención!<span id="nombreModal"></span>
                    </h4>
                    <button style="color: #ffffff;" type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="content-modal">
                      <div class="row">
                        <div class="col-md-12">
                          <p style="font-size: 17px; color: #000000; margin: 0px;">
                            Se han completado las inscripciones para este evento.
                            Mantente atento a nuestra siguiente fecha de dedicaciones.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        style="background-color: #000000;color: #ffffff; border: #ffffff 1px solid;">Cerrar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button #btnModalPre class="section-scroll btn btn-border-w btn-round" data-toggle="modal"
              data-target="#exampleModal" style="display: none;">Abrir modal 1</button>
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content" style="background-color: #ffffff;
                            background-repeat: no-repeat;
                            background-size: cover;">
                  <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalLabel" style="color: #000000;text-align: center;">
                      ¡Felicidades <span id="nombreModal"></span>!
                    </h4>
                    <button style="color: #ffffff;" type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="content-modal">
                      <div class="row">
                        <div class="col-md-12">
                          <p style="font-size: 17px; color: #000000; margin: 0px;">
                            Se registró correctamente su solicitud, nos pondremos en contacto contigo.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        style="background-color: #000000;color: #ffffff; border: #ffffff 1px solid;">Cerrar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-sm-2 col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
</section>

