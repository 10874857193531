<section class="module parallax-ibe" id="about"
  style="background-image: url('assets/images/clase-magistral/background1.jpg'); ">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-xs-1 col-sm-3 col-md-5"></div>
          <div class="col-xs-10 col-sm-6 col-md-2">
            <img src="assets/images/clase-magistral/logo.png" />
          </div>
          <div class="col-xs-1 col-sm-3 col-md-5"></div>
        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <h1 class="titulo1">
              CLASE MAGISTRAL
            </h1>
            <h1 class="titulo2">
              ALEX SAMPEDRO
            </h1>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-80">
    <div class="row">
      <div class="col-md-12">
        <div class="row mt-80 mb-50">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <h1 class="titulo1">
              ENTRADAS
            </h1>
          </div>
          <div class="col-md-3"></div>
        </div>
        <div class="row flex">
          <div class="col-sm-1 col-md-1"></div>
          <div class="col-sm-5 col-md-4 mb-40" style="border: 1px solid #3A3833; padding: 20px;  margin: 10px;">
            <div class="row">
              <!-- <div class="col-md-6">
                <p class="titulo-detalle">
                  Descuento
                  20 %
                </p>
                <p class="titulo-detalle1">
                  Hasta el 31 /05
                </p>
              </div> -->
              <div class="col-md-12">
                <p class="titulo-detalle">
                  Precio Regular
                </p>
                <p class="titulo-detalle1">
                  Desde el 01/06 al 12 /06
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-0 col-md-2"></div>
          <div class="col-sm-5 col-md-4 mb-40" style="border: 1px solid #3A3833; padding: 20px;  margin: 10px;">
            <div class="row">
              <div class="col-md-12">
                <p class="titulo-detalle">
                  Precio Regular
                </p>
                <p class="titulo-detalle1">
                  Hasta el 12 /06
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-1 col-md-1"></div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-6">
            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <p class="titulo-detalle2">
                  CM-PRESENCIAL
                </p>
                <a href="https://cursos.emmanuel.pe/slides/clase-magistral-2022-presencial-317" target="_blank"
                  class="section-scroll btn btn-border-w btn-round"
                  style="font-family: 'cubano-regular';font-size: 15px;text-align: center;color: #fff; background-color: #3A3833; cursor: pointer;">
                  INSCRÍBETE AQUÍ
                </a>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <p class="titulo-detalle2">
                  CM-VIRTUAL
                </p>
                <a href="https://cursos.emmanuel.pe/slides/clase-magistral-virtual-336" target="_blank"
                  class="section-scroll btn btn-border-w btn-round"
                  style="font-family: 'cubano-regular';font-size: 15px;text-align: center;color: #fff; background-color: #3A3833; cursor: pointer;">
                  INSCRÍBETE AQUÍ
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="module" id="about" style="background-image: url('assets/images/clase-magistral/background2.jpg'); ">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-sm-3 col-md-4"></div>
          <div class="col-sm-6 col-md-4">
            <p class="titulo-detalle3" style="text-align: center">
              Clase Magistral es un espacio de estudio y aprendizaje a
              profundidad dirigido al liderazgo de la Iglesia local.
            </p>
            <br>
            <p class="titulo-detalle" style="color: #ffffff;">
              ESTE AÑO HABLAREMOS DE
            </p>
          </div>
          <div class="col-sm-3 col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <br>
    <img src="assets/images/clase-magistral/letra1.png" class="letra1" width="100%" />
    <img src="assets/images/clase-magistral/letra2.png" class="letra2" width="100%" />
  </div>
</section>
<section class="module parallax-ibe" id="about"
  style="background-image: url('assets/images/clase-magistral/background4.jpg'); ">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="row">
              <div class="col-sm-6 col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <p class="titulo11">ALEX</p>
                    <p class="titulo10">SAMPEDRO</p>
                  </div>
                  <div class="col-md-2"></div>
                  <div class="col-md-10 mt-40">
                    <p class="parrafo1">
                      Amigo de la casa, nacido en Valencia (España), cantautor, psicólogo,
                      conferencista, escritor y pastor en la Comunidad Cristiana Valentia de Alboraya.<br><br>
                      Director de e625 Europa, viaja por Latinoamérica y su continente animando a
                      los jóvenes a ser y hacer discípulos de Jesús. Vive en Burjassot, Valencia,
                      con su esposa y mejor amiga Geraldine.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="row">
                  <div class="col-sm-1 col-md-2"></div>
                  <div class="col-sm-10 col-md-8">
                    <img src="assets/images/clase-magistral/img1.jpg" width="100%" />
                  </div>
                  <div class="col-sm-1 col-md-2"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>
    <div class="row mt-80">
      <div class="col-md-12">
        <div class="col-md-1">
          <p class="hashtag1">#CM22</p>
        </div>
        <div class="col-md-10">
          <iframe class="iframeYT" src="https://www.youtube.com/embed/51W3Z2Y2xHY" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <div class="row mt-80">
      <div class="col-md-12">
        <div class="row">
          <div class="col-sm-1 col-md-2"></div>
          <div class="col-sm-10 col-md-8">
            <p class="horario">HORARIOS</p>
            <p class="zonaHoraria">LIMA/BOGOTÁ</p>
            <div class="row">
              <div class="col-md-5">
                <p class="horario1">
                  JUEVES
                </p>
                <p class="horario2">
                  16.06
                </p>
                <br>
                <p class="horario3">
                  Registro<br>
                  6:00 p.m.
                </p>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-5"></div>
            </div>
          </div>
          <div class="col-sm-1 col-md-2"></div>
        </div>
        <br>
        <div class="row">
          <div class="col-sm-1 col-md-2"></div>
          <div class="col-sm-10 col-md-8">
            <div class="row">
              <div class="col-sm-5 col-md-5 background-horario">
                <p class="horario3">
                  Inicio<br>
                  6:45 p.m.
                </p>
                <p class="horario3" style="text-align: right;">
                  Final<br>
                  9:30 p.m.
                </p>
              </div>
              <div class="col-sm-2 col-md-2">
                <p class="hashtag2">#CMl22</p>
              </div>
              <div class="col-sm-5 col-md-5">
                <br><br>
                <p class="horario1">
                  VIERNES
                </p>
                <p class="horario2">
                  17.06
                </p>
                <br>
                <p class="horario3">
                  Antesala<br>
                  6:20 p.m.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-2 col-md-2"></div>
        </div>
        <div class="row horarioDesk">
          <div class="col-sm-1 col-md-2"></div>
          <div class="col-sm-10 col-md-8">
            <div class="row">
              <div class="col-sm-5 col-md-5">
                <br><br>
                <p class="horario1">
                  SÁBADO
                </p>
                <p class="horario2">
                  18.06
                </p>
                <br>
                <p class="horario3">
                  Antesala<br>
                  3:20 p.m.
                </p>
                <br>
                <div class="background-horario">
                  <p class="horario3">
                    Inicio<br>
                    3:50 p.m.
                  </p>
                  <p class="horario3" style="text-align: right;">
                    Final<br>
                    6 p.m.
                  </p>
                </div>
              </div>
              <div class="col-sm-2 col-md-2"></div>
              <div class="ml-auto col-sm-5 col-md-5 background-horario">
                <br>
                <p class="horario3">
                  Inicio<br>
                  6:50 p.m.
                </p>
                <p class="horario3" style="text-align: right;">
                  Final<br>
                  9:40 p.m.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-1 col-md-2"></div>
        </div>
        <div class="row horarioMobile">
          <div class="col-sm-1 col-md-2"></div>
          <div class="col-sm-10 col-md-8">
            <div class="row">
              <div class="ml-auto col-sm-5 col-md-5 background-horario">
                <br>
                <p class="horario3">
                  Inicio<br>
                  6:50 p.m.
                </p>
                <p class="horario3" style="text-align: right;">
                  Final<br>
                  9:40 p.m.
                </p>
              </div>
              <div class="col-sm-2 col-md-2"></div>
              <div class="col-sm-5 col-md-5">
                <br><br>
                <p class="horario1">
                  SÁBADO
                </p>
                <p class="horario2">
                  18.06
                </p>
                <br>
                <p class="horario3">
                  Antesala<br>
                  3:20 p.m.
                </p>
                <br>
                <div class="background-horario">
                  <p class="horario3">
                    Inicio<br>
                    3:50 p.m.
                  </p>
                  <p class="horario3" style="text-align: right;">
                    Final<br>
                    6 p.m.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-1 col-md-2"></div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="module" id="about" style="background-image: url('assets/images/clase-magistral/background2.jpg'); ">
  <p style="text-align: right">
    <img src="assets/images/clase-magistral/letra3.png" class="imgAdoracion" />
  </p>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo1" style="color: #D7DCEA; -webkit-text-stroke: 0px transparent;">
          INSCRÍBETE
        </h1>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-1 col-md-2"></div>
      <div class="col-sm-5 col-md-4" style="text-align: center">
        <a href="https://cursos.emmanuel.pe/slides/clase-magistral-2022-presencial-317" target="_blank"
          class="section-scroll btn btn-border-w btn-round"
          style="font-family: 'cubano-regular';font-size: 19px;text-align: center;color: #3A3833; background-color: #FFFFFF; cursor: pointer; margin-top: 10px;">
          CM-PRESENCIAL
        </a>
      </div>
      <div class="col-sm-5 col-md-4" style="text-align: center">
        <a href="https://cursos.emmanuel.pe/slides/clase-magistral-virtual-336" target="_blank"
          class="section-scroll btn btn-border-w btn-round"
          style="font-family: 'cubano-regular'; padding-left: 35px; padding-right: 35px; font-size: 19px;text-align: center;color: #3A3833; background-color: #FFFFFF; cursor: pointer; margin-top: 10px;">
          CM-VIRTUAL
        </a>
      </div>
      <div class="col-sm-1 col-md-2"></div>
    </div>
    <br><br>
    <div class="row">
      <div class="col-md-12">
        <h1 class="parrafo1" style="color: #D7DCEA; text-align: center">
          16 al 18 de Junio 2022
        </h1>
      </div>
    </div>
  </div>
  <p style="text-align: left">
    <img src="assets/images/clase-magistral/letra4.png" class="imgAdoracion" />
  </p>
</section>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="background-color: #000000;
                background-repeat: no-repeat;
                background-size: cover;">
      <div class="modal-header">
        <h4 class="text1-modal" style="letter-spacing: 0px; text-align: center;" style="color: #fff;">Ingresa aquí el ID
          que te
          enviamos
          a tu correo para ingresar a la celebración.
        </h4>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <div class="col-md-4"></div>
                    <div class="col-md-4"><input name="codigo" id="codigo" onKeyPress="if(this.value.length==6) return false;" placeholder="ID" /></div>
                    <div class="col-md-4"></div> -->
        <div class="content-modal">
          <div style="width: 10%;margin-left: 3%; margin-right: 3%;float: left;">
            <input name="pin1" id="pin1" style="width: 100%;
                        background-color: transparent;
                        border: transparent;
                        border-bottom: #ffffff 1px solid;
                        color: #fff;
                        text-align: center;
                        " onKeyPress="if(this.value.length==1) return false;" type="text"
              (keyup)=onKeydownEvent1($event) (click)="onClickKeydownEvent1($event)" autocomplete="off" />
          </div>
          <div style="width: 10%;margin-left: 3%; margin-right: 3%;float: left;">
            <input name="pin2" id="pin2" style="width: 100%;
                        background-color: transparent;
                        border: transparent;
                        border-bottom: #ffffff 1px solid;
                        color: #fff;
                        text-align: center;
                        " onKeyPress="if(this.value.length==1) return false;" type="text"
              (keyup)=onKeydownEvent2($event) (click)="onClickKeydownEvent2($event)" autocomplete="off" />
          </div>
          <div style="width: 10%;margin-left: 3%; margin-right: 3%;float: left;">
            <input name="pin3" id="pin3" style="width: 100%;
                        background-color: transparent;
                        border: transparent;
                        border-bottom: #ffffff 1px solid;
                        color: #fff;
                        text-align: center;
                        " onKeyPress="if(this.value.length==1) return false;" type="text"
              (keyup)=onKeydownEvent3($event) (click)="onClickKeydownEvent3($event)" autocomplete="off" />
          </div>
          <div style="width: 10%;margin-left: 3%; margin-right: 3%;float: left;">
            <input name="pin4" id="pin4" style="width: 100%;
                        background-color: transparent;
                        border: transparent;
                        border-bottom: #ffffff 1px solid;
                        color: #fff;
                        text-align: center;
                        " onKeyPress="if(this.value.length==1) return false;" type="text"
              (keyup)=onKeydownEvent4($event) (click)="onClickKeydownEvent4($event)" autocomplete="off" />
          </div>
          <div style="width: 10%;margin-left: 3%; margin-right: 3%;float: left;">
            <input name="pin5" id="pin5" style="width: 100%;
                        background-color: transparent;
                        border: transparent;
                        border-bottom: #ffffff 1px solid;
                        color: #fff;
                        text-align: center;
                        " onKeyPress="if(this.value.length==1) return false;" type="text"
              (keyup)=onKeydownEvent5($event) (click)="onClickKeydownEvent5($event)" autocomplete="off" />
          </div>
          <div style="width: 10%;margin-left: 3%; margin-right: 3%;float: left;">
            <input name="pin6" id="pin6" style="width: 100%;
                        background-color: transparent;
                        border: transparent;
                        border-bottom: #ffffff 1px solid;
                        color: #fff;
                        text-align: center;
                        " onKeyPress="if(this.value.length==1) return false;" type="text"
              (keyup)=onKeydownEvent6($event) (click)="onClickKeydownEvent6($event)" autocomplete="off" />
          </div>
        </div>


        <br><br><br>
        <div style="width: 100%;display: none;" id="alertError">
          <p style="font-family: NeueMontreal-Medium;font-size: 14px;color:red;text-align: center;">El ID
            ingresado es incorrecto</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          style="background-color: #FEF7E3;color: #282828; border: #282828 1px solid;">Cerrar</button>
        <button type="button" class="btn btn-primary" style="background-color: #282828;color: #FEF7E3;"
          (click)="onValidarCodigo($event)">Ingresar</button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="#fff" type="ball-running-dots">
  <p style="font-size: 20px; color: #ffffff">Enviando...</p>
</ngx-spinner>
