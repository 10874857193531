<section class="home-section home-parallax home-fade home-full-height gb-parallax" id="home"
  data-background="assets/images/background/42.jpg"
  style="background-image: url('assets/images/background/42.jpg'); height: 100vh;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
      <div class="col-sm-8 col-md-6 col-lg-6">
        <img src="assets/images/background/logoEC.png" />
      </div>
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
    </div>
  </div>
</section>
<div class="main">
  <section class="module zk1 parallax-ibe" id="about" style="background-image: url('assets/images/background/44.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <p class="titleEx1" style="background-image:  url('assets/images/background/ventanaec1.png');">
                Somos Extremo, un programa dedicado al bienestar emocional
                del adolescente en toda su etapa de desarrollo.
              </p>
              <p class="titleEx1" style="background-image:  url('assets/images/background/ventanaec2.png');">
                Compartimos principios y valores a través de charlas en
                colegios e instituciones desde el 2014, acompañando a
                los adolescentes junto a un equipo de voluntarios
                entrenados en Psicología del Desarrollo Adolescente.
              </p>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module zk1 parallax-ibe" id="about" style="background-image: url('assets/images/background/45.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <p class="titleBoldEx1">
                Nuestras Redes
              </p>
              <p class="titleEx1" style="background-image:  url('assets/images/background/ventanaec3.png');">
                Aquí compartimos información importante para psicoeducar
                a los adolescentes, así como a los padres, profesores
                y personal que trabaja directamente
                con ellos.
              </p>
              <div style="text-align: center;">
                <a class="section-scroll btn btn-border-w btn-round" target="_blank"
                  href="https://www.facebook.com/extremo.colegios" style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;
                  background-color: #000000;border: #000000;padding-left: 7%; padding-right: 7%;border-radius: 5px;
                  margin-left: 5%;margin-right: 5%;">FACEBOOK &nbsp; <i class="fa fa-facebook-official"></i></a>

                <a class="section-scroll btn btn-border-w btn-round" target="_blank"
                  href="https://www.instagram.com/extremo.colegios/" style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;
                  background-color: #000000;border: #000000;padding-left: 7%; padding-right: 7%;border-radius: 5px;
                  margin-left: 5%;margin-right: 5%;">INSTAGRAM &nbsp; <i class="fa fa-instagram"></i></a>
              </div>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module zk1 parallax-ibe" id="about" style="background-image: url('assets/images/background/46.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <p class="titleBoldEx1">
                Únete
              </p>
              <p class="titleEx1" style="background-image:  url('assets/images/background/ventanaec4.png');">
                Si quieres ser parte de nuestro voluntariado o
                estás interesado en este programa, contáctanos.
              </p>
              <div style="text-align: center;">
                <a class="section-scroll btn btn-border-w btn-round" target="_blank"
                  href="http://wa.me/+51993584314" style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;
                  background-color: #000000;border: #000000;padding-left: 7%; padding-right: 7%;border-radius: 5px;
                  margin-left: 5%;margin-right: 5%;">ESCRIBE AQUÍ</a>
              </div>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
