<div class="main bg">
  <section class="module sectionHome" id="about">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-40 mt-40">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-3">
              <p class="text-intro">
                GRUPO DE JÓVENES<br>
                DE 18 - 25 AÑOS
              </p>
            </div>
            <div class="col-sm-8 col-md-6">
            </div>
            <div class="col-sm-2 col-md-3">
              <p class="text-intro">
                HACEMOS REAL<br>
                EL MOVER DE DIOS
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module" id="about" style="padding-top: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <p class="text-center title">
            <i>
              ¡NO TE PIERDAS DE NINGUNA DE
              NUESTRAS REUNIONES!
            </i>
          </p>
          <br>
          <br>
          <p class="text-center subtitle">
            1ER LUNES DEL MES:
          </p>
          <p class="text-center subtitle1">
            <i>
              NUEVO EPISODIO DE LET'S TALK
            </i>
          </p>
          <br>
          <p class="text-center subtitle">
            2DO Y 4TO SÁBADO DEL MES:
          </p>
          <p class="text-center subtitle1">
            <i>
              PUNTOS
            </i>
          </p>
          <br>
          <p class="text-center subtitle">
            3ER SÁBADO DEL MES:
          </p>
          <p class="text-center subtitle1">
            <i>
              INTERIOR
            </i>
          </p>
          <br>
          <br>
          <p class="text-center subtitle2">
            <i>
              ¡SE PARTE DEL MOVER!
            </i>
          </p>
          <p class="social">
            <a href="https://www.facebook.com/motionemmanuel" target="_blank"><i class="fa fa-facebook-official"></i></a>
            <a href="https://www.instagram.com/motion_pe/" target="_blank"><i class="fa fa-instagram"></i></a>
          </p>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </section>
</div>
