<section class="home-section home-fade home-full-height bg-dark-30 parallax-ibe" id="home"
  style="background-image: url('assets/images/background/38.jpg'); height: 100vh;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-md-12">
        <div class="row">
          <div class="col-sm-2 col-md-4"></div>
          <div class="col-sm-8 col-md-4">
            <p style="text-align: center; margin-bottom: 0px;">
              <img class="imgLogo" src="assets/images/background/logoMovilizacion.png" />
            </p>
            <p class="hashTituloMov1">
              Movilización
            </p>
            <p class="hashTituloMov2">
              La iglesia está en movimiento
            </p>
          </div>
          <div class="col-sm-2 col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="module" id="about" data-background="assets/images/background/39.jpg"
  style="background-image: url('assets/images/background/39.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-sm-1 col-md-1"></div>
              <div class="col-sm-6 col-md-4">
                <p style="text-align: center; margin-bottom: 0px;">
                  <img class="imgLogo" src="assets/images/background/logoMovilizacion.png" />
                </p>
                <br>
              </div>
              <div class="col-sm-5 col-md-7"></div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-sm-2 col-md-2"></div>
              <div class="col-sm-8 col-md-8">
                <p class="title2Mov">
                  Somos una iglesia en acción. Ninguna circustancia hará que dejemos de
                  llevar ayuda a las familias más vulnerables de nuestra ciudad,
                  contribuyendo con nuestro país, mostrando
                  el amor y la verdad de Jesús.
                </p>
              </div>
              <div class="col-sm-2 col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="module" id="dar" style="background-image: url('assets/images/background/40.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-sm-6 col-md-8"></div>
              <div class="col-sm-6 col-md-4">
                <p style="text-align: center; margin-bottom: 0px;">
                  <img class="imgLogo" src="assets/images/background/logoMovilizacion.png" />
                </p>
                <br>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-sm-2 col-md-2"></div>
              <div class="col-sm-8 col-md-8">
                <p class="title2Mov">
                  Creemos que <span>amor no es amor hasta darlo a los demás, </span>
                  es por eso que nos sentimos impulsados a seguir
                  alcanzando a las personas.
                </p>
              </div>
              <div class="col-sm-2 col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="module" id="dar" style="background-image: url('assets/images/background/41.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-sm-2 col-md-1"></div>
      <div class="col-sm-8 col-md-10">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <p class="pieMov">
                Hoy puedes ser parte desde casa.
                Dona víveres, medicinas o ropa,
                nosotros pasaremos a recoger
                todo  a tu domicilio.
              </p>
              <div style="text-align: center;" class="mobile">
                <a class="section-scroll btn btn-border-w btn-round" href="https://www.facebook.com/iemmanuelperu"
                  target="_blank"
                  style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;">
                  CONOCE MÁS
                </a>
              </div>
              <br><br>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <p style="text-align: center; margin-bottom: 0px; padding-left: 30%; padding-right: 30%;">
                <img src="assets/images/background/logoMovilizacion.png" />
              </p>
            </div>
            <br><br>
          </div>
          <div class="col-md-4">
            <div class="row">
              <p class="pieMov">
                Si deseas dar a través de
                una ayuda económica, también puedes ser parte de
                esta movilización.<br>
                Envía tu constancia de operación al correo:
                movilizacion@emmanuel.pe
              </p>
              <div style="text-align: center;" class="mobile">
                <a class="section-scroll btn btn-border-w btn-round" href="https://www.facebook.com/iemmanuelperu"
                  target="_blank"
                  style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;">
                  HAZ CLICK AQUÍ
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div style="text-align: center;" class="desktop">
                <a class="section-scroll btn btn-border-w btn-round" href="http://wa.me/+51987510931"
                  target="_blank"
                  style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;">
                  ESCRIBE AQUÍ
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <div class="row">
              <div style="text-align: center;" class="desktop">
                <a class="section-scroll btn btn-border-w btn-round" routerLink="/dar"
                  style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;">
                  HAZ CLICK AQUÍ
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-70" style="text-align: center;">
            <p class="pieMov" style="font-size: 25px;">Síguenos en nuestras redes sociales</p>
            <div class="font-alt mb-10 titan-title-size-2 iconMixtura">
              <a target="_blank" href="https://www.facebook.com/movilizacionemmanuel"
                style="color: #ffffff;font-size: 25px;margin: 2px 10px;"><i class="fa fa-facebook-official"
                  aria-hidden="true"></i></a>
              <a target="_blank" href="https://www.instagram.com/movilizacionemmanuel/"
                style="color: #ffffff;font-size: 25px;margin: 2px 10px;"><i class="fa fa-instagram"
                  aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-2 col-md-1"></div>
    </div>
  </div>
</section>
