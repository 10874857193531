
<img src="assets/images/background/declaracion-de-fe.jpg" width="100%" class="desktop" />
<img src="assets/images/background/declaracion-de-fe-movil.jpg" width="100%" class="mobile" />
<div class="main">
  <section class="module" id="about" style="padding: 50px 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <ul style="font-size: 14px; text-align: justify;">
            <li>Creemos en la existencia de un solo Dios infinito, inmutable, perfecto, creador y sustentador de todas
              las cosas; de eterna existencia en las tres personas de la Trinidad: Padre, Hijo y Espíritu Santo. </li>
            <li>Creemos que Jesucristo es verdadero Dios y verdadero hombre; nacido de la virgen María por obra del
              Espíritu Santo; que vivió sin pecado y murió en la cruz en sacrificio sustitutorio por la humanidad; que
              fue sepultado, resucitó al tercer día y ascendió corporalmente a los cielos. Que hoy está a la diestra de
              Dios como nuestro representante y único Mediador, y que vendrá otra vez para establecer su reino de
              justicia y paz. Que es salvador de todos los que se arrepienten y se apropian por fe de su obra expiatoria
              en la cruz del Calvario. </li>
            <li>Creemos que el Espíritu Santo es la tercera persona de la Trinidad, habitando en la Iglesia desde
              Pentecostés conforme a la promesa del Padre. Que es el encargado de convencer al mundo de pecado, de
              justicia y de juicio. Que da vida nueva al que pone su fe en Cristo como Salvador, viniendo a habitar en
              él y haciéndole un hijo de Dios. </li>
            <li>Creemos que el Señor Jesucristo bautiza con el Espíritu Santo al creyente, dándole así el poder para ser
              un testigo eficaz, en una experiencia normalmente subsecuente a la conversión. </li>
            <li>Creemos que el orar en lenguas es una de las manifestaciones de dicho bautismo, así como lo son una
              experiencia con Dios más real y profunda, y el poder para una vida de santidad y para un servicio más
              efectivo a Dios mediante los dones del Espíritu. Creemos que el bautismo con el Espíritu es la puerta de
              entrada al ámbito sobrenatural de la vida cristiana, y a lo que debe ser una vida en continua plenitud y
              en obediencia a la Palabra de Dios. </li>
            <li>Creemos en la sanidad divina de los enfermos por medio de la oración de fe en Jesucristo. </li>
            <li>Creemos que las Sagradas Escrituras, compuestas por 66 libros divididos en el Antiguo y el Nuevo
              Testamentos, fueron sobrenatural, plenaria y dinámicamente inspiradas por Dios en su forma original, y
              constituyen así la Palabra infalible de Dios y la revelación completa del camino de salvación para el
              hombre, y su única regla de fe y conducta cristiana. </li>
            <li>Creemos que el hombre, originalmente creado a imagen y semejanza de Dios, cayó por desobediencia,
              trayendo así la muerte física y espiritual sobre la humanidad. El hombre nace desde entonces con una
              naturaleza pecaminosa y separado de Dios. </li>
            <li>Creemos que el destino del impenitente e incrédulo es una existencia en tormento eterno, mientras que el
              del creyente en Jesucristo es una existencia en espíritu, alma y cuerpo glorificado, de eterno gozo y
              bendición en la comunión con Dios. </li>
            <li>Creemos que la Iglesia Universal, verdadero cuerpo místico de Cristo, está constituida por todas las
              personas renacidas espiritualmente por la obra del Espíritu Santo en base a la fe puesta en Jesucristo. La
              Iglesia ha sido comisionada para predicar el Evangelio a toda criatura en todas las naciones,
              bautizándolas en el nombre del Padre, del Hijo, y del Espíritu Santo, y enseñándoles a cumplir con los
              mandatos de Cristo. </li>
            <li>Creemos que Cristo volverá corporalmente por segunda vez para establecer su Reino. Su venida es
              inminente, y creemos que es premilenial.</li>
          </ul>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </section>
</div>
