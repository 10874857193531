<section class="home-section home-parallax home-fade home-full-height parallax-ibe" id="home"
  data-background="assets/images/background/20.jpg"
  style="background-image: url('assets/images/background/20.jpg'); height: 100vh;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
      <div class="col-sm-8 col-md-6 col-lg-6">
        <img src="assets/images/background/logopeople.png" />
        <p class="edades" >
          Grupo de adolescentes de 12 a 17 años
        </p>
      </div>
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
    </div>
  </div>
</section>
<div class="main">
  <section class="module" id="about" style="background-image: url('assets/images/background/21.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mb-40">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-3 col-md-2"></div>
            <div class="col-sm-6 col-md-8">
              <p class="reupeopleTitulo1">
                Preparamos espacios
                únicos para adolescentes a
                través de diferentes actividades
                virtuales.<br>
                ¡People, personas como tú!
              </p>
              <p class="reupeopleTitulo2">
                #PeopleEnCasa #People <br>
                #PersonasComoTú
              </p>
            </div>
            <div class="col-sm-3 col-md-2"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <p style="text-align: center;"><img src="assets/images/background/logopeople1.png" /></p>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-1"></div>
            <div class="col-sm-8 col-md-10">
              <p class="tituloPP1">
                Te presentamos People Live, nuestras
                transmisiones en vivo los primeros y
                terceros sábados de cada mes, a las
                5 p.m. (PER) por nuestras redes sociales.
              </p>

              <div style="text-align: center;">
                <a class="section-scroll btn btn-border-w btn-round" href="https://www.youtube.com/channel/UC1y7eUzbOaZ8dgNexeOrOCg" target="_blank"
                  style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;
                  background-color: #000000;border: #000000;padding-left: 7%; padding-right: 7%;border-radius: 5px;">ÚNETE</a>

              </div>
            </div>
            <div class="col-sm-2 col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module parallax-ibe" id="about" style="background-image: url('assets/images/background/22.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-10">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-3 col-md-3"></div>
            <div class="col-sm-6 col-md-6">
              <p style="text-align: center;">
                <img src="assets/images/background/logopeople2.png" />
              </p>
            </div>
            <div class="col-sm-3 col-md-3"></div>
          </div>
        </div>
        <div class="col-md-12 mb-10">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-1 col-md-2"></div>
            <div class="col-sm-10 col-md-8">
              <p class="tituloPP2">
                Estás invitado a ser parte de Puntos, nuestros grupos de conexión
                con diferentes temáticas todos los sábados vía zoom. Si quieres
                formar parte de esto o tener más información escríbenos a nuestro instagram.
              </p>
            </div>
            <div class="col-sm-1 col-md-2"></div>
          </div>
        </div>
        <div class="col-md-12 mb-10">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-1 col-md-2"></div>
            <div class="col-sm-10 col-md-8">
              <div style="text-align: center;">
                <a class="section-scroll btn btn-border-w btn-round" target="_blank" href="https://www.instagram.com/people.peru/"
                  style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;
                  background-color: #000000;border: #000000;padding-left: 7%; padding-right: 7%;border-radius: 5px;">ÚNETE</a>
              </div>
            </div>
            <div class="col-sm-1 col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module" id="about" style="background-image: url('assets/images/background/23.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mb-40">

          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-2"></div>
            <div class="col-sm-8 col-md-8">
              <p style="text-align: center;"><img src="assets/images/background/logopeople3.png" /></p>
            </div>
            <div class="col-sm-2 col-md-2"></div>
          </div>
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-md-12">
              <div style="text-align: center;">
                <a class="section-scroll btn btn-border-w btn-round" target="_blank" href="https://www.instagram.com/people.peru/" style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;
                  background-color: #000000;border: #000000;padding-left: 7%; padding-right: 7%;border-radius: 5px;
                  margin-left: 5%;margin-right: 5%;">ÚNETE</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-3 col-md-2"></div>
            <div class="col-sm-6 col-md-8">
              <p class="reupeopleTitulo3">
                Aquí profundizamos en los principios bíblicos.
                A través de 5 libros podemos aprender mucho más de
                Dios.  Escríbenos a nuestro instagram si estás
                interesado.
              </p>
              <p class="reupeopleTitulo4">
                #PeopleEnCasa #People <br>
                #PersonasComoTú
              </p>
            </div>
            <div class="col-sm-3 col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module" id="about" style="background-image: url('assets/images/background/24.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-10">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-4 col-md-4"></div>
            <div class="col-sm-4 col-md-4">
              <p style="text-align: center;">
                <img src="assets/images/background/logopeople4.png" />
              </p>
            </div>
            <div class="col-sm-4 col-md-4"></div>
          </div>
        </div>
        <div class="col-md-12 mb-10">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-1 col-md-2"></div>
            <div class="col-sm-10 col-md-8">
              <p class="tituloPP2">
                Integra una y conéctate más con Jesús.
                Cada Red está dirigida por un líder que busca generar
                espacios de amistad y cercanía con los integrantes del
                grupo. Sé parte escribiéndonos por instagram.
              </p>
            </div>
            <div class="col-sm-1 col-md-2"></div>
          </div>
        </div>
        <div class="col-md-12 mb-10 mt-50">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-3 col-md-4"></div>
            <div class="col-sm-6 col-md-4">
              <div class="redes-people">
                <div class="font-alt mb-10 titan-title-size-2" style="margin-top: 15px;">
                  <a target="_blank" href="https://www.facebook.com/people.peruu"
                    style="color: #000000;font-size: 25px;margin: 2px 10px;"><i class="fa fa-facebook-official"
                      aria-hidden="true"></i></a>
                  <a target="_blank" href="https://www.instagram.com/people.peru/"
                    style="color: #000000;font-size: 25px;margin: 2px 10px;"><i class="fa fa-instagram"
                      aria-hidden="true"></i></a>
                  <a target="_blank" href="https://www.youtube.com/channel/UC1y7eUzbOaZ8dgNexeOrOCg"
                    style="color: #000000;font-size: 25px;margin: 2px 10px;"><i class="fa fa-youtube-play"
                      aria-hidden="true"></i></a>
                  <a target="_blank" href="https://vm.tiktok.com/E2T87W/"
                    style="color: #000000;font-size: 25px;margin: 2px 10px;">
                    <svg xmlns="http://www.w3.org/2000/svg" class="fa-fa-tik-tok" viewBox="0 0 48 48">
                      <g fill="none" fill-rule="evenodd">
                          <path fill="#00F2EA" d="M20.023 18.111v-1.703a13.17 13.17 0 0 0-1.784-.13c-7.3 0-13.239 5.94-13.239 13.24 0 4.478 2.238 8.442 5.652 10.839a13.187 13.187 0 0 1-3.555-9.014c0-7.196 5.77-13.064 12.926-13.232"/>
                          <path fill="#00F2EA" d="M20.335 37.389c3.257 0 5.914-2.591 6.035-5.82l.011-28.825h5.266a9.999 9.999 0 0 1-.17-1.825h-7.192l-.012 28.826c-.12 3.228-2.778 5.818-6.034 5.818a6.006 6.006 0 0 1-2.805-.694 6.037 6.037 0 0 0 4.901 2.52M41.484 12.528v-1.602a9.943 9.943 0 0 1-5.449-1.62 10.011 10.011 0 0 0 5.45 3.222"/>
                          <path fill="#FF004F" d="M36.035 9.305a9.962 9.962 0 0 1-2.461-6.56h-1.927a10.025 10.025 0 0 0 4.388 6.56M18.239 23.471a6.053 6.053 0 0 0-6.046 6.046 6.05 6.05 0 0 0 3.24 5.352 6.007 6.007 0 0 1-1.144-3.526 6.053 6.053 0 0 1 6.046-6.047c.623 0 1.22.103 1.784.28v-7.343a13.17 13.17 0 0 0-1.784-.13c-.105 0-.208.006-.312.008v5.64a5.944 5.944 0 0 0-1.784-.28"/>
                          <path fill="#FF004F" d="M41.484 12.528v5.59c-3.73 0-7.185-1.193-10.007-3.218v14.617c0 7.3-5.938 13.239-13.238 13.239-2.821 0-5.437-.89-7.587-2.4a13.201 13.201 0 0 0 9.683 4.225c7.3 0 13.239-5.939 13.239-13.238V16.726a17.107 17.107 0 0 0 10.007 3.218V12.75c-.72 0-1.42-.078-2.097-.223"/>
                          <path fill="#000000" d="M31.477 29.517V14.9a17.103 17.103 0 0 0 10.007 3.218v-5.59a10.011 10.011 0 0 1-5.449-3.223 10.025 10.025 0 0 1-4.388-6.56h-5.266L26.37 31.57c-.121 3.228-2.778 5.819-6.035 5.819a6.038 6.038 0 0 1-4.901-2.52 6.05 6.05 0 0 1-3.241-5.352 6.053 6.053 0 0 1 6.046-6.046c.622 0 1.219.102 1.784.28v-5.64c-7.156.168-12.926 6.036-12.926 13.232 0 3.48 1.352 6.648 3.555 9.014a13.16 13.16 0 0 0 7.587 2.399c7.3 0 13.238-5.939 13.238-13.239"/>
                      </g>
                  </svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
