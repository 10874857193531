import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line: typedef
  onSubmit() {
    const politicas = (document.getElementById('politicas') as HTMLInputElement).checked;
    const alert = (document.getElementById('alert') as HTMLInputElement);
    const alert1 = (document.getElementById('alert1') as HTMLInputElement);
    alert1.style.display = 'none';
    alert.style.display = 'none';
    if (!politicas) {
      alert.style.display = 'block';
    } else {
      const nombreApellido = (document.getElementById('nombreApellido') as HTMLInputElement).value;
      const tipoRed = (document.getElementById('tipoRed') as HTMLInputElement).value;
      const edad = (document.getElementById('edad') as HTMLInputElement).value;
      // const estadoCivil = (document.getElementById('estadoCivil') as HTMLInputElement).value;
      const distrito = (document.getElementById('distrito') as HTMLInputElement).value;
      // const departamento = (document.getElementById('departamento') as HTMLInputElement).value;
      // const pais = (document.getElementById('pais') as HTMLInputElement).value;
      const celular = (document.getElementById('celular') as HTMLInputElement).value;
      const correo = (document.getElementById('correo') as HTMLInputElement).value;
      const observaciones = (document.getElementById('observaciones') as HTMLInputElement).value;

      if (nombreApellido === '' || tipoRed === '0' || edad === '' || distrito === '' ||
      celular === '' || correo === '') {
        alert1.style.display = 'block';
      } else {
        const form = document.getElementById('emailform') as HTMLFormElement;
        form.submit();
      }

    }
  }
}
