import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cultura',
  templateUrl: './cultura.component.html',
  styleUrls: ['./cultura.component.css']
})
export class CulturaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
