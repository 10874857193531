import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-dar',
  templateUrl: './dar.component.html',
  styleUrls: ['./dar.component.css']
})
export class DarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.getElementById('chile').style.display = 'none';
    document.getElementById('extranjero').style.display = 'none';
    window.addEventListener('scroll', this.scroll, true);

    document.getElementById('btnExtranjero').style.backgroundColor = '#ffffff';
    document.getElementById('btnExtranjero').style.color = '#000000';
    document.getElementById('btnChile').style.backgroundColor = '#ffffff';
    document.getElementById('btnChile').style.color = '#000000';
    document.getElementById('btnPeru').style.backgroundColor = '#000000';
    document.getElementById('btnPeru').style.color = '#ffffff';
  }

  scroll = (event): void => {
    const demo1 = document.getElementsByClassName('parallax-ibe');
    const demo2 = event.path[0].documentElement.scrollTop;
    [].forEach.call(demo1, function (el) {
      const height = el.getBoundingClientRect().top;
      const height1 = el.offsetHeight;
      if (height < 0 && (height1 + height) > 0) {
        el.style.top = (height * -1) / 4 + 'px';
        el.style.backgroundPositionY = (height * -1) / 2 + 'px';
      } else {
        el.style.top = 0 + 'px';
        el.style.backgroundPositionY = 0 + 'px';
      }
    });
  }

  // tslint:disable-next-line: typedef
  onPeru() {
    document.getElementById('chile').style.display = 'none';
    document.getElementById('extranjero').style.display = 'none';
    document.getElementById('peru').style.display = 'block';

    document.getElementById('btnExtranjero').style.backgroundColor = '#ffffff';
    document.getElementById('btnExtranjero').style.color = '#000000';
    document.getElementById('btnChile').style.backgroundColor = '#ffffff';
    document.getElementById('btnChile').style.color = '#000000';
    document.getElementById('btnPeru').style.backgroundColor = '#000000';
    document.getElementById('btnPeru').style.color = '#ffffff';
  }

  // tslint:disable-next-line: typedef
  onChile() {
    document.getElementById('chile').style.display = 'block';
    document.getElementById('extranjero').style.display = 'none';
    document.getElementById('peru').style.display = 'none';

    document.getElementById('btnExtranjero').style.backgroundColor = '#ffffff';
    document.getElementById('btnExtranjero').style.color = '#000000';
    document.getElementById('btnPeru').style.backgroundColor = '#ffffff';
    document.getElementById('btnPeru').style.color = '#000000';
    document.getElementById('btnChile').style.backgroundColor = '#000000';
    document.getElementById('btnChile').style.color = '#ffffff';
  }

  // tslint:disable-next-line: typedef
  onExtranjero() {
    document.getElementById('chile').style.display = 'none';
    document.getElementById('extranjero').style.display = 'block';
    document.getElementById('peru').style.display = 'none';

    document.getElementById('btnChile').style.backgroundColor = '#ffffff';
    document.getElementById('btnChile').style.color = '#000000';
    document.getElementById('btnPeru').style.backgroundColor = '#ffffff';
    document.getElementById('btnPeru').style.color = '#000000';
    document.getElementById('btnExtranjero').style.backgroundColor = '#000000';
    document.getElementById('btnExtranjero').style.color = '#ffffff';
  }


}
