import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'frontEmmanuel';

  constructor(private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) { }

  onActivate(event) {
    window.scroll(0,0);
  }

  onScroll(target) {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: target,
    })
  }

}
