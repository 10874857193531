import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators'
import { EventoModel } from '../models/sige/evento/evento.model';
import { Observable } from 'rxjs';
import { PersonaModel } from '../models/sige/persona/persona.model';

@Injectable({
  providedIn: 'root'
})
export class SigeService {

  constructor(private http:HttpClient) { }

  consultaUsuarioSige(request: any){
    const headers = new HttpHeaders();
		headers.append('Accept', 'application/json');
		headers.append('Content-Type', 'application/json');
    return this.http.post<any>(environment.end_point_backend + "/sige/usuario", request, { headers }).pipe(
      map(datos=>{
        return datos;
      })
    );
  }

  obtenerEventosxTipo(request: any) : Observable<EventoModel[]>{
    const headers = new HttpHeaders();
		headers.append('Accept', 'application/json');
		headers.append('Content-Type', 'application/json');
    return this.http.post<any>(environment.end_point_backend + "/sige/obtenerEventosxTipo", request, { headers }).pipe(
      map(datos=>{
        let responseModel :EventoModel[] = datos;
        return responseModel;
      })
    );
  }

  obtenerPersona(request: any) : Observable<PersonaModel>{
    const headers = new HttpHeaders();
		headers.append('Accept', 'application/json');
		headers.append('Content-Type', 'application/json');
    return this.http.post<any>(environment.end_point_backend + "/sige/obtenerPersona", request, { headers }).pipe(
      map(datos=>{
        let responseModel :PersonaModel = datos;
        return responseModel;
      })
    );
  }

  registrarEvento(request: any){
    const headers = new HttpHeaders();
		headers.append('Accept', 'application/json');
		headers.append('Content-Type', 'application/json');
    return this.http.post<any>(environment.end_point_backend + "/sige/registrarEvento", request, { headers }).pipe(
      map(datos=>{
        return datos;
      })
    );
  }

  obtenerDisponibilidadEvento(request: any){
    const headers = new HttpHeaders();
		headers.append('Accept', 'application/json');
		headers.append('Content-Type', 'application/json');
    return this.http.post<any>(environment.end_point_backend + "/sige/obtenerDisponibilidadEvento", request, { headers }).pipe(
      map(datos=>{
        return datos;
      })
    );
  }

}
