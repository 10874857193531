<section class="module" id="about" data-background="assets/images/background/fondo02.jpg"
  style="background-image: url('assets/images/background/fondo02.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="font-alt titan-title-size-4 portTitle1 moviTitle1">
          <img src="assets/images/movilizacion.png" class="movilogo" />
          Movilización
          <br>
          <span class="moviTitle2">La iglesia está en movimiento</span>
        </div>
        <div class="col-md-12">
          <div class="col-sm-1 col-md-1 col-lg-1"></div>
          <div class="col-sm-10 col-md-10 col-lg-10" style="padding: 0px; margin: 0px;">
            <div class="moviWindows">
              <div class="font-alt titan-title-size-2" style="font-family: Montserrat-Light;
                text-align: center;color: #ffffff;">
                Contribuimos con nuestro país. <br>
                Ayudamos a las personas en necesidad. <br> <br>
                <b>Amor no es amor hasta darlo a los demás.</b>
              </div>
              <div class="font-alt titan-title-size-2" style="font-family: Montserrat-Light;
                text-align: center;color: #ffffff;">
                #MovilízateEnCasa
              </div>
            </div>
          </div>
          <div class="col-sm-1 col-md-1 col-lg-1"></div>
        </div>
        <div style="text-align: center;" class="mobile">
          <a class="section-scroll btn btn-border-w btn-round" routerLink="/movilizacion"
            style="font-family: Montserrat-Bold;font-size: 13px;text-align: center;">CONOCE MÁS</a>
        </div>
        <br><br><br>
      </div>
      <div class="col-md-6">
        <div class="font-alt titan-title-size-4 portTitle1 moviTitle1">
          Chats de Ayuda
          <br>
          <span class="moviTitle2">Siempre conectados</span>
        </div>
        <div class="col-md-12">
          <div class="col-sm-1 col-md-1 col-lg-1"></div>
          <div class="col-sm-10 col-md-10 col-lg-10" style="padding: 0px; margin: 0px;">
            <div class="moviWindows">
              <div class="font-alt titan-title-size-2" style="font-family: Montserrat-Light;
                text-align: center;color: #ffffff;padding-left: 5%; padding-right: 5%;">
                Sabemos lo importante que es mantenernos cerca a pesar de la distancia.
                <br> <br>
                Escríbenos en estos horarios:
              </div>
            </div>
          </div>
          <div class="col-sm-1 col-md-1 col-lg-1"></div>
        </div>
        <div style="text-align: center;" class="mobile">
          <a class="section-scroll btn btn-border-w btn-round" href="https://wa.me/51945169790/" target="_blank"
              style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin-left: 15px;margin-right: 15px;text-transform: inherit;">8 a.m.
              - 1 p.m. (PER)</a>

            <a class="section-scroll btn btn-border-w btn-round" href="https://wa.me/51989286082/" target="_blank"
              style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin-left: 15px;margin-right: 15px;text-transform: inherit;">1 p.m.
              - 7 p.m. (PER)</a>
        </div>
      </div>
      <div class="col-md-12 desktop">
        <div class="col-md-6">
          <div style="text-align: center;">
            <a class="section-scroll btn btn-border-w btn-round" routerLink="/movilizacion"
              style="font-family: Montserrat-Bold;font-size: 13px;text-align: center;">CONOCE MÁS</a>
          </div>
        </div>
        <div class="col-md-6">
          <div style="text-align: center;">
            <a class="section-scroll btn btn-border-w btn-round" href="https://wa.me/51945169790/" target="_blank"
              style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin-left: 15px;margin-right: 15px;text-transform: inherit;">8 a.m.
              - 1 p.m. (PER)</a>

            <a class="section-scroll btn btn-border-w btn-round" href="https://wa.me/51989286082/" target="_blank"
              style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin-left: 15px;margin-right: 15px;text-transform: inherit;">1 p.m.
              - 7 p.m. (PER)</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="module" id="dar" style="background-image: url('assets/images/background/4.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="font-alt titan-title-size-4 chatTitle1">
          <b class="titleDar">Formas de Dar</b><br>
          <span class="moviTitle2">¡Gracias por tu generosidad!</span>
        </div>
        <br>
        <div class="col-md-12">
          <div class="col-sm-1 col-md-2 col-lg-4"></div>
          <div class="col-sm-10 col-md-8 col-lg-4" style="padding: 0px; margin: 0px;">
            <div class="darWindows">
              <div class="font-alt titan-title-size-2" style="font-family: Montserrat-Light;
            text-align: center;color: #ffffff;">
                Damos por amor; queremos llegar a más familias vulnerables.
              </div>
            </div>
          </div>
          <div class="col-sm-1 col-md-2 col-lg-4"></div>
        </div>
        <div style="text-align: center;">
          <a class="btn btn-border-w btn-round" routerLink="/dar"
            style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; padding: 8px 60px;">DAR</a>
        </div>
      </div>
    </div>
  </div>
</section>
