<section class="home-section home-fade home-full-height bg-dark-30 parallax-ibe" id="home"
  style="background-image: url('assets/images/background/47.jpg'); height: 100vh;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
      <div class="col-sm-8 col-md-6 col-lg-6">
        <div class="col-md-12">
          <p class="tituloRespuesta">Ya registramos tus datos :)</p>
          <br>
          <p class="tituloRespuesta1">
            ¡Gracias por escribirnos!
          </p>
          <br>
          <div style="text-align: center;">
            <a class="section-scroll btn btn-border-w btn-round" routerLink="/home"
              style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;">Volver al inicio</a>
          </div>
        </div>
      </div>
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
    </div>
  </div>
</section>
