<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <!-- NAME: SELL PRODUCTS -->
  <!--[if gte mso 15]>
        <xml>
            <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <title>*|MC:SUBJECT|*</title>

  <style type="text/css">
    p {
      margin: 10px 0;
      padding: 0;
    }

    table {
      border-collapse: collapse;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: block;
      margin: 0;
      padding: 0;
    }

    img,
    a img {
      border: 0;
      height: auto;
      outline: none;
      text-decoration: none;
    }

    body,
    #bodyTable,
    #bodyCell {
      height: 100%;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    .mcnPreviewText {
      display: none !important;
    }

    #outlook a {
      padding: 0;
    }

    img {
      -ms-interpolation-mode: bicubic;
    }

    table {
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }

    .ReadMsgBody {
      width: 100%;
    }

    .ExternalClass {
      width: 100%;
    }

    p,
    a,
    li,
    td,
    blockquote {
      mso-line-height-rule: exactly;
    }

    a[href^=tel],
    a[href^=sms] {
      color: inherit;
      cursor: default;
      text-decoration: none;
    }

    p,
    a,
    li,
    td,
    body,
    table,
    blockquote {
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
    }

    .ExternalClass,
    .ExternalClass p,
    .ExternalClass td,
    .ExternalClass div,
    .ExternalClass span,
    .ExternalClass font {
      line-height: 100%;
    }

    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }

    .templateContainer {
      max-width: 600px !important;
    }

    a.mcnButton {
      display: block;
    }

    .mcnImage,
    .mcnRetinaImage {
      vertical-align: bottom;
    }

    .mcnTextContent {
      word-break: break-word;
    }

    .mcnTextContent img {
      height: auto !important;
    }

    .mcnDividerBlock {
      table-layout: fixed !important;
    }

    /*
	@tab Page
	@section Heading 1
	@style heading 1
	*/
    h1 {
      /*@editable*/
      color: #222222;
      /*@editable*/
      font-family: Helvetica;
      /*@editable*/
      font-size: 40px;
      /*@editable*/
      font-style: normal;
      /*@editable*/
      font-weight: bold;
      /*@editable*/
      line-height: 150%;
      /*@editable*/
      letter-spacing: normal;
      /*@editable*/
      text-align: center;
    }

    /*
	@tab Page
	@section Heading 2
	@style heading 2
	*/
    h2 {
      /*@editable*/
      color: #222222;
      /*@editable*/
      font-family: Helvetica;
      /*@editable*/
      font-size: 34px;
      /*@editable*/
      font-style: normal;
      /*@editable*/
      font-weight: bold;
      /*@editable*/
      line-height: 150%;
      /*@editable*/
      letter-spacing: normal;
      /*@editable*/
      text-align: left;
    }

    /*
	@tab Page
	@section Heading 3
	@style heading 3
	*/
    h3 {
      /*@editable*/
      color: #444444;
      /*@editable*/
      font-family: Helvetica;
      /*@editable*/
      font-size: 22px;
      /*@editable*/
      font-style: normal;
      /*@editable*/
      font-weight: bold;
      /*@editable*/
      line-height: 150%;
      /*@editable*/
      letter-spacing: normal;
      /*@editable*/
      text-align: left;
    }

    /*
	@tab Page
	@section Heading 4
	@style heading 4
	*/
    h4 {
      /*@editable*/
      color: #949494;
      /*@editable*/
      font-family: Georgia;
      /*@editable*/
      font-size: 20px;
      /*@editable*/
      font-style: italic;
      /*@editable*/
      font-weight: normal;
      /*@editable*/
      line-height: 125%;
      /*@editable*/
      letter-spacing: normal;
      /*@editable*/
      text-align: left;
    }

    /*
	@tab Header
	@section Header Container Style
	*/
    #templateHeader {
      /*@editable*/
      background-color: #F7F7F7;
      /*@editable*/
      background-image: url("https://mcusercontent.com/eac4ab6fc21f29c6233d47c67/images/43e5c086-e594-5688-73ce-c75ca353cf4c.png");
      /*@editable*/
      background-repeat: no-repeat;
      /*@editable*/
      background-position: center;
      /*@editable*/
      background-size: cover;
      /*@editable*/
      border-top: 0;
      /*@editable*/
      border-bottom: 0;
      /*@editable*/
      padding-top: 45px;
      /*@editable*/
      padding-bottom: 45px;
    }

    /*
	@tab Header
	@section Header Interior Style
	*/
    .headerContainer {
      /*@editable*/
      background-color: transparent;
      /*@editable*/
      background-image: none;
      /*@editable*/
      background-repeat: no-repeat;
      /*@editable*/
      background-position: center;
      /*@editable*/
      background-size: cover;
      /*@editable*/
      border-top: 0;
      /*@editable*/
      border-bottom: 0;
      /*@editable*/
      padding-top: 0;
      /*@editable*/
      padding-bottom: 0;
    }

    /*
	@tab Header
	@section Header Text
	*/
    .headerContainer .mcnTextContent,
    .headerContainer .mcnTextContent p {
      /*@editable*/
      color: #757575;
      /*@editable*/
      font-family: Helvetica;
      /*@editable*/
      font-size: 16px;
      /*@editable*/
      line-height: 150%;
      /*@editable*/
      text-align: left;
    }

    /*
	@tab Header
	@section Header Link
	*/
    .headerContainer .mcnTextContent a,
    .headerContainer .mcnTextContent p a {
      /*@editable*/
      color: #007C89;
      /*@editable*/
      font-weight: normal;
      /*@editable*/
      text-decoration: underline;
    }

    /*
	@tab Body
	@section Body Container Style
	*/
    #templateBody {
      /*@editable*/
      background-color: #FFFFFF;
      /*@editable*/
      background-image: none;
      /*@editable*/
      background-repeat: no-repeat;
      /*@editable*/
      background-position: center;
      /*@editable*/
      background-size: cover;
      /*@editable*/
      border-top: 0;
      /*@editable*/
      border-bottom: 0;
      /*@editable*/
      padding-top: 36px;
      /*@editable*/
      padding-bottom: 45px;
    }

    /*
	@tab Body
	@section Body Interior Style
	*/
    .bodyContainer {
      /*@editable*/
      background-color: transparent;
      /*@editable*/
      background-image: none;
      /*@editable*/
      background-repeat: no-repeat;
      /*@editable*/
      background-position: center;
      /*@editable*/
      background-size: cover;
      /*@editable*/
      border-top: 0;
      /*@editable*/
      border-bottom: 0;
      /*@editable*/
      padding-top: 0;
      /*@editable*/
      padding-bottom: 0;
    }

    /*
	@tab Body
	@section Body Text
	*/
    .bodyContainer .mcnTextContent,
    .bodyContainer .mcnTextContent p {
      /*@editable*/
      color: #757575;
      /*@editable*/
      font-family: Helvetica;
      /*@editable*/
      font-size: 16px;
      /*@editable*/
      line-height: 150%;
      /*@editable*/
      text-align: left;
    }

    /*
	@tab Body
	@section Body Link
	*/
    .bodyContainer .mcnTextContent a,
    .bodyContainer .mcnTextContent p a {
      /*@editable*/
      color: #007C89;
      /*@editable*/
      font-weight: normal;
      /*@editable*/
      text-decoration: underline;
    }

    /*
	@tab Footer
	@section Footer Style
	*/
    #templateFooter {
      /*@editable*/
      background-color: #0080ff;
      /*@editable*/
      background-image: none;
      /*@editable*/
      background-repeat: no-repeat;
      /*@editable*/
      background-position: center;
      /*@editable*/
      background-size: cover;
      /*@editable*/
      border-top: 0;
      /*@editable*/
      border-bottom: 0;
      /*@editable*/
      padding-top: 45px;
      /*@editable*/
      padding-bottom: 63px;
    }

    /*
	@tab Footer
	@section Footer Interior Style
	*/
    .footerContainer {
      /*@editable*/
      background-color: transparent;
      /*@editable*/
      background-image: none;
      /*@editable*/
      background-repeat: no-repeat;
      /*@editable*/
      background-position: center;
      /*@editable*/
      background-size: cover;
      /*@editable*/
      border-top: 0;
      /*@editable*/
      border-bottom: 0;
      /*@editable*/
      padding-top: 0;
      /*@editable*/
      padding-bottom: 0;
    }

    /*
	@tab Footer
	@section Footer Text
	*/
    .footerContainer .mcnTextContent,
    .footerContainer .mcnTextContent p {
      /*@editable*/
      color: #FFFFFF;
      /*@editable*/
      font-family: Helvetica;
      /*@editable*/
      font-size: 12px;
      /*@editable*/
      line-height: 150%;
      /*@editable*/
      text-align: center;
    }

    /*
	@tab Footer
	@section Footer Link
	*/
    .footerContainer .mcnTextContent a,
    .footerContainer .mcnTextContent p a {
      /*@editable*/
      color: #FFFFFF;
      /*@editable*/
      font-weight: normal;
      /*@editable*/
      text-decoration: underline;
    }

    @media only screen and (min-width:768px) {
      .templateContainer {
        width: 600px !important;
      }

    }

    @media only screen and (max-width: 480px) {

      body,
      table,
      td,
      p,
      a,
      li,
      blockquote {
        -webkit-text-size-adjust: none !important;
      }

    }

    @media only screen and (max-width: 480px) {
      body {
        width: 100% !important;
        min-width: 100% !important;
      }

    }

    @media only screen and (max-width: 480px) {
      .mcnRetinaImage {
        max-width: 100% !important;
      }

    }

    @media only screen and (max-width: 480px) {
      .mcnImage {
        width: 100% !important;
      }

    }

    @media only screen and (max-width: 480px) {

      .mcnCartContainer,
      .mcnCaptionTopContent,
      .mcnRecContentContainer,
      .mcnCaptionBottomContent,
      .mcnTextContentContainer,
      .mcnBoxedTextContentContainer,
      .mcnImageGroupContentContainer,
      .mcnCaptionLeftTextContentContainer,
      .mcnCaptionRightTextContentContainer,
      .mcnCaptionLeftImageContentContainer,
      .mcnCaptionRightImageContentContainer,
      .mcnImageCardLeftTextContentContainer,
      .mcnImageCardRightTextContentContainer,
      .mcnImageCardLeftImageContentContainer,
      .mcnImageCardRightImageContentContainer {
        max-width: 100% !important;
        width: 100% !important;
      }

    }

    @media only screen and (max-width: 480px) {
      .mcnBoxedTextContentContainer {
        min-width: 100% !important;
      }

    }

    @media only screen and (max-width: 480px) {
      .mcnImageGroupContent {
        padding: 9px !important;
      }

    }

    @media only screen and (max-width: 480px) {

      .mcnCaptionLeftContentOuter .mcnTextContent,
      .mcnCaptionRightContentOuter .mcnTextContent {
        padding-top: 9px !important;
      }

    }

    @media only screen and (max-width: 480px) {

      .mcnImageCardTopImageContent,
      .mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,
      .mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent {
        padding-top: 18px !important;
      }

    }

    @media only screen and (max-width: 480px) {
      .mcnImageCardBottomImageContent {
        padding-bottom: 9px !important;
      }

    }

    @media only screen and (max-width: 480px) {
      .mcnImageGroupBlockInner {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

    }

    @media only screen and (max-width: 480px) {
      .mcnImageGroupBlockOuter {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
      }

    }

    @media only screen and (max-width: 480px) {

      .mcnTextContent,
      .mcnBoxedTextContentColumn {
        padding-right: 18px !important;
        padding-left: 18px !important;
      }

    }

    @media only screen and (max-width: 480px) {

      .mcnImageCardLeftImageContent,
      .mcnImageCardRightImageContent {
        padding-right: 18px !important;
        padding-bottom: 0 !important;
        padding-left: 18px !important;
      }

    }

    @media only screen and (max-width: 480px) {
      .mcpreview-image-uploader {
        display: none !important;
        width: 100% !important;
      }

    }

    @media only screen and (max-width: 480px) {

      /*
	@tab Mobile Styles
	@section Heading 1
	@tip Make the first-level headings larger in size for better readability on small screens.
	*/
      h1 {
        /*@editable*/
        font-size: 30px !important;
        /*@editable*/
        line-height: 125% !important;
      }

    }

    @media only screen and (max-width: 480px) {

      /*
	@tab Mobile Styles
	@section Heading 2
	@tip Make the second-level headings larger in size for better readability on small screens.
	*/
      h2 {
        /*@editable*/
        font-size: 26px !important;
        /*@editable*/
        line-height: 125% !important;
      }

    }

    @media only screen and (max-width: 480px) {

      /*
	@tab Mobile Styles
	@section Heading 3
	@tip Make the third-level headings larger in size for better readability on small screens.
	*/
      h3 {
        /*@editable*/
        font-size: 20px !important;
        /*@editable*/
        line-height: 150% !important;
      }

    }

    @media only screen and (max-width: 480px) {

      /*
	@tab Mobile Styles
	@section Heading 4
	@tip Make the fourth-level headings larger in size for better readability on small screens.
	*/
      h4 {
        /*@editable*/
        font-size: 18px !important;
        /*@editable*/
        line-height: 150% !important;
      }

    }

    @media only screen and (max-width: 480px) {

      /*
	@tab Mobile Styles
	@section Boxed Text
	@tip Make the boxed text larger in size for better readability on small screens. We recommend a font size of at least 16px.
	*/
      .mcnBoxedTextContentContainer .mcnTextContent,
      .mcnBoxedTextContentContainer .mcnTextContent p {
        /*@editable*/
        font-size: 14px !important;
        /*@editable*/
        line-height: 150% !important;
      }

    }

    @media only screen and (max-width: 480px) {

      /*
	@tab Mobile Styles
	@section Header Text
	@tip Make the header text larger in size for better readability on small screens.
	*/
      .headerContainer .mcnTextContent,
      .headerContainer .mcnTextContent p {
        /*@editable*/
        font-size: 16px !important;
        /*@editable*/
        line-height: 150% !important;
      }

    }

    @media only screen and (max-width: 480px) {

      /*
	@tab Mobile Styles
	@section Body Text
	@tip Make the body text larger in size for better readability on small screens. We recommend a font size of at least 16px.
	*/
      .bodyContainer .mcnTextContent,
      .bodyContainer .mcnTextContent p {
        /*@editable*/
        font-size: 16px !important;
        /*@editable*/
        line-height: 150% !important;
      }

    }

    @media only screen and (max-width: 480px) {

      /*
	@tab Mobile Styles
	@section Footer Text
	@tip Make the footer content text larger in size for better readability on small screens.
	*/
      .footerContainer .mcnTextContent,
      .footerContainer .mcnTextContent p {
        /*@editable*/
        font-size: 14px !important;
        /*@editable*/
        line-height: 150% !important;
      }

    }
  </style>
</head>

<body>
  <!--*|IF:MC_PREVIEW_TEXT|*-->
  <!--[if !gte mso 9]><!----><span class="mcnPreviewText"
    style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">*|MC_PREVIEW_TEXT|*</span>
  <!--<![endif]-->
  <!--*|END:IF|*-->
  <div style="text-align: center;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable">
      <tr>
        <td align="center" valign="top" id="bodyCell">
          <!-- BEGIN TEMPLATE // -->
          <table border="0" cellpadding="0" cellspacing="0" width="100%">
            <tr>
              <td align="center" valign="top" id="templateHeader" data-template-container>
                <!--[if (gte mso 9)|(IE)]>
                                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                    <tr>
                                    <td align="center" valign="top" width="600" style="width:600px;">
                                    <![endif]-->
                <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer">
                  <tr>
                    <td valign="top" class="headerContainer">
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock"
                        style="min-width:100%;">
                        <tbody class="mcnImageBlockOuter">
                          <tr>
                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                              <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0"
                                class="mcnImageContentContainer" style="min-width:100%;">
                                <tbody>
                                  <tr>
                                    <td class="mcnImageContent" valign="top"
                                      style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0; text-align:center;">


                                      <img align="center" alt=""
                                        src="https://mcusercontent.com/eac4ab6fc21f29c6233d47c67/images/00164ac4-9e92-d259-283d-fe8f4f7a9bad.png"
                                        width="564"
                                        style="max-width:1057px; padding-bottom: 0; display: inline !important; vertical-align: bottom;"
                                        class="mcnImage">


                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
                <!--[if (gte mso 9)|(IE)]>
                                    </td>
                                    </tr>
                                    </table>
                                    <![endif]-->
              </td>
            </tr>
            <tr>
              <td align="center" valign="top" id="templateBody" data-template-container>
                <!--[if (gte mso 9)|(IE)]>
                                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                    <tr>
                                    <td align="center" valign="top" width="600" style="width:600px;">
                                    <![endif]-->
                <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer">
                  <tr>
                    <td valign="top" class="bodyContainer">
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock"
                        style="min-width:100%;">
                        <tbody class="mcnImageBlockOuter">
                          <tr>
                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                              <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0"
                                class="mcnImageContentContainer" style="min-width:100%;">
                                <tbody>
                                  <tr>
                                    <td class="mcnImageContent" valign="top"
                                      style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0; text-align:center;">


                                      <img align="center" alt=""
                                        src="https://mcusercontent.com/eac4ab6fc21f29c6233d47c67/images/c7372632-8f90-fa1a-1b8c-bebcafc65524.png"
                                        width="456"
                                        style="max-width:456px; padding-bottom: 0; display: inline !important; vertical-align: bottom;"
                                        class="mcnImage">


                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock"
                        style="min-width:100%;">
                        <tbody class="mcnTextBlockOuter">
                          <tr>
                            <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                              <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                              <!--[if mso]>
				<td valign="top" width="600" style="width:600px;">
				<![endif]-->
                              <table align="left" border="0" cellpadding="0" cellspacing="0"
                                style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                <tbody>
                                  <tr>

                                    <td valign="top" class="mcnTextContent"
                                      style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                      <h3>¡Hola!,</h3>

                                      <p>Cada año, en el mes de octubre, como iglesia realizamos nuestra Conferencia
                                        Emmanuel, la cual fue diseñada para los líderes y voluntarios de la casa. En
                                        este marco, te hacemos llegar una invitación especial para que seas parte de
                                        esta. Podrás preinscribirse de manera gratuita llenando el formulario al que se
                                        te derivará haciendo <strong>click en los botones</strong> más
                                        abajo.&nbsp;Luego, recibirán un correo de confirmación en estos días.&nbsp;El
                                        día del evento podrás elegir tu lugar de asiento.</p>

                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]>
				</td>
				<![endif]-->

                              <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnButtonBlock"
                        style="min-width:100%;">
                        <tbody class="mcnButtonBlockOuter">
                          <tr>
                            <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;"
                              valign="top" align="center" class="mcnButtonBlockInner">
                              <table border="0" cellpadding="0" cellspacing="0" class="mcnButtonContentContainer"
                                style="border-collapse: separate !important;border-radius: 3px;background-color: #0080FF;">
                                <tbody>
                                  <tr>
                                    <td align="center" valign="middle" class="mcnButtonContent"
                                      style="font-family: Helvetica; font-size: 18px; padding: 18px;">
                                      <a class="mcnButton " title="#ConfEmmanuel22" href="" target="_blank"
                                        style="font-weight: bold;letter-spacing: -0.5px;line-height: 100%;text-align: center;text-decoration: none;color: #DCD8CC;">#ConfEmmanuel22</a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock"
                        style="min-width:100%;">
                        <tbody class="mcnTextBlockOuter">
                          <tr>
                            <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                              <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                              <!--[if mso]>
				<td valign="top" width="600" style="width:600px;">
				<![endif]-->
                              <table align="left" border="0" cellpadding="0" cellspacing="0"
                                style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                <tbody>
                                  <tr>

                                    <td valign="top" class="mcnTextContent"
                                      style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                      Fechas y horarios:<br>
                                      <br>
                                      ✲ Miércoles 26: 19:00 hrs. - 21:30 hrs.<br>
                                      ✲ Jueves 27: 18:30 hrs. - 22:00 hrs.<br>
                                      ✲ Viernes 28: 18:30 hrs. - 22:00 hrs.
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]>
				</td>
				<![endif]-->

                              <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock"
                        style="min-width:100%;">
                        <tbody class="mcnDividerBlockOuter">
                          <tr>
                            <td class="mcnDividerBlockInner" style="min-width:100%; padding:18px;">
                              <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%"
                                style="min-width:100%;">
                                <tbody>
                                  <tr>
                                    <td>
                                      <span></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--
                <td class="mcnDividerBlockInner" style="padding: 18px;">
                <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnCaptionBlock">
                        <tbody class="mcnCaptionBlockOuter">
                          <tr>
                            <td class="mcnCaptionBlockInner" valign="top" style="padding:9px;">


                              <table align="left" border="0" cellpadding="0" cellspacing="0"
                                class="mcnCaptionBottomContent" width="282">
                                <tbody>
                                  <tr>
                                    <td class="mcnCaptionBottomImageContent" align="center" valign="top"
                                      style="padding:0 9px 9px 9px;">


                                      <a href="https://forms.gle/9nLy6iXimK5xVwjZ8" title="" class="" target="_blank">


                                        <img alt=""
                                          src="https://mcusercontent.com/eac4ab6fc21f29c6233d47c67/images/5a691416-7dcd-0504-a20c-c077a04eb672.png"
                                          width="264" style="max-width:264px;" class="mcnImage">
                                      </a>

                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="mcnTextContent" valign="top" style="padding:0 9px 0 9px;" width="282">
                                      <h4>Asiste presencialmente</h4>

                                      <p>Vive la experiencia de forma presencial, inscríbete aquí. Recuerda:&nbsp;cupos
                                        limitados.</p>

                                      <p><a href="https://forms.gle/9nLy6iXimK5xVwjZ8" target="_blank">Inscríbete ahora
                                          »</a></p>

                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <table align="right" border="0" cellpadding="0" cellspacing="0"
                                class="mcnCaptionBottomContent" width="282">
                                <tbody>
                                  <tr>
                                    <td class="mcnCaptionBottomImageContent" align="center" valign="top"
                                      style="padding:0 9px 9px 9px;">


                                      <a href="https://forms.gle/eiWU7HWSRRLrpybn7" title="" class="" target="_blank">


                                        <img alt=""
                                          src="https://mcusercontent.com/eac4ab6fc21f29c6233d47c67/images/113e4883-ad11-a7af-1bab-1115fc70c88d.png"
                                          width="264" style="max-width:264px;" class="mcnImage">
                                      </a>

                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="mcnTextContent" valign="top" style="padding:0 9px 0 9px;" width="282">
                                      <h4>Asiste virtualmente</h4>

                                      <p>Disfruta de nuestra fiesta desde donde estés. Asiste a la&nbsp;conferencia de
                                        forma virtual.</p>

                                      <p><a href="https://forms.gle/eiWU7HWSRRLrpybn7" target="_blank">Inscríbete ahora
                                          »</a></p>

                                    </td>
                                  </tr>
                                </tbody>
                              </table>





                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock"
                        style="min-width:100%;">
                        <tbody class="mcnDividerBlockOuter">
                          <tr>
                            <td class="mcnDividerBlockInner" style="min-width:100%; padding:18px;">
                              <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%"
                                style="min-width:100%;">
                                <tbody>
                                  <tr>
                                    <td>
                                      <span></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--
                <td class="mcnDividerBlockInner" style="padding: 18px;">
                <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock"
                        style="min-width:100%;">
                        <tbody class="mcnTextBlockOuter">
                          <tr>
                            <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                              <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                              <!--[if mso]>
				<td valign="top" width="600" style="width:600px;">
				<![endif]-->
                              <table align="left" border="0" cellpadding="0" cellspacing="0"
                                style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                <tbody>
                                  <tr>

                                    <td valign="top" class="mcnTextContent"
                                      style="padding: 0px 18px 9px; font-size: 16px;">

                                      <strong>¡#ConfEmmanuel22!</strong>, ¡será de mucha bendición!<br>
                                      <br>
                                      Saúl y Ana Gutiérrez<br>
                                      <em>Pastores principales.</em>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]>
				</td>
				<![endif]-->

                              <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
                <!--[if (gte mso 9)|(IE)]>
                                    </td>
                                    </tr>
                                    </table>
                                    <![endif]-->
              </td>
            </tr>
            <tr>
              <td align="center" valign="top" id="templateFooter" data-template-container>
                <!--[if (gte mso 9)|(IE)]>
                                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                    <tr>
                                    <td align="center" valign="top" width="600" style="width:600px;">
                                    <![endif]-->
                <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer">
                  <tr>
                    <td valign="top" class="footerContainer">
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnFollowBlock"
                        style="min-width:100%;">
                        <tbody class="mcnFollowBlockOuter">
                          <tr>
                            <td align="center" valign="top" style="padding:9px" class="mcnFollowBlockInner">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                class="mcnFollowContentContainer" style="min-width:100%;">
                                <tbody>
                                  <tr>
                                    <td align="center" style="padding-left:9px;padding-right:9px;">
                                      <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                        style="min-width:100%;" class="mcnFollowContent">
                                        <tbody>
                                          <tr>
                                            <td align="center" valign="top"
                                              style="padding-top:9px; padding-right:9px; padding-left:9px;">
                                              <table align="center" border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                  <tr>
                                                    <td align="center" valign="top">
                                                      <!--[if mso]>
                                    <table align="center" border="0" cellspacing="0" cellpadding="0">
                                    <tr>
                                    <![endif]-->

                                                      <!--[if mso]>
                                        <td align="center" valign="top">
                                        <![endif]-->


                                                      <table align="left" border="0" cellpadding="0" cellspacing="0"
                                                        style="display:inline;">
                                                        <tbody>
                                                          <tr>
                                                            <td valign="top"
                                                              style="padding-right:10px; padding-bottom:9px;"
                                                              class="mcnFollowContentItemContainer">
                                                              <table border="0" cellpadding="0" cellspacing="0"
                                                                width="100%" class="mcnFollowContentItem">
                                                                <tbody>
                                                                  <tr>
                                                                    <td align="left" valign="middle"
                                                                      style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;">
                                                                      <table align="left" border="0" cellpadding="0"
                                                                        cellspacing="0" width="">
                                                                        <tbody>
                                                                          <tr>

                                                                            <td align="center" valign="middle"
                                                                              width="24" class="mcnFollowIconContent">
                                                                              <a href="http://www.facebook.com/iemmanuelperu"
                                                                                target="_blank"><img
                                                                                  src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-facebook-48.png"
                                                                                  alt="Facebook" style="display:block;"
                                                                                  height="24" width="24" class=""></a>
                                                                            </td>


                                                                          </tr>
                                                                        </tbody>
                                                                      </table>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>

                                                      <!--[if mso]>
                                        </td>
                                        <![endif]-->

                                                      <!--[if mso]>
                                        <td align="center" valign="top">
                                        <![endif]-->


                                                      <table align="left" border="0" cellpadding="0" cellspacing="0"
                                                        style="display:inline;">
                                                        <tbody>
                                                          <tr>
                                                            <td valign="top"
                                                              style="padding-right:10px; padding-bottom:9px;"
                                                              class="mcnFollowContentItemContainer">
                                                              <table border="0" cellpadding="0" cellspacing="0"
                                                                width="100%" class="mcnFollowContentItem">
                                                                <tbody>
                                                                  <tr>
                                                                    <td align="left" valign="middle"
                                                                      style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;">
                                                                      <table align="left" border="0" cellpadding="0"
                                                                        cellspacing="0" width="">
                                                                        <tbody>
                                                                          <tr>

                                                                            <td align="center" valign="middle"
                                                                              width="24" class="mcnFollowIconContent">
                                                                              <a href="http://www.twitter.com/iemmanuelpe"
                                                                                target="_blank"><img
                                                                                  src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-twitter-48.png"
                                                                                  alt="Twitter" style="display:block;"
                                                                                  height="24" width="24" class=""></a>
                                                                            </td>


                                                                          </tr>
                                                                        </tbody>
                                                                      </table>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>

                                                      <!--[if mso]>
                                        </td>
                                        <![endif]-->

                                                      <!--[if mso]>
                                        <td align="center" valign="top">
                                        <![endif]-->


                                                      <table align="left" border="0" cellpadding="0" cellspacing="0"
                                                        style="display:inline;">
                                                        <tbody>
                                                          <tr>
                                                            <td valign="top"
                                                              style="padding-right:10px; padding-bottom:9px;"
                                                              class="mcnFollowContentItemContainer">
                                                              <table border="0" cellpadding="0" cellspacing="0"
                                                                width="100%" class="mcnFollowContentItem">
                                                                <tbody>
                                                                  <tr>
                                                                    <td align="left" valign="middle"
                                                                      style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;">
                                                                      <table align="left" border="0" cellpadding="0"
                                                                        cellspacing="0" width="">
                                                                        <tbody>
                                                                          <tr>

                                                                            <td align="center" valign="middle"
                                                                              width="24" class="mcnFollowIconContent">
                                                                              <a href="http://www.instagram.com/emmanuel.peru"
                                                                                target="_blank"><img
                                                                                  src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-instagram-48.png"
                                                                                  alt="Link" style="display:block;"
                                                                                  height="24" width="24" class=""></a>
                                                                            </td>


                                                                          </tr>
                                                                        </tbody>
                                                                      </table>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>

                                                      <!--[if mso]>
                                        </td>
                                        <![endif]-->

                                                      <!--[if mso]>
                                        <td align="center" valign="top">
                                        <![endif]-->


                                                      <table align="left" border="0" cellpadding="0" cellspacing="0"
                                                        style="display:inline;">
                                                        <tbody>
                                                          <tr>
                                                            <td valign="top"
                                                              style="padding-right:0; padding-bottom:9px;"
                                                              class="mcnFollowContentItemContainer">
                                                              <table border="0" cellpadding="0" cellspacing="0"
                                                                width="100%" class="mcnFollowContentItem">
                                                                <tbody>
                                                                  <tr>
                                                                    <td align="left" valign="middle"
                                                                      style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;">
                                                                      <table align="left" border="0" cellpadding="0"
                                                                        cellspacing="0" width="">
                                                                        <tbody>
                                                                          <tr>

                                                                            <td align="center" valign="middle"
                                                                              width="24" class="mcnFollowIconContent">
                                                                              <a href="https://emmanuel.pe/"
                                                                                target="_blank"><img
                                                                                  src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-link-48.png"
                                                                                  alt="Website" style="display:block;"
                                                                                  height="24" width="24" class=""></a>
                                                                            </td>


                                                                          </tr>
                                                                        </tbody>
                                                                      </table>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>

                                                      <!--[if mso]>
                                        </td>
                                        <![endif]-->

                                                      <!--[if mso]>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock"
                        style="min-width:100%;">
                        <tbody class="mcnDividerBlockOuter">
                          <tr>
                            <td class="mcnDividerBlockInner" style="min-width:100%; padding:18px;">
                              <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%"
                                style="min-width: 100%;border-top: 2px solid #DCD8CC;">
                                <tbody>
                                  <tr>
                                    <td>
                                      <span></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--
                <td class="mcnDividerBlockInner" style="padding: 18px;">
                <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock"
                        style="min-width:100%;">
                        <tbody class="mcnTextBlockOuter">
                          <tr>
                            <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                              <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                              <!--[if mso]>
				<td valign="top" width="600" style="width:600px;">
				<![endif]-->
                              <table align="left" border="0" cellpadding="0" cellspacing="0"
                                style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                <tbody>
                                  <tr>

                                    <td valign="top" class="mcnTextContent"
                                      style="padding: 0px 18px 9px;color: #E6E1E1;">

                                      <em>Copyright © *|CURRENT_YEAR|* *|LIST:COMPANY|*, All rights reserved.</em><br>
                                      *|IFNOT:ARCHIVE_PAGE|* *|LIST:DESCRIPTION|*<br>
                                      <br>
                                      <strong>Our mailing address is:</strong><br>
                                      *|HTML:LIST_ADDRESS_HTML|* *|END:IF|*<br>
                                      <br>
                                      Want to change how you receive these emails?<br>
                                      You can <a href="*|UPDATE_PROFILE|*">update your preferences</a> or <a
                                        href="*|UNSUB|*">unsubscribe from this list</a>.<br>
                                      <br>
                                      *|IF:REWARDS|* *|HTML:REWARDS|* *|END:IF|*
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]>
				</td>
				<![endif]-->

                              <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
                <!--[if (gte mso 9)|(IE)]>
                                    </td>
                                    </tr>
                                    </table>
                                    <![endif]-->
              </td>
            </tr>
          </table>
          <!-- // END TEMPLATE -->
        </td>
      </tr>
    </table>
  </div>
  <script type="text/javascript" src="/PnNz_ae_E/zic/Al7eKQ/k3tup8Dzap/alwUQkx-/RlgsXw81/SzoB"></script>
</body>

</html>
