<section class="home-section home-fade home-full-height bg-dark-30 parallax-ibe" id="home"
  style="background-image: url('assets/images/background/12.jpg'); height: 100vh; ">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="font-alt titan-title-size-4 title-primeros-pasos">
        ¡Hola, te damos la bienvenida!
      </div>
      <div>
        <img src="assets/images/iconoCamara.png" class="iconoCamara" />
      </div>
    </div>
  </div>
</section>
<div class="main">
  <section class="module" id="about" style="padding: 50px 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <p class="tituloCultura">
              ¡Felicidades por haber tomado este nuevo paso de fe! Estamos muy felices porque te has
              conectado con nosotros y esperamos que desde ahora te sientas parte de Emmanuel.
              <br><br>
              Tú y tu familia son importantes para nosotros. Es nuestro deseo que en este espacio puedan
              sentir el amor de Dios, que se maravillen con su gracia y que se conecten rápidamente al
              corazón y el sentir de nuestra iglesia. Buscamos ser transformados por el amor de Dios para
              poder amar a todas las personas sin distinción, y así trascender a las nuevas generaciones.
              <br><br>
              Hay muchas personas que están emocionadas por conectar contigo. Ellos quieren conocerte
              y acompañarte en tus próximos pasos. Déjanos tus datos y un miembro de nuestro equipo se
              contactará contigo lo antes posible. ¡Estamos para servirte!
              <br><br>
              ¡Emmanuel, somos familia!
            </p>
            <p class="firmaCultura">
              Saúl y Ana Gutiérrez<br>
              <span>Pastores Principales</span>
            </p>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="col-md-12" style="margin-top: 2%;">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <h3 style="font-family: Montserrat-Bold; text-align: justify;">Déjanos tus datos para que un miembro de
              nuestro equipo pueda
              ponerse en contacto contigo.</h3>
            <br><br>
            <div class="col-md-10 col-lg-9">
              <form action="envioCorreo.php" method="POST" id="emailform">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <input type="text" name="nombreApellido" class="form-control" id="nombreApellido" placeholder="NOMBRES Y APELLIDOS" required>
                  </div>
                  <div class="form-group col-md-4">
                    <select id="sexo" name="sexo" class="form-control">
                      <option selected disabled value="0">SEXO</option>
                      <option value="M">MASCULINO</option>
                      <option value="F">FEMENINO</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <input type="number" class="form-control" id="edad" name="edad" placeholder="EDAD">
                  </div>
                  <div class="form-group col-md-4">
                    <select id="estadoCivil" name="estadoCivil" class="form-control">
                      <option selected disabled value="0">ESTADO CIVIL</option>
                      <option value="SOLTERO">SOLTERO</option>
                      <option value="CASADO">CASADO</option>
                      <option value="CONVIVIENTE">CONVIVIENTE</option>
                      <option value="DIVORCIADO/SEPARADO">DIVORCIADO / SEPARADO</option>
                      <option value="VIUDO">VIUDO</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <input type="text" class="form-control" id="pais" name="pais" placeholder="PAÍS">
                  </div>
                  <div class="form-group col-md-4">
                    <input type="text" class="form-control" id="departamento" name="departamento" placeholder="CIUDAD / DEPARTAMENTO">
                  </div>
                  <div class="form-group col-md-4">
                    <input type="text" class="form-control" id="distrito" name="distrito" placeholder="DISTRITO">
                  </div>
                  <div class="form-group col-md-4">
                    <input type="text" class="form-control" id="celular" name="celular" placeholder="CELULAR">
                  </div>
                  <div class="form-group col-md-8">
                    <input type="email" class="form-control" id="correo" name="correo" placeholder="CORREO ELECTRÓNICO">
                  </div>
                  <!-- <div class="form-group col-md-12">
                    <br>
                    <p style="margin-bottom: 0px;"><b>TRANSMISÓN QUE VISTE</b></p>
                  </div>
                  <div class="form-group col-md-4" style="height: auto;
                  display: flex;
                  align-items: center;">
                    <input class="form-check-input checkPP" type="checkbox" name="dmu" id="dmu">
                    <label class="form-check-label gridCheck" for="dmu" style="padding-top: 5%;font-size: 12px; color: #000000;">
                      DOMINGO 8 a.m. (PER)
                    </label>
                  </div>
                  <div class="form-group col-md-4" style="height: auto;
                  display: flex;
                  align-items: center;">
                    <input class="form-check-input checkPP" type="checkbox" name="dm" id="dm">
                    <label class="form-check-label gridCheck" for="dm" style="padding-top: 5%;font-size: 12px; color: #000000;">
                      DOMINGO 10 a.m. (PER)
                    </label>
                  </div>
                  <div class="form-group col-md-4" style="height: auto;
                  display: flex;
                  align-items: center;">
                    <input class="form-check-input checkPP" type="checkbox" name="dn" id="dn">
                    <label class="form-check-label gridCheck" for="dn" style="padding-top: 5%;font-size: 12px; color: #000000;">
                      DOMINGO 12 p.m. (PER)
                    </label>
                  </div>
                  <div class="form-group col-md-4" style="height: auto;
                  display: flex;
                  align-items: center;">
                    <input class="form-check-input checkPP" type="checkbox" name="dmd" id="dmd">
                    <label class="form-check-label gridCheck" for="dmd" style="padding-top: 5%;font-size: 12px; color: #000000;">
                      DOMINGO 5 p.m. (PER)
                    </label>
                  </div>
                  <div class="form-group col-md-4" style="height: auto;
                  display: flex;
                  align-items: center;">
                    <input class="form-check-input checkPP" type="checkbox" name="dmt" id="dmt">
                    <label class="form-check-label gridCheck" for="dmt" style="padding-top: 5%;font-size: 12px; color: #000000;">
                      DOMINGO 7 p.m. (PER)
                    </label>
                  </div>
                  <div class="form-group col-md-4" style="height: auto;
                  display: flex;
                  align-items: center;">
                    <input class="form-check-input checkPP" type="checkbox" name="m" id="m">
                    <label class="form-check-label gridCheck" for="m" style="padding-top: 5%;font-size: 12px; color: #000000;">
                      MIÉRCOLES (PER)
                    </label>
                  </div>
                  <div class="form-group col-md-4" style="height: auto;
                  display: flex;
                  align-items: center;">
                    <input class="form-check-input checkPP" type="checkbox" name="dm" id="dm">
                    <label class="form-check-label gridCheck" for="dmC" style="padding-top: 5%;font-size: 12px; color: #000000;">
                      DOMINGO (CHI)
                    </label>
                  </div>
                  <div class="form-group col-md-4" style="height: auto;
                  display: flex;
                  align-items: center;">
                    <input class="form-check-input checkPP" type="checkbox" name="dn" id="dn">
                    <label class="form-check-label gridCheck" for="dnC" style="padding-top: 5%;font-size: 12px; color: #000000;">
                      MIÉRCOLES (CHI)
                    </label>
                  </div>
                  <div class="form-group col-md-4" style="height: auto;
                  display: flex;
                  align-items: center;">
                    <input class="form-check-input checkPP" type="checkbox" name="dn" id="dn">
                    <input type="text" class="form-control" id="otros" name="otros" placeholder="OTROS" style="margin-left: 4%;">
                  </div> -->
                  <div class="form-group col-md-12" style="height: auto;
                  display: flex;
                  align-items: center;">
                    <input class="form-check-input checkPP" type="checkbox" id="politicas">
                    <label class="form-check-label gridppAA" for="politicas" style="padding-top: 1%; color: #000000; font-size: 12px ">
                      Doy consentimiento para que la Iglesia Emmanuel almacene mis datos, y de esta manera
                      poder contactarse conmigo y facilitar mi próximo paso en la fe.
                    </label>
                  </div>
                  <p style="font-family: 'Montserrat-Medium'; font-size: 13px; color: red;display: none;" id="alert">
                    Acepte el consentimiento para el almacenamiento de datos
                  </p>
                  <p style="font-family: 'Montserrat-Medium'; font-size: 13px; color: red;display: none;" id="alert1">
                    Todos los campos son obligatorios
                  </p>
                </div>
                <div class="form-group col-md-12">
                  <a (click)="onSubmit()" class="btn btn-primary">ENVIAR</a>
                </div>
              </form>
            </div>
            <div class="col-md-2 col-lg-3"></div>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>
  </section>
</div>
