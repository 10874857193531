import { Component, OnInit } from '@angular/core';
import { EmailService } from '../services/email.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ConferenciaService } from '../services/conferencia.service';

@Component({
  selector: 'app-clase-magistral',
  templateUrl: './clase-magistral.component.html',
  styleUrls: ['./clase-magistral.component.css']
})
export class ClaseMagistralComponent implements OnInit {

  contactanos: String = "<CONTÁCTANOS>";
  horarios: String = "<HORARIOS>";
  preguntas: String = "<PREGUNTAS";
  frecuentes: String = "FRECUENTES>";
  experiencias: String = "<EXPERIENCIAS>";
  constructor(
    private emailService: EmailService,
    private spinner: NgxSpinnerService,
    private conferenciaService: ConferenciaService) { }

  ngOnInit(): void {
    // window.addEventListener('scroll', this.scroll, true);
    // var detalles = document.getElementsByClassName('detallePreguntasFrecuentes');
    // [].forEach.call(detalles, function (value, key) {
    //   value.style.display = 'none';
    // });

    //  // window.location.href = 'https://cursos.emmanuel.pe/5sm-singular'
    //  window.addEventListener('scroll', this.scroll, true);

    //  (document.getElementById('pin1') as HTMLInputElement).value = '';
    //  (document.getElementById('pin2') as HTMLInputElement).value = '';
    //  (document.getElementById('pin3') as HTMLInputElement).value = '';
    //  (document.getElementById('pin4') as HTMLInputElement).value = '';
    //  (document.getElementById('pin5') as HTMLInputElement).value = '';
    //  (document.getElementById('pin6') as HTMLInputElement).value = '';
    //  document.getElementById('alertError').style.display = 'none';

    //  // Set the date we're counting down to
    //  // tslint:disable-next-line: prefer-const
    //  const countDownDate = new Date('August 12, 2021 17:00:00 GMT-0500').getTime();

    //  // Update the count down every 1 second
    //  const x = setInterval(function () {

    //    // Get today's date and time

    //    // now1.setUTCDate();
    //    const now = new Date().getTime();

    //    // Find the distance between now and the count down date
    //    const distance = countDownDate - now;

    //    // Time calculations for days, hours, minutes and seconds
    //    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    //    // console.log(hours + 'h1 ' + minutes + 'm1 ' + seconds + 's1 ');


    //    // Output the result in an element with id="demo"
    //    document.getElementById('contdownDemo').innerHTML = days + 'd ' + hours + 'h '
    //      + minutes + 'm ' + seconds + 's ';

    //    // If the count down is over, write some text
    //    if (distance < 0) {
    //      clearInterval(x);
    //      document.getElementById('btnPre').style.display = 'none';
    //      document.getElementById('btnPost').style.display = 'block';
    //    }
    //  }, 1000);
  }

  btnPreguntaFrecuente($event: any, item: number) {
    var detalles = document.getElementsByClassName('detallePreguntasFrecuentes');
    [].forEach.call(detalles, function (value, key) {
      if (item !== key)
        value.style.display = 'none';
      else
        value.style.display = 'block';
    });
  }

  scroll = (event): void => {
    const demo1 = document.getElementsByClassName('parallax-ibe');
    const demo2 = event.path[0].documentElement.scrollTop;
    [].forEach.call(demo1, function (el) {
      const height = el.getBoundingClientRect().top;
      const height1 = el.offsetHeight;
      if (height < 0 && (height1 + height) > 0) {
        el.style.top = (height * -1) / 4 + 'px';
        el.style.backgroundPositionY = (height * -1) / 2 + 'px';
      } else {
        el.style.top = 0 + 'px';
        el.style.backgroundPositionY = 0 + 'px';
      }
    });
  }

  enviarMensaje() {
    document.getElementById('emailOK').style.display = 'none';
    document.getElementById('emailERROR').style.display = 'none';
    const nombre = (document.getElementById('nombreApellido') as HTMLInputElement).value;
    const correo = (document.getElementById('correo') as HTMLInputElement).value;
    const mensaje = (document.getElementById('mensaje') as HTMLInputElement).value;
    const jsonString = '{"nombre":"' + nombre + '","correo":"' + correo + '","mensaje":"' + mensaje + '"}';
    const request = JSON.parse(jsonString);
    this.spinner.show();
    this.emailService.envioEmailClaseMagistral(request).subscribe(datos => {
      this.spinner.hide();
      if (datos.resultado) {
        document.getElementById('emailOK').style.display = 'block';
        document.getElementById('emailERROR').style.display = 'none';
      }
      else {
        document.getElementById('emailOK').style.display = 'none';
        document.getElementById('emailERROR').style.display = 'block';
      }
    });
  }

  onClickKeydownEvent1($event) {
    (document.getElementById('pin1') as HTMLInputElement).value = '';
  }

  onKeydownEvent1(event: KeyboardEvent): void {
    const value = (document.getElementById('pin1') as HTMLInputElement).value;
    if (value !== '') {
      const reg = new RegExp(/[^a-zA-Z0-9\-\/]/);
      if (reg.test(event.key)) {
        (document.getElementById('pin1') as HTMLInputElement).value = '';
      } else {
        (document.getElementById('pin2') as HTMLInputElement).focus();
      }
    }
  }
  // tslint:disable-next-line: typedef
  onClickKeydownEvent2($event) {
    (document.getElementById('pin2') as HTMLInputElement).value = '';
  }

  onKeydownEvent2(event: KeyboardEvent): void {
    const value = (document.getElementById('pin2') as HTMLInputElement).value;
    if (value !== '') {
      const reg = new RegExp(/[^a-zA-Z0-9\-\/]/);
      if (reg.test(event.key)) {
        (document.getElementById('pin2') as HTMLInputElement).value = '';
      } else {
        (document.getElementById('pin3') as HTMLInputElement).focus();
      }
    }
  }
  // tslint:disable-next-line: typedef
  onClickKeydownEvent3($event) {
    (document.getElementById('pin3') as HTMLInputElement).value = '';
  }

  onKeydownEvent3(event: KeyboardEvent): void {
    const value = (document.getElementById('pin3') as HTMLInputElement).value;
    if (value !== '') {
      const reg = new RegExp(/[^a-zA-Z0-9\-\/]/);
      if (reg.test(event.key)) {
        (document.getElementById('pin3') as HTMLInputElement).value = '';
      } else {
        (document.getElementById('pin4') as HTMLInputElement).focus();
      }
    }
  }
  // tslint:disable-next-line: typedef
  onClickKeydownEvent4($event) {
    (document.getElementById('pin4') as HTMLInputElement).value = '';
  }

  onKeydownEvent4(event: KeyboardEvent): void {
    const value = (document.getElementById('pin4') as HTMLInputElement).value;
    if (value !== '') {
      const reg = new RegExp(/[^a-zA-Z0-9\-\/]/);
      if (reg.test(event.key)) {
        (document.getElementById('pin4') as HTMLInputElement).value = '';
      } else {
        (document.getElementById('pin5') as HTMLInputElement).focus();
      }
    }
  }
  // tslint:disable-next-line: typedef
  onClickKeydownEvent5($event) {
    (document.getElementById('pin5') as HTMLInputElement).value = '';
  }

  onKeydownEvent5(event: KeyboardEvent): void {
    const value = (document.getElementById('pin5') as HTMLInputElement).value;
    if (value !== '') {
      const reg = new RegExp(/[^a-zA-Z0-9\-\/]/);
      if (reg.test(event.key)) {
        (document.getElementById('pin5') as HTMLInputElement).value = '';
      } else {
        (document.getElementById('pin6') as HTMLInputElement).focus();
      }
    }
  }
  // tslint:disable-next-line: typedef
  onClickKeydownEvent6($event) {
    (document.getElementById('pin6') as HTMLInputElement).value = '';
  }

  onKeydownEvent6(event: KeyboardEvent): void {
    const value = (document.getElementById('pin6') as HTMLInputElement).value;
    if (value !== '') {
      const reg = new RegExp(/[^a-zA-Z0-9\-\/]/);
      if (reg.test(event.key)) {
        (document.getElementById('pin6') as HTMLInputElement).value = '';
      }
    }
  }

  onValidarCodigo($event) {
    document.getElementById('alertError').style.display = 'none';

    const pin1 = (document.getElementById('pin1') as HTMLInputElement).value;
    const pin2 = (document.getElementById('pin2') as HTMLInputElement).value;
    const pin3 = (document.getElementById('pin3') as HTMLInputElement).value;
    const pin4 = (document.getElementById('pin4') as HTMLInputElement).value;
    const pin5 = (document.getElementById('pin5') as HTMLInputElement).value;
    const pin6 = (document.getElementById('pin6') as HTMLInputElement).value;
    let codigo = pin1 + pin2 + pin3 + pin4 + pin5 + pin6;
    codigo = codigo.toUpperCase();
    const jsonString = '{"token":"' + codigo + '"}';
    const request = JSON.parse(jsonString);
    this.spinner.show();
    this.conferenciaService.validarTokenConferencia(request).subscribe(datos => {
      this.spinner.hide();
      console.log(datos);
      if (datos.resultado)
        window.location.href = 'https://live.emmanuel.pe/';
      else
        document.getElementById('alertError').style.display = 'block';
    });

  }

  btnIngresar($event) {
    (document.getElementById('pin1') as HTMLInputElement).value = '';
    (document.getElementById('pin2') as HTMLInputElement).value = '';
    (document.getElementById('pin3') as HTMLInputElement).value = '';
    (document.getElementById('pin4') as HTMLInputElement).value = '';
    (document.getElementById('pin5') as HTMLInputElement).value = '';
    (document.getElementById('pin6') as HTMLInputElement).value = '';
    document.getElementById('alertError').style.display = 'none';
  }


}
