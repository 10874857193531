<hr class="divider-d">
<footer class="footer bg-dark">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <p class="copyright font-alt">&copy; 2024&nbsp;<a href="index.html">Emmanuel</a></p>
      </div>
      <div class="col-sm-6">
        <div class="footer-social-links">
          <a href="https://www.youtube.com/IglesiaEmmanuelPeru"><i class="fa fa-youtube-play"></i></a>
          <a href="https://www.instagram.com/emmanuel.peru/"><i class="fa fa-instagram"></i></a>
          <a href="https://www.facebook.com/iemmanuelperu"><i class="fa fa-facebook-official"></i></a>
        </div>
      </div>
    </div>
  </div>
</footer>