<section class="home-section home-fade home-full-height bg-dark-30 parallax-ibe" id="home"
style="background-image: url('assets/images/background/33.jpg'); height: 100vh;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-2">
            <p class="hashTitulo1">
              #EmmanuelEnCasa<br>
              <span>#EmmanuelEnCasa</span>
            </p>
          </div>
          <div class="col-md-5"></div>
        </div>
      </div>
      <div class="col-md-12">
        <p class="title1GS">
          <span>Gente</span><br>
          Sabia
        </p>
        <p class="title2GS">
          Grupo de adultos mayores de 60 a más
        </p>
        <p style="text-align: center; padding-left: 5%; padding-right: 5%;">
          <img src="assets/images/background/franjaGS.png" />
        </p>
      </div>
    </div>
  </div>
</section>
<section class="module" id="about" data-background="assets/images/background/34.jpg" style="background-image: url('assets/images/background/34.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-sm-2 col-md-4"></div>
          <div class="col-sm-8 col-md-4">
            <p class="hashTitulo1">
              #GenteSabia<br>
              <span>#GenteSabia</span>
            </p>
            <br>
            <p class="title2GS">
              Acompañamos con amor a los adultos mayores.
              En esta temporada debemos cuidarlos con
              nuevas estrategias integrales.
            </p>
          </div>
          <div class="col-sm-2 col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="module" id="dar" style="background-image: url('assets/images/background/35.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-sm-2 col-md-3"></div>
          <div class="col-sm-8 col-md-6">
            <p class="hashTitulo1">
              #GenteSabia<br>
              <span>#GenteSabia</span>
            </p>
            <p class="title3GS">
              Reuniones<br>
              <span>en vivo</span>
            </p>
            <br>
            <p class="title2GS">
              Realizamos transmisiones en vivo con temas como la estimulación
              funcional y el autocuidado de la salud a través de ejercicios
              prácticos. Además, damos un mensaje de esperanza y confianza en Dios.<br><br>
              Únete todos los terceros viernes del mes,
              a las 9 a.m. (PER), por la página de Facebook y el canal
              de YouTube de nuestra Iglesia Emmanuel.
            </p>
          </div>
          <div class="col-sm-2 col-md-3"></div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="module" id="dar" style="background-image: url('assets/images/background/36.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-sm-2 col-md-4"></div>
          <div class="col-sm-8 col-md-4">
            <p class="hashTitulo1">
              #GenteSabia<br>
              <span>#GenteSabia</span>
            </p>
            <p class="title3GS">
              Contáctanos
            </p>
            <br>
            <p class="title2GS">
              Si deseas ser acompañado, ¡comunícate con nosotros!
              Queremos escuchar tus necesidades y orar por ti.
              <br><br>
              Atención de 10 a.m. a 8 p.m. (PER)
            </p>

            <div style="text-align: center;">
              <a class="section-scroll btn btn-border-w btn-round" href="http://wa.me/+51997510168" target="_blank"
                style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;">ESCRÍBENOS</a>
            </div>
          </div>
          <div class="col-sm-2 col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
</section>
