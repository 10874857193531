
<div class="fadein">
  <img id="f1" src="assets/images/presencial/general/img1.jpg" />
  <img id="f2" src="assets/images/presencial/general/img2.jpg" />
  <img id="f3" src="assets/images/presencial/general/img3.jpg" />
  <img id="f4" src="assets/images/presencial/general/img4.jpg" />
</div>
<section class="parallax-ibe background" id="home">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-sm-12 col-md-12">
        <div class="row">
          <div class="col-sm-2 col-md-4"></div>
          <div class="col-sm-8 col-md-4">
            <p class="titulo">
              ENTRADA PRESENCIAL
            </p>
            <app-formulario-reunion-presencial [idTipoEvento]="15"></app-formulario-reunion-presencial>
          </div>
          <div class="col-sm-2 col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
</section>

