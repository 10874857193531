<input class="inputLogin" type="text" id="usuario" placeholder="USUARIO:" width="100%" />
<input class="inputLogin" type="password" id="password" placeholder="PASSWORD:" width="100%" />
<p style="color:white; font-size: 18px; display: none; margin: 0;" id="campoObligatorio">Ingrese su usuario y password</p>
<p style="color:white; font-size: 18px; display: none; margin: 0;" id="incorrecto">Usuario o password incorrecto</p>
<p style="color:white; font-size: 18px; display: none; margin: 0;" id="errorGenerico">Error, vuelva a intentarlo</p>
<p style="color:white; font-size: 18px; display: none; margin: 0;" id="errorDoble">El usuario ya esta conectado, intente con otro.</p>
<br>
<p style="text-align: center;">
  <input class="btnLogin" value="INGRESAR" (click)="btnIngresar()" type="button" />
</p>
<ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="#fff" type="ball-running-dots">
  <p style="font-size: 20px; color: #ffffff">Validando...</p>
</ngx-spinner>
