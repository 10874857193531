import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reunion-general',
  templateUrl: './reunion-general.component.html',
  styleUrls: ['./reunion-general.component.css']
})

export class ReunionGeneralComponent implements OnInit {

  arrayImg: string[] = ['/assets/images/presencial/general/img1.jpg', '/assets/images/presencial/general/img2.jpg', '/assets/images/presencial/general/img3.jpg', '/assets/images/presencial/general/img4.jpg'];
  constructor() { }

  ngOnInit(): void {
  }

  onCambioBackground(){
    var demo = (document.getElementById('home'));
    demo.style.opacity = "0.5";
    demo.style.backgroundColor = "black";
  }
}
