<section class="home-parallax home-fade gb-parallax" id="home"
  data-background="assets/images/background/47.jpg"
  style="background-image: url('assets/images/background/47.jpg'); height: 60vh; background-size: cover;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
      <div class="col-sm-8 col-md-6 col-lg-6">
        <p class="tituloGrupo">Grupos de Redes</p>
        <!--
        <a class="section-scroll btn1" href="#detalleGrupos">
          <p class="tituloGrupo1">
            Conoce más<br>
            <i class="fa fa-angle-down"></i>
          </p>
        </a>
        -->
      </div>
      <div class="col-sm-2 col-md-3 col-lg-3"></div>
    </div>
  </div>
</section>
<div class="main" id="detalleGrupos">
  <section class="module zk1 parallax-ibe" id="about" style="background-image: url('assets/images/background/48.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <p class="titleGr1 ventanaGrupo1">
                Como comunidad nos mantenemos cerca a través de grupos de
                conexión llamados Redes. Éstas son espacios donde nos
                edificamos, consolamos y acompañamos mutuamente. Aquí,
                en un ambiente de vulnerabilidad, nos relacionamos,
                entablamos amistades y buscamos crecer juntos en la
                verdad.
              </p>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="module zk1 parallax-ibe" id="about" style="background-image: url('assets/images/background/13.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <p class="tituloGrupo">¿Cómo nos reunimos?</p>
              <p class="titleGr1 ventanaGrupo2 mb-40">
                Nos reunimos presencialmente, o nos conectamos mediante plataformas virtuales. <br><br>
                Tenemos Redes de hombres, mujeres, mixtas y de matrimonios.
              </p>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <section class="module zk1 parallax-ibe" id="about" style="background-image: url('assets/images/background/49.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <p class="tituloGrupo">¿Deseas unirte a un grupo de red?</p>
              <div style="text-align: center;">
                <a class="section-scroll btn btn-border-w btn-round" routerLink="/crece/grupos/formulario"
                 style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;
                  background-color: #000000;border: #000000;padding-left: 7%; padding-right: 7%;border-radius: 5px;
                  margin-left: 5%;margin-right: 5%;margin-top: 5%;">INGRESA AQUÍ</a>
              </div>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
