import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-extremo-colegios',
  templateUrl: './extremo-colegios.component.html',
  styleUrls: ['./extremo-colegios.component.css']
})
export class ExtremoColegiosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {
    const demo1 = document.getElementsByClassName('parallax-ibe');
    const demo2 = event.path[0].documentElement.scrollTop;
    [].forEach.call(demo1, function (el) {
      const height = el.getBoundingClientRect().top;
      const height1 = el.offsetHeight;
      if (height < 0 && (height1 + height) > 0) {
        el.style.top = (height * -1)/4 + 'px';
        el.style.backgroundPositionY = (height * -1)/2 + 'px';
      }else{
        el.style.top = 0  + 'px';
        el.style.backgroundPositionY = 0 + 'px';
      }
    });
  }
}
