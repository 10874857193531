import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HighlightOnHoverDirective } from './commons/menu/directives/highlight-on-hover.directive';
import { HttpClientModule } from '@angular/common/http';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './commons/menu/menu.component';
import { LoaderComponent } from './commons/loader/loader.component';
import { SliderComponent } from './home/slider/slider.component';
import { CuerpoComponent } from './home/cuerpo/cuerpo.component';
import { HomeComponent } from './home/home.component';
import { Route, RouterModule } from '@angular/router';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { PieComponent } from './commons/pie/pie.component';
import { CulturaComponent } from './nosotros/cultura/cultura.component';
import { PrimerosPasosComponent } from './nosotros/primeros-pasos/primeros-pasos.component';
import { ReunionesComponent } from './reuniones/reuniones.component';
import { GeneralComponent } from './reuniones/general/general.component';
import { ZonaKidsComponent } from './reuniones/zona-kids/zona-kids.component';
import { PeopleComponent } from './reuniones/people/people.component';
import { JovenesComponent } from './reuniones/jovenes/jovenes.component';
import { MixturaComponent } from './reuniones/mixtura/mixtura.component';
import { GenteSabiaComponent } from './reuniones/gente-sabia/gente-sabia.component';
import { DarComponent } from './dar/dar.component';
import { MovilizacionComponent } from './involucrate/movilizacion/movilizacion.component';
import { ExtremoColegiosComponent } from './involucrate/extremo-colegios/extremo-colegios.component';
import { GruposComponent } from './crece/grupos/grupos.component';
import { ConferenciaComponent } from './conferencia/conferencia.component';
import { FormularioComponent } from './crece/grupos/formulario/formulario.component';
import { CorreoComponent } from './commons/notificacion/correo/correo.component';
import { SingularComponent } from './singular/singular.component';
import { ClaseMagistralComponent } from './clase-magistral/clase-magistral.component';
import { TicketVirtualComponent } from './reuniones/ticket-virtual/ticket-virtual.component';
import { LoginComponent } from './conferencia/login/login.component';
import { CookieService } from 'ngx-cookie-service';
import { ReunionJovenesComponent } from './presencial/reunion-jovenes/reunion-jovenes.component';
import { FormularioReunionPresencialComponent } from './presencial/common/formulario/formulario.component';
import { ReunionGeneralComponent } from './presencial/reunion-general/reunion-general.component';
import { DedicacionesComponent } from './dedicaciones/dedicaciones.component';
import { EnEstoCreemosComponent } from './nosotros/en-esto-creemos/en-esto-creemos.component';
import { MailingComponent } from './mailing/mailing.component';
import { FormsModule } from '@angular/forms';

const routes: Route[] = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'nosotros/cultura', component: CulturaComponent},
  {path: 'nosotros/primeros-pasos', component: PrimerosPasosComponent},
  {path: 'nosotros/en-esto-creemos', component: EnEstoCreemosComponent},
  {path: 'reuniones', component: GeneralComponent},
  {path: 'zona-kids', component: ZonaKidsComponent},
  {path: 'people', component: PeopleComponent},
  {path: 'motion', component: JovenesComponent},
  {path: 'mixtura', component: MixturaComponent},
  {path: 'gente-sabia', component: GenteSabiaComponent},
  {path: 'dar', component: DarComponent},
  {path: 'movilizacion', component: MovilizacionComponent},
  {path: 'extremo-colegios', component: ExtremoColegiosComponent},
  {path: 'crece/grupos', component: GruposComponent},
  {path: 'conferencia', component: ConferenciaComponent},
  {path: 'crece/grupos/formulario', component: FormularioComponent},
  {path: 'formulario/envio-exitoso', component: CorreoComponent},
  {path: 'singular', component: SingularComponent},
  {path: 'clase-magistral', component: ClaseMagistralComponent},
  {path: 'jovenes/registro-reunion', component: TicketVirtualComponent},
  {path: 'conferencia/login-demo', component: LoginComponent},
  {path: 'entrada-presencial/jovenes', component: ReunionJovenesComponent},
  {path: 'entrada-presencial/general', component: ReunionGeneralComponent},
  {path: 'dedicaciones', component: DedicacionesComponent},
  {path: 'mailing', component: MailingComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HighlightOnHoverDirective,
    LoaderComponent,
    SliderComponent,
    CuerpoComponent,
    HomeComponent,
    NosotrosComponent,
    PieComponent,
    CulturaComponent,
    PrimerosPasosComponent,
    ReunionesComponent,
    GeneralComponent,
    ZonaKidsComponent,
    PeopleComponent,
    JovenesComponent,
    MixturaComponent,
    GenteSabiaComponent,
    DarComponent,
    MovilizacionComponent,
    ExtremoColegiosComponent,
    GruposComponent,
    ConferenciaComponent,
    FormularioComponent,
    CorreoComponent,
    SingularComponent,
    ClaseMagistralComponent,
    TicketVirtualComponent,
    LoginComponent,
    ReunionJovenesComponent,
    FormularioReunionPresencialComponent,
    ReunionGeneralComponent,
    DedicacionesComponent,
    EnEstoCreemosComponent,
    MailingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    NgxPageScrollCoreModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule
  ],
  providers: [DatePipe, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
