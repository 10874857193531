import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-primeros-pasos',
  templateUrl: './primeros-pasos.component.html',
  styleUrls: ['./primeros-pasos.component.css']
})
export class PrimerosPasosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line: typedef
  onSubmit() {
    const politicas = (document.getElementById('politicas') as HTMLInputElement).checked;
    const alert = (document.getElementById('alert') as HTMLInputElement);
    const alert1 = (document.getElementById('alert1') as HTMLInputElement);
    alert1.style.display = 'none';
    alert.style.display = 'none';
    if (!politicas){
      alert.style.display = 'block';
    }else{
      const nombreApellido = (document.getElementById('nombreApellido') as HTMLInputElement).value;
      const sexo = (document.getElementById('sexo') as HTMLInputElement).value;
      const edad = (document.getElementById('edad') as HTMLInputElement).value;
      const estadoCivil = (document.getElementById('estadoCivil') as HTMLInputElement).value;
      const distrito = (document.getElementById('distrito') as HTMLInputElement).value;
      const departamento = (document.getElementById('departamento') as HTMLInputElement).value;
      const pais = (document.getElementById('pais') as HTMLInputElement).value;
      const celular = (document.getElementById('celular') as HTMLInputElement).value;
      const correo = (document.getElementById('correo') as HTMLInputElement).value;

      if (nombreApellido === '' || sexo === '0' || edad === '' || estadoCivil === '0' ||
      distrito === '' || departamento === '' || pais === '' || celular === '' || correo === ''){
        alert1.style.display = 'block';
      }else{
        const form = document.getElementById('emailform') as HTMLFormElement;
        form.submit();
      }

    }
  }

}
