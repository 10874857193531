<section class="home-fade bg-dark-30 gb-parallax backgr mobile" id="home"
  style="background-image: url('assets/images/background/10.jpg'); height: 45vh;">
  <div class="titan-caption">
    <div class="caption-content">
    </div>
  </div>
</section>
<img src="assets/images/background/30.jpg" width="100%" class="desktop" />
<div class="main">
  <section class="module" id="about" style="padding: 50px 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <p class="tituloCultura">
              Somos Emmanuel
            </p>
            <p class="detalleCultura">
              Una iglesia cristiana que alcanza a la comunidad a través del mensaje de Jesús.
            </p>
          </div>
          <div class="col-md-3"></div>
        </div>
        <div class="col-md-12">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <p class="tituloCultura">
              Nuestra visión
            </p>
            <p class="detalleCultura">
              Ser transformados por el amor de Dios,
              amar a todas las personas y trascender a las nuevas generaciones.
            </p>
          </div>
          <div class="col-md-3"></div>
        </div>
        <div class="col-md-12">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <p class="tituloCultura">
              Nuestros valores
            </p>
            <p class="detalleCultura">
              Intimidad con Dios<br>
              Comunidad<br>
              Amor desinteresado<br>
              Trascendencia<br>
              Servicio<br>
              Profundidad<br>
              Honra
            </p>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </div>
  </section>
</div>
