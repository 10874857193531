<!--
<section class="home-section home-fade home-full-height bg-dark-30 parallax-ibe" id="home"
  style="background-image: url('assets/images/background/13.jpg'); height: 100vh;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-sm-2 col-md-3 col-lg-4"></div>
      <div class="col-sm-8 col-md-6 col-lg-4">
        <div class="font-alt reuGeneralTitulo">
          <b>
            Creemos que somos la iglesia,
            y seguimos siéndolo en
            el lugar donde estamos.
          </b>
        </div>
      </div>
      <div class="col-sm-2 col-md-3 col-lg-4"></div>
    </div>
  </div>
</section>
-->
<div class="main">
  <section class="module" id="about" data-background="assets/images/background/fondo03.jpg"
    style="background-image: url('assets/images/background/fondo03.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <!-- <p class="reuGeneralTitulo1">Reuniones <span style="font-family: Montserrat-ExtraBold;">Online</span></p> -->
          <p class="reuGeneralTitulo1">Reuniones</p>
          <p class="reuGeneralParrafo1">Participa de nuestras reuniones presenciales o únete online desde la comodidad de tu hogar.
          </p>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="col-sm-1 col-md-1 col-lg-1"></div>
            <div class="col-sm-10 col-md-10 col-lg-10" style="padding: 0px; margin: 0px;">
              <div class="movi-rg-1">
                <div class="font-alt titan-title-size-2" style="font-family: Montserrat-Light;
                  text-align: center;color: #ffffff;">
                  <span style="font-family: Montserrat-Bold;">PERÚ</span><br>
                  <span style="font-family: Montserrat-Bold;">Domingos de Celebración</span><br>
                  <span style="font-family: Montserrat-Bold;">San Isidro</span><br>
                  07:30 a.m. / 09:00 a.m. / 11:00 a.m. / 12:30 p.m. / 06:00 p.m. <br><br>
                  <span style="font-family: Montserrat-Bold;">Independencia</span><br>
                  <span style="font-family: Montserrat-Bold; font-size: 11px;">MegaPlaza, Cinemark</span><br>
                  09:00 a.m. / 11:00 a.m. <br><br>
                  <span style="font-family: Montserrat-Bold;">Mall del Sur</span><br>
                  <span style="font-family: Montserrat-Bold; font-size: 11px;">Cineplanet</span><br>
                  11:00 a.m. <br><br>
                  <span hidden class="nota-rf1" style="color: #ffffff; text-align: center;">
                    <a href="https://us02web.zoom.us/j/84582293171?pwd=SGd1MDRiNUxBeVlMQ2VLOG9URWNPUT09" target="_blank" style="color: #ffffff">
                      Al finalizar nuestras reuniones ingresa a Emmanuel Conecta via Zoom, haciendo <b><u>click aquí</u></b>.
                    </a>
                  </span>
                  <br>
                  <span style="font-family: Montserrat-Bold;">Miércoles de Oración</span><br>
                  07:30 p.m.<br><br>
                </div>
              </div>
            </div>
            <div class="col-sm-1 col-md-1 col-lg-1"></div>
          </div>
          <div style="text-align: center;" class="mobile">
            <a class="section-scroll btn btn-border-w btn-round" href="https://www.youtube.com/IglesiaEmmanuelPeru" target="_blank"
              style="font-family: Montserrat-Bold;font-size: 13px;text-align: center;">ÚNETE</a>
          </div>
          <br><br><br>
        </div>
        <div class="col-md-12 desktop">
          <div style="text-align: center;">
            <a class="section-scroll btn btn-border-w btn-round" href="https://www.youtube.com/IglesiaEmmanuelPeru" target="_blank"
              style="font-family: Montserrat-Bold;font-size: 13px;text-align: center;">ÚNETE</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module" id="dar" style="background-image: url('assets/images/background/15.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <p class="reuGeneralTitulo1">Nuestras <span style="font-family: Montserrat-ExtraBold;">Redes Sociales</span></p>
            <br>
          </div>
          <div class="col-md-2"></div>
          <br>
          <div class="col-md-12">
            <div class="col-sm-1 col-md-2 col-lg-3"></div>
            <div class="col-sm-10 col-md-8 col-lg-6" style="padding: 0px; margin: 0px;">
              <div class="darWindows">
                <div class="font-alt titan-title-size-2" style="font-family: Montserrat-Light;
              text-align: center;color: #ffffff;">
                  <span class="nota-rf1" style="font-size: 14px;">
                    Síguenos y no te pierdas de todos los anuncios
                    y eventos que tenemos. También puedes utilizarlas para
                    comunicarte con nosotros. ¡Estaremos atentos!
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-1 col-md-2 col-lg-3"></div>
          </div>
          <div style="text-align: center;">
            <a class="section-scroll btn btn-border-w btn-round" href="https://www.facebook.com/iemmanuelperu" target="_blank"
              style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px;">FACEBOOK &nbsp; <i class="fa fa-facebook-official"></i></a>

            <a class="section-scroll btn btn-border-w btn-round" href="https://www.instagram.com/emmanuel.peru/" target="_blank"
              style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px">INSTAGRAM &nbsp; <i class="fa fa-instagram"></i></a>

            <a class="section-scroll btn btn-border-w btn-round" href="https://www.youtube.com/IglesiaEmmanuelPeru" target="_blank"
              style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 15px">YOUTUBE &nbsp; <i class="fa fa-youtube-play"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
