<section class="home-section home-fade home-full-height parallax-ibe" id="home"
  style="background-image: url('assets/images/background/28.jpg'); height: 100vh; background-position-x: 46.5%;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-md-12">
        <div class="col-sm-2 col-md-3"></div>
        <div class="col-sm-8 col-md-6">
          <div class="col-md-12">
            <img src="assets/images/background/mixturaLogo.png" />
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="font-alt mb-10 titan-title-size-2 iconMixtura">
              <a target="_blank" href="https://www.facebook.com/MixturaEmmanuel"
                style="color: #000000;font-size: 25px;margin: 2px 10px;"><i class="fa fa-facebook-official"
                  aria-hidden="true"></i></a>
              <a target="_blank" href="https://www.instagram.com/mixtura_emmanuel/"
                style="color: #000000;font-size: 25px;margin: 2px 10px;"><i class="fa fa-instagram"
                  aria-hidden="true"></i></a>
              <a target="_blank" href="https://www.youtube.com/user/MixturaSanIsidro"
                style="color: #000000;font-size: 25px;margin: 2px 10px;"><i class="fa fa-youtube-play"
                  aria-hidden="true"></i></a>
              <a target="_blank" href="https://open.spotify.com/user/ikulzd6plipa0dznly1e9tp9d?si=EAlb3-RZQxO_9wy1Fqh8KQ"
                style="color: #000000;font-size: 25px;margin: 2px 10px;"><i class="fa fa-spotify"
                  aria-hidden="true"></i></a>
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <p class="hashtagMixtura">
              Grupo de jóvenes adultos de 26 a 35 años
            </p>
          </div>
        </div>
        <div class="col-sm-2 col-md-3"></div>
      </div>
    </div>
  </div>
</section>
<div class="main">
  <section class="module" id="about" style="background-image: url('assets/images/background/31.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-40 mt-40">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-0 col-md-2"></div>
            <div class="col-sm-4 col-md-3">
              <p class="title1">
                HEY!<br>
                HEY!<br>
                <i class="fa fa-arrow-down"></i>
                <br>
              </p>
            </div>
            <div class="col-sm-8 col-md-5 border1">
              <p class="title2">
                Se parte de nuestras reuniones en vivo todos los segundos y cuartos
                sábados de cada mes, a las 8 p.m. (PER)
                por nuestras redes sociales.
              </p>
            </div>
            <div class="col-sm-0 col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module" id="about" style="background-image: url('assets/images/background/29.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-40 mt-40">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-1 col-md-3"></div>
            <div class="col-sm-10 col-md-6">
              <p class="title1">
                VIERNES DE AMIGA
                <br>
              </p>
              <div class="col-sm-12 col-md-7">
                <p class="mobile-align" style="font-family: agrandirregular; font-size: 18px; color: #000000;">
                  Este espacio es únicamente para chicas, todos los tercer viernes del mes
                  a las 8 p.m. (PER) vía Zoom.
                </p>
                <p style="border-right: #000000 4px solid; border-left: #000000 4px solid;
                text-align: center; color: #000000; font-size: 20px; font-family: agrandirbold;">
                  #EnCasaEsMejor
                </p>
              </div>
              <div class="col-sm-0 col-md-1"></div>
              <div class="col-sm-12 col-md-4">
                <p class="mobile-align">
                  <img src="assets/images/background/mixturaLogo1.png" style="padding: 40px 20px 0px 0px;" />
                </p>
              </div>
            </div>
            <div class="col-sm-1 col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module" id="about" style="background-image: url('assets/images/background/32.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-40 mt-40">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-sm-2 col-md-3"></div>
            <div class="col-sm-8 col-md-6">
              <div class="col-sm-12 col-md-12" class="leadMixtua" style="background-image: url('assets/images/background/ventanamx.png'); background-repeat: no-repeat; background-size: 100% 100%;">
                <p style="text-align: right; color: #000000; font-size: 20px; font-family: agrandirbold;">
                  #EnCasaEsMejor
                </p>
                <p class="mobile-align" style="font-family: agrandirregular; font-size: 18px; color: #000000;">
                  <span class="title1">LEAD</span><br>
                  Si estás interesado en capacitaciones profesionales y sobre temas de emprendimiento,
                  no te puedes perder de este espacio donde crecemos juntos. Siguenos todos los
                  primeros y terceros viernes del mes, a las 8:30 p.m. (PER) por nuestro
                  instagram.
                </p>
              </div>
            </div>
            <div class="col-sm-2 col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="module" id="about" style="background-image: url('assets/images/background/28.jpg'); padding: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-40 mt-40">
          <div class="row" style="width: 100%;margin: 0px;">
            <div class="col-md-12">
              <p style="text-align: center; color: #000000; font-size: 20px; font-family: agrandirbold;">
                Síguenos en nuestras redes
              </p>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-sm-2 col-md-3"></div>
                <div class="col-sm-8 col-md-6">
                  <div class="row">
                    <div class="col-sm-4 col-md-4 iconRedes">
                      <div class="font-alt mb-10 titan-title-size-2 iconMixtura" style="text-align: center;">
                        <a target="_blank" href="https://www.facebook.com/MixturaEmmanuel"
                          style="color: #000000;font-size: 25px;margin: 2px 5px;"><i class="fa fa-facebook-official"
                            aria-hidden="true"></i></a>
                        <a target="_blank" href="https://www.instagram.com/mixtura_emmanuel/"
                          style="color: #000000;font-size: 25px;margin: 2px 5px;"><i class="fa fa-instagram"
                            aria-hidden="true"></i></a>
                        <a target="_blank" href="https://www.youtube.com/user/MixturaSanIsidro"
                          style="color: #000000;font-size: 25px;margin: 2px 5px;"><i class="fa fa-youtube-play"
                            aria-hidden="true"></i></a>
                        <a target="_blank" href="https://open.spotify.com/user/ikulzd6plipa0dznly1e9tp9d?si=EAlb3-RZQxO_9wy1Fqh8KQ"
                          style="color: #000000;font-size: 25px;margin: 2px 5px;"><i class="fa fa-spotify"
                            aria-hidden="true"></i></a>
                      </div>
                    </div>
                    <div class="col-sm-4 col-md-4 lineaLados">
                      <p class="imgFooter"><img src="assets/images/background/logoMixtura.png" /></p>
                    </div>
                    <div class="col-sm-4 col-md-3" style="text-align: center;
                    color: #000000; font-size: 25px;
                    font-family: agrandirbold;
                    margin-top: 3px;
                    ">
                      <p style="text-align: center;"><b>2020</b></p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-2 col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
