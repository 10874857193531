import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs';
import { LoginModel } from '../models/conferencia/login.model';

@Injectable({
  providedIn: 'root'
})
export class ConferenciaService {

  constructor(private http:HttpClient) { }
  validarTokenConferencia(request: any){
    const headers = new HttpHeaders();
		headers.append('Accept', 'application/json');
		headers.append('Content-Type', 'application/json');
    return this.http.post<any>(environment.end_point_backend + "/conferencia/validarToken", request, { headers }).pipe(
      map(datos=>{
        return datos;
      })
    );
  }

  login(request: any) : Observable<LoginModel>{
    const headers = new HttpHeaders();
		headers.append('Accept', 'application/json');
		headers.append('Content-Type', 'application/json');
    return this.http.post<any>(environment.end_point_backend + "/conferencia/login", request, { headers }).pipe(
      map(datos=>{
        let responseModel :LoginModel = datos;
        return responseModel;
      })
    );
  }

}
