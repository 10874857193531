import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reunion-jovenes',
  templateUrl: './reunion-jovenes.component.html',
  styleUrls: ['./reunion-jovenes.component.css']
})
export class ReunionJovenesComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
