import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HorarioReunionPresencial } from 'src/app/models/ticketReunion/horario-reunion-presencial.model';
import { ReunionPresencialModel } from 'src/app/models/ticketReunion/reunion-presencial.model';
import { ReunionVirtualService } from 'src/app/services/reunion-virtual.service';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-ticket-virtual',
  templateUrl: './ticket-virtual.component.html',
  styleUrls: ['./ticket-virtual.component.css']
})
export class TicketVirtualComponent implements OnInit {
  reunionModel: ReunionPresencialModel[] = [];
  horarioReunionModel: HorarioReunionPresencial[] = [];
  @ViewChild('idReunion') idReunion: ElementRef;
  @ViewChild('alertDatosObligatotios') alertDatosObligatotios: ElementRef;
  @ViewChild('alertTerminos') alertTerminos: ElementRef;
  @ViewChild('idHorarioReunion') idHorarioReunion: ElementRef;
  @ViewChild('nombreApellido') nombreApellido: ElementRef;
  @ViewChild('correo') correo: ElementRef;
  @ViewChild('terminos') terminos: ElementRef;
  @ViewChild('btnModalPre') btnModalPre: ElementRef;
  @ViewChild('alertExito') alertExito: ElementRef;
  @ViewChild('alertError') alertError: ElementRef;
  @ViewChild('formulario') formulario: ElementRef;

  constructor(
    private reunionVirtualService: ReunionVirtualService,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.reunionVirtualService.obtenerReuniones().subscribe(datos => {
      this.reunionModel = datos;
      this.btnModalPre.nativeElement.click();
      console.log(this.btnModalPre);
    });
  }

  onChangeTipoReunion(value: string) {
    const jsonString = '{"id":"' + value + '"}';
    const request = JSON.parse(jsonString);
    this.reunionVirtualService.obtenerHorarioReunion(request).subscribe(datos => {
      this.horarioReunionModel = datos;
      console.log(datos);
    });
  }

  onRegistroAsistencia() {
    this.spinner.show();
    var idReunion = this.idReunion.nativeElement.value;
    var idHorarioReunion = this.idHorarioReunion.nativeElement.value;
    var nombreApellido = this.nombreApellido.nativeElement.value;
    var correo = this.correo.nativeElement.value;
    var terminos = this.terminos.nativeElement.checked;
    this.alertDatosObligatotios.nativeElement.style.display = "none";
    this.alertTerminos.nativeElement.style.display = "none";
    this.alertExito.nativeElement.style.display = "none";
    this.alertError.nativeElement.style.display = "none";


    if (idReunion == "-1" || idHorarioReunion == "-1" || nombreApellido == "" || correo == "") {
      this.spinner.hide();
      this.alertDatosObligatotios.nativeElement.style.display = "block";
      return;
    }
    if (!terminos) {
      this.spinner.hide();
      this.alertTerminos.nativeElement.style.display = "block";
      return;
    }

    var datosReunion = this.reunionModel.find(x => x.idReunion === parseInt(idReunion));
    var datosHorario = this.horarioReunionModel.find(x => x.idHorarioReunion === parseInt(idHorarioReunion));
    const jsonString = '{"idReunion":"' + idReunion + '","idHorarioReunion":"' + idHorarioReunion + '","nombreApellido":"' + nombreApellido + '","correo":"' + correo + '","local":"' + datosHorario.local + '","fecha":"' + this.datepipe.transform(datosHorario.fecha, 'dd/MM/yyyy') + '","hora":"' + this.datepipe.transform(datosHorario.fecha, 'h:m aa') + '","reunion":"' + datosReunion.nombreReunion + '"}';
    const request = JSON.parse(jsonString);
    console.log(request);
    this.reunionVirtualService.registrarAsistencia(request).subscribe(datos => {
      this.spinner.hide();
      this.formulario.nativeElement.style.display = "none";
      if (datos.resultado) {
        this.alertExito.nativeElement.style.display = "block";
      } else {
        this.alertError.nativeElement.style.display = "block";
      }
    });
  }

}
