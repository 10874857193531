import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mixtura',
  templateUrl: './mixtura.component.html',
  styleUrls: ['./mixtura.component.css']
})
export class MixturaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
