<div style="position: absolute; z-index: 999;width: 100%; padding-top: 20vh;">
  <div class="col-md-12">
    <div class="row">
      <div class="col-sm-2 col-md-4"></div>
      <div class="col-sm-8 col-md-4" style="background-color: rgb(0, 0, 0, 0.5); padding-top: 5px;">
        <p style="text-align: center; color: #ffffff; font-family: Montserrat-Medium; font-size: 24px;">Ticket para
          reunión presencial</p>
        <br>
        <div class="row">
          <div class="col-md-12" #alertExito style="display: none;">
            <div class="row">
              <div class="col-xs-4 col-sm-4 col-md-4"></div>
              <div class="col-xs-4 col-sm-4 col-md-4">
                <p style="text-align: center;"><img src="assets/images/check.png" width="100%" /> </p>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4"></div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p style="color: #ffffff; font-size: 18px; text-align: center;">Registro exitoso</p>
                <p style="color: #ffffff; font-size: 14px;">
                  Le enviamos un correo con el código QR de acceso, muestrelo al momento de ingresar a la reunión
                  <br>Revise su bandeja de entrada y correos no deseados. Si el correo no llegó, comunícate al siguiente número +951 987 654 321
                </p>
                <p style="text-align: center;"><a routerLink="/home" class="section-scroll btn btn-border-w btn-round"
                style="font-family: Agrandir-Variable;font-size: 15px;text-align: center;color: #fff; background-color: #000; cursor: pointer;">IR AL INICIO</a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-12" #alertError style="display: none;">
            <div class="row">
              <div class="col-xs-4 col-sm-4 col-md-4"></div>
              <div class="col-xs-4 col-sm-4 col-md-4">
                <p style="text-align: center;"><img src="assets/images/error.png" width="100%" /> </p>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4"></div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p style="color: #ffffff; font-size: 18px; text-align: center;">Error</p>
                <p style="color: #ffffff; font-size: 14px; text-align: justify;">Ocurrió un error al momento de realizar la transacción. Vuelve a intentarlo en un momento.<br>Si el problema persiste comunícate al siguiente número +951 987 654 321</p>
                <p style="text-align: center;"><a routerLink="/home" class="section-scroll btn btn-border-w btn-round"
                style="font-family: Agrandir-Variable;font-size: 15px;text-align: center;color: #fff; background-color: #000; cursor: pointer;">IR AL INICIO</a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-12" #formulario>
            <div class="row">
              <div class="form-group col-md-12 mb-20">
                <input #nombreApellido type="text" placeholder="Nombre y apellido" class="form-control formCM"
                  id="nombreApellido" name="nombreApellido">
              </div>
              <div class="form-group col-md-12 mb-20">
                <input #correo type="text" placeholder="Correo" class="form-control formCM" id="correo" name="correo">
              </div>
              <div class="form-group col-md-12 mb-30">
                <select #idReunion class="form-control" name="tipdocident" required=""
                  (change)='onChangeTipoReunion(idReunion.value)'>
                  <option value="-1" disabled selected>Tipo Reunion</option>
                  <option [value]="item.idReunion" *ngFor="let item of reunionModel">{{item.nombreReunion}}</option>
                </select>
              </div>
              <div class="form-group col-md-12">
                <select #idHorarioReunion class="form-control" name="tipdocident" required="">
                  <option value="-1" disabled selected>Horario:</option>
                  <option [value]="item.idHorarioReunion" *ngFor="let item of horarioReunionModel">
                    {{item.nombreHorario}}</option>
                </select>
              </div>
              <div class="form-group col-md-12">
                <input #terminos class="form-check-input checkPP" type="checkbox" style="transform: scale(1.5);">
                <label class="form-check-label gridCheck" for="m"
                  style="padding-top: 5%; padding-left: 5%; font-size: 15px; color: #ffffff;">
                  Aceptar los <a data-toggle="modal" data-target="#exampleModal" style="cursor: pointer; color: #a7a7a7;">términos y condiciones</a>
                </label>
              </div>
              <div class="form-group col-md-12">
                <p #alertDatosObligatotios style="color: red; font-size: 15px;display: none;">Todos los datos son
                  obligatorios</p>
                  <p #alertTerminos style="color: red; font-size: 15px;display: none;">Acepte los términos y condiciones</p>
              </div>
              <div class="form-group col-md-12 mb-30" style="text-align: center;">
                <a (click)="onRegistroAsistencia()" class="section-scroll btn btn-border-w btn-round"
                  style="font-family: Agrandir-Variable;font-size: 15px;text-align: center;color: #fff; background-color: #000; cursor: pointer;">GENERAR</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-2 col-md-4"></div>
    </div>
  </div>
</div>
<div id="vimeohero">
  <iframe src="https://player.vimeo.com/video/71070693?background=1" frameborder="0" webkitallowfullscreen
    mozallowfullscreen allowfullscreen></iframe>
</div>
<ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="#fff" type="ball-running-dots">
  <p style="font-size: 20px; color: #ffffff">Registrando...</p>
</ngx-spinner>
<button #btnModalPre class="section-scroll btn btn-border-w btn-round" data-toggle="modal" data-target="#exampleModal" style="display: none;">Abrir modal 1</button>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="background-color: #000000;
                background-repeat: no-repeat;
                background-size: cover;">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel" style="color: #ffffff;text-align: center;">
         ¡Recuerda!
        </h4>
        <button style="color: #ffffff;" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="content-modal">
          <div class="row">
            <div class="col-md-12">
              <p style="font-size: 17px; color: #ffffff;"><b>Requisitos para realizar el registro</b></p>
              <ul style="color: #ffffff; font-size: 14px;">
                <li>No presentar síntomas de COVID19 en los últimos tres días.</li>
                <li>No tener familiares cercanos con síntomas de COVID19 en los últimos tres días.</li>
              </ul>
              <p style="font-size: 17px; color: #ffffff;"><b>Durante la reunión</b></p>
              <ul style="color: #ffffff; font-size: 14px;">
                <li>Toda persona asistente deberá portar en todo momento doble mascarilla.</li>
                <li>Se medirá la temperatura corporal al ingreso de la reunión. No ingresarán las personas con temperatura mayor a 38°C.</li>
                <li>Mantener el distanciamiento social de mínimo 1 metro durante toda la reunión.</li>
                <li>No quitarse la mascarilla en ningún momento de la reunión.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          style="background-color: #000000;color: #ffffff; border: #ffffff 1px solid;">Cerrar</button>
      </div>
    </div>
  </div>
</div>
