<section class="home-fade bg-dark-30 parallax-ibe" id="home"
  style="background-image: url('assets/images/background/37.jpg'); height: 60vh; background-size: cover;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="col-md-12">
        <p class="title1Dar">
          Formas de Dar
        </p>
        <p class="subtitle1Dar">
          ¡Gracias por tu generosidad!
        </p>
      </div>
    </div>
  </div>
</section>
<section class="module" id="chats" style="background-color: #ffffff;padding: 70px 0px;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="col-sm-3" style="margin-bottom: 10%;">
          <div class="row">
            <div style="padding: 0px 20px;">
              <a class="btn btn-border-w btn-round" id="btnPeru" (click)="onPeru()" style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 10px 0px;border-color: #000000;
          color: #000000; width: 100%;white-space: normal;">Si vives en Perú</a>
              <a class="btn btn-border-w btn-round" id="btnChile" (click)="onChile()" style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 10px 0px;border-color: #000000;
          color: #000000; width: 100%;white-space: normal;">Si vives en Chile</a>
              <a class="btn btn-border-w btn-round" id="btnExtranjero" (click)="onExtranjero()" style="font-family: Montserrat-Bold;font-size: 13px;text-align: center; margin: 10px 0px;border-color: #000000;
          color: #000000; width: 100%;white-space: normal;">Si vives en otro país</a>
            </div>
          </div>
        </div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-11" style="padding: 2% 4%;border: #000000 2px solid;" id="peru">
                  <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                    <b style="font-size: 16px;">Importante</b><br>
                  </p>
                  <ul>
                    <li>
                      <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                        Envía tu constancia de operación y tus datos personales
                        al correo: <b>diezmoyofrendas@emmanuel.pe</b>
                      </p>
                    </li>
                    <li>
                      <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                        Si donas para Movilización, colócalo en tu
                        constancia de operación y luego envíala al
                        correo: <b>movilizacion@emmanuel.pe</b>
                      </p>
                    </li>
                  </ul>
                  <p style="font-family: Montserrat-Bold;color: #000000; font-size: 16px;margin-bottom: 0px;">
                    <b><i class="fa fa-circle" style="font-size: 10px"></i> Depósito o transferencia bancaria.</b>
                  </p>
                  <p style="font-family: Montserrat-Medium;color: #000000; font-size: 14px;padding-left: 12px;">
                    Utiliza los medios digitales, agentes o la agencia bancaria del BBVA o BCP.<br>
                    BBVA<br>
                    Soles 0011 0351 01 00007699<br>
                    Dólares 0011 0351 01 00002034<br>
                    <br>
                    BCP<br>
                    Soles 193 2106221 0 59<br>
                    Dólares 191 2507792141<br>
                  </p>
                  <p style="font-family: Montserrat-Bold;color: #000000; font-size: 16px;margin-bottom: 0px;">
                    <b><i class="fa fa-circle" style="font-size: 10px"></i> Depósito con la aplicación "YAPE".</b>
                  </p>
                  <div class="row">
                    <div class="col-md-6">
                      <p style="font-family: Montserrat-Medium;color: #000000; font-size: 14px;padding-left: 12px;">
                        Escanea el código con tu dispositivo móvil desde la aplicación YAPE.<br>
                        Nombre: Asociación Iglesia Bíblica Emmanuel<br>
                        Recuerda envíar tu constancia y tus datos al correo: diezmoyofrendas@emmanuel.pe<br>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <p class="qrDar" >
                        <img src="assets/images/qrYape.png" width="25%" />
                      </p>
                    </div>
                  </div>
                  <p style="font-family: Montserrat-Bold;color: #000000; font-size: 16px;margin-bottom: 0px;">
                    <b><i class="fa fa-circle" style="font-size: 10px"></i> Transferencia interbancaria.</b>
                  </p>
                  <p style="font-family: Montserrat-Medium;color: #000000; font-size: 14px;padding-left: 12px;">
                    Si no tienes una cuenta BBVA o BCP.<br>
                    BBVA (CCI)<br>
                    Soles 011 351 000100007699 40<br>
                    Dólares 011 351 000100002034 45<br>
                    <br>
                    BCP (CCI)<br>
                    Soles 002 193 002106221059 12<br>
                    Dólares 002 191 002507792141 56<br>
                  </p>


                  <p style="font-family: Montserrat-Bold;color: #000000; font-size: 16px;margin-bottom: 0px;">
                    <b><i class="fa fa-circle" style="font-size: 10px"></i> Generar un código de pago</b>
                  </p>
                  <p style="font-family: Montserrat-Medium;color: #000000; font-size: 14px;padding-left: 12px;">
                    Haz click <a href="https://wa.link/ylfbdu" target="_blank"><b>aquí</b></a> y
                    te ayudaremos a generar un código para que puedas depositar tu diezmo y/o ofrenda
                    en cualquier agente BCP, BBVA, Interbank, Kasnet,
                    Western Union, Scotiabank, entre otros.<br>
                  </p>
                  <p style="font-family: Montserrat-Bold;color: #000000; font-size: 16px;margin-bottom: 0px;">
                    <b><i class="fa fa-circle" style="font-size: 10px"></i> Girar un cheque a nombre de la ASOCIACION IGLESIA BIBLICA
                      EMMANUEL y depositarlo en una agencia bancaria.</b>
                  </p>
                  <br><br><br>
                  <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                    <b style="font-size: 16px;">Importante</b><br>
                  </p>
                  <ul>
                    <li>
                      <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                        Envía tu constancia de operación y tus datos personales
                        al correo: <b>diezmoyofrendas@emmanuel.pe</b>
                      </p>
                    </li>
                    <li>
                      <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                        Si donas para Movilización, colócalo en tu
                        constancia de operación y luego envíala al
                        correo: <b>movilizacion@emmanuel.pe</b>
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="col-sm-11" style="padding: 2% 4%;border: #000000 2px solid;" id="chile">
                  <p style="font-family: Montserrat-Bold;color: #000000; font-size: 16px;margin-bottom: 0px;">
                    <b>Depósito o transferencia bancaria.</b>
                  </p>
                  <p style="font-family: Montserrat-Medium;color: #000000; font-size: 14px; padding-left: 12px;">
                    Titular: Iglesia Bíblica Emmanuel Chile<br>
                    Banco Estado<br>
                    Chequera Electrónica (Cuenta Vista)<br>
                    N° 360-7-232147-7<br>
                    Rut 65.086.448-4<br>
                  </p>
                  <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px;">
                    <b style="font-size: 16px;">Importante</b><br>
                    Envía tu constancia de operación y tus datos personales
                    al correo: <b>tesoreria@emmanuelchile.cl</b>
                  </p>
                </div>
                <div class="col-sm-11" style="padding: 2% 4%;border: #000000 2px solid;" id="extranjero">
                  <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                    <b style="font-size: 16px;">Importante</b><br>
                  </p>
                  <ul>
                    <li>
                      <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                        Envía tu constancia de operación y tus datos personales
                        al correo: <b>diezmoyofrendas@emmanuel.pe</b>
                      </p>
                    </li>
                    <li>
                      <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                        Si donas para Movilización, colócalo en tu
                        constancia de operación y luego envíala al
                        correo: <b>movilizacion@emmanuel.pe</b>
                      </p>
                    </li>
                  </ul>
                  <p style="font-family: Montserrat-Bold;color: #000000; font-size: 16px;margin-bottom: 0px;">
                    <b>1. Transferencia del extranjero</b>
                  </p>
                  <p style="font-family: Montserrat-Medium;color: #000000; font-size: 14px;padding-left: 12px;">
                    <b>Datos de la iglesia</b><br>
                    Nombre: <b>Asociación Iglesia Bíblica Emmanuel</b><br>
                    Dirección: <b>Av. Javier Prado Este N° 671 - San Isidro</b><br>
                    Teléfono: <b>+51-1-4216333</b><br>
                    Ciudad: <b>Lima </b><br>
                    País: <b>Perú  </b><br><br>
                    <b>Datos del Banco</b><br>
                    Nº de la cuenta: <b>0011-0351-0100002034-45, en dólares</b><br>
                    Código Swift: <b>BCONPEPL</b><br>
                    Nombre: <b>BBVA Banco Continental</b><br>
                    Dirección: <b>Av. República de Panamá Nº 3055</b><br>
                    Ciudad: <b>Lima</b><br>
                    País: <b>Perú</b><br>
                    Teléfono: <b>+51-1-2111000 Anexo 3174</b><br>
                  </p>
                  <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                    <b style="font-size: 16px;">Importante</b><br>
                  </p>
                  <ul>
                    <li>
                      <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                        Envía tu constancia de operación y tus datos personales
                        al correo: <b>diezmoyofrendas@emmanuel.pe</b>
                      </p>
                    </li>
                    <li>
                      <p style="font-family: Montserrat-MediumItalic;color: #000000; font-size: 14px; margin-bottom: 0px;">
                        Si donas para Movilización, colócalo en tu
                        constancia de operación y luego envíala al
                        correo: <b>movilizacion@emmanuel.pe</b>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
