import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-singular',
  templateUrl: './singular.component.html',
  styleUrls: ['./singular.component.css']
})
export class SingularComponent implements OnInit {
  constructor(
  ) { }
  ngOnInit(): void {
  }
}
