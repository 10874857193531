<nav class="navbar navbar-custom navbar-fixed-top navbar-transparent" role="navigation">
  <div class="container">
    <div class="navbar-header headerLogo">
      <button class="navbar-toggle" type="button" data-toggle="collapse" data-target="#custom-collapse"><span class="sr-only">Toggle navigation</span><span class="icon-bar"></span><span class="icon-bar"></span><span class="icon-bar"></span></button>
      <a class="navbar-brand imgLogo" routerLink="/home"><img src="assets/images/logo.png" style="width: 100%;" /> </a>
    </div>
    <div class="collapse navbar-collapse" id="custom-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li><a routerLink="singular">Singular</a></li>
        <!-- <li><a routerLink="conferencia">CONFERENCIA</a></li> -->
        <li appHighlightOnHover class="dropdown"><a class="dropdown-toggle" href="#" data-toggle="dropdown">NOSOTROS</a>
          <ul class="dropdown-menu">
            <li><a routerLink="/nosotros/en-esto-creemos">EN ESTO CREEMOS</a></li>
            <li><a routerLink="/nosotros/cultura">CULTURA</a></li>
            <li><a routerLink="/nosotros/primeros-pasos">PRIMEROS PASOS</a></li>
          </ul>
        </li>
        <li appHighlightOnHover class="dropdown"><a class="dropdown-toggle" href="#" data-toggle="dropdown">REUNIONES</a>
          <ul class="dropdown-menu">
            <li><a routerLink="reuniones">TODOS</a></li>
            <li><a routerLink="zona-kids">NIÑOS</a></li>
            <li><a routerLink="people">ADOLESCENTES</a></li>
            <li><a routerLink="motion">JÓVENES</a></li>
            <li><a routerLink="mixtura">JÓVENES ADULTOS</a></li>
            <li><a routerLink="gente-sabia">ADULTOS MAYORES</a></li>
            <li><a href="https://cursos.emmanuel.pe/4hominsc" target="_blank">HOMBRES</a></li>
            <li><a href="https://cursos.emmanuel.pe/5sm-inscsm" target="_blank">MUJERES</a></li>
          </ul>
        </li>
        <li appHighlightOnHover class="dropdown"><a class="dropdown-toggle" href="#" data-toggle="dropdown">INVOLÚCRATE</a>
          <ul class="dropdown-menu">
            <li><a routerLink="movilizacion">MOVILIZACIÓN</a></li>
            <li><a routerLink="extremo-colegios">EXTREMO COLEGIOS</a></li>
          </ul>
        </li>
        <li appHighlightOnHover class="dropdown"><a class="dropdown-toggle" href="#" data-toggle="dropdown">CRECE</a>
          <ul class="dropdown-menu">
            <li><a href="https://cursos.emmanuel.pe/" target="_blank">CURSOS</a></li>
            <li><a routerLink="crece/grupos">GRUPOS</a></li>
            <li><a href="https://emmanuel.pe/lab/" target="_blank">LAB</a></li>
            <li><a href="https://sem.emmanuel.pe" target="_blank" style="text-transform: initial;">SEM (Seminario)</a></li>
          </ul>
        </li>
        <li><a routerLink="dar">DAR</a></li>
        <!-- <li><a routerLink="clase-magistral">CLASE MAGISTRAL</a></li> -->
        <li><a href="https://www.youtube.com/channel/UCGimexax_xNysi9QkR7Pzmw" target="_blank">EN VIVO</a></li>
        <li>
          <a target="_blank" href="https://www.instagram.com/emmanuel.peru/" style="padding-left: 10px; padding-right: 10px;float: left;"><i class="fa fa-instagram" style="font-size: 16px;"></i></a>
          <a target="_blank" href="https://www.facebook.com/iemmanuelperu" style="padding-left: 10px; padding-right: 10px;float: left;"><i class="fa fa-facebook-official" style="font-size: 16px;"></i></a>
          <a target="_blank" href="https://www.youtube.com/IglesiaEmmanuelPeru" style="padding-left: 10px; padding-right: 10px;float: left;"><i class="fa fa-youtube-play" style="font-size: 16px;"></i></a>
        </li>
      </ul>
    </div>
  </div>
</nav>
