export class DedicacionesModel {
  fatherName: string;
  fatherEmail: string;
  fatherPhone: string;
  childrenName1: string;
  childrenAge1: string;
  childrenName2: string;
  childrenAge2: string;
  childrenName3: string;
  childrenAge3: string;
}
