<section class="home-section home-fade home-full-height bg-dark-30 parallax-ibe" id="home"
  style="background-image: url('assets/images/background/fondo01.jpg'); height: 100vh;">
  <div class="titan-caption">
    <div class="caption-content">
      <div class="font-alt titan-title-size-4 portTitle1">
        Bienvenidos
      </div>

      <div class="font-alt titan-title-size-4 portTitle2">
        Emmanuel, una sola iglesia, un solo corazón
      </div>


      <div class="col-sm-1 col-md-1 col-lg-2"></div>
      <div class="col-sm-10 col-md-10 col-lg-8">

        <div class="font-alt mb-10 titan-title-size-2" style="margin-top: 15px;">
          <a target="_blank" href="https://www.instagram.com/emmanuel.peru/"
            style="color: #ffffff;font-size: 25px;margin: 2px 10px;"><i class="fa fa-instagram"
              aria-hidden="true"></i></a>
          <a target="_blank" href="https://www.facebook.com/iemmanuelperu"
            style="color: #ffffff;font-size: 25px;margin: 2px 10px;"><i class="fa fa-facebook-official"
              aria-hidden="true"></i></a>
          <a target="_blank" href="https://www.youtube.com/IglesiaEmmanuelPeru"
            style="color: #ffffff;font-size: 25px;margin: 2px 10px;"><i class="fa fa-youtube-play"
              aria-hidden="true"></i></a>
        </div>
        <br>
        <a class="section-scroll btn btn-border-w btn-round" routerLink="/reuniones"
          style="font-family: Montserrat-Bold;font-size: 13px;">Conoce nuestras reuniones</a>

      </div>
      <div class="col-sm-1 col-md-1 col-lg-2"></div>

    </div>
  </div>
</section>
