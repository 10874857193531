import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EventoModel } from 'src/app/models/sige/evento/evento.model';
import { PersonaModel } from 'src/app/models/sige/persona/persona.model';
import { SigeService } from 'src/app/services/sige.service';

@Component({
  selector: 'app-formulario-reunion-presencial',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioReunionPresencialComponent implements OnInit {
  currentTab: number = 0;
  lenthFecha: number = 0;
  eventos: EventoModel[] = [];
  @Input() idTipoEvento = 0;
  @ViewChild('btnModalPre') btnModalPre: ElementRef;

  constructor(
    private spinner: NgxSpinnerService,
    private sigeService: SigeService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.showTab(this.currentTab);
    this.onObtenerEventos();
    (document.getElementById('numdoc') as HTMLInputElement).addEventListener("keypress", this.soloNumeros, false);
    (document.getElementById('fecha_nacimiento') as HTMLInputElement).addEventListener("keypress", this.soloNumerosFechaNac, false);
    // setInterval(() => {
    //   this.onEventoRealTime()
    // }, 10000);
  }

  onEscogerEvento() {
    this.spinner.show();
    var evento = (document.getElementById('evento') as HTMLInputElement).value;
    const jsonString = '{"idEvento": ' + evento + '}';
    const request = JSON.parse(jsonString);
    this.sigeService.obtenerDisponibilidadEvento(request).subscribe(datos => {
      this.spinner.hide();
      if (datos.disponibilidad <= 0) {
        (document.getElementById('evento') as HTMLInputElement).value = "-1";
        this.toastr.error('El evento ya se encuentra con aforo maximo, seleccione otro porfavor.', '¡Atención!');
      }
    });
  }

  onEventoRealTime() {
    var evento = (document.getElementById('evento') as HTMLInputElement).value;
    if (evento != "-1") {
      const jsonString = '{"idEvento": ' + evento + '}';
      const request = JSON.parse(jsonString);
      this.sigeService.obtenerDisponibilidadEvento(request).subscribe(datos => {
        if (datos.disponibilidad <= 0) {
          (document.getElementById('evento') as HTMLInputElement).value = "-1";
          this.toastr.error('El evento ya se encuentra con aforo maximo, seleccione otro porfavor.', '¡Atención!');
        } else {
          this.toastr.success('Aforo disponible: ' + datos.disponibilidad, '¡Atención');
        }
      });
    }
  }

  onObtenerEventos() {
    this.spinner.show();
    var selectEvento = document.getElementById('evento');
    const jsonString = '{"id": ' + this.idTipoEvento + '}';
    const request = JSON.parse(jsonString);
    this.sigeService.obtenerEventosxTipo(request).subscribe(datos => {
      this.eventos = datos;
      datos.forEach((valor, key) => {
        var opt = document.createElement('option');
        opt.value = valor.id.toString();
        opt.innerHTML = valor.descripcion;
        selectEvento.appendChild(opt);
      });
      this.spinner.hide();
    });
  }

  onChangeNacionalidad() {
    document.getElementById("notaNumDoc").style.display = "none";
    const nacionalidad = (document.getElementById('nacionalidad') as HTMLInputElement);
    const numdoc = (document.getElementById('numdoc') as HTMLInputElement);
    var options = '';
    switch (nacionalidad.value) {
      case '47'://Chile
        options = '<option value="RUT">RUT</option>';
        numdoc.setAttribute("pattern", "^\d\d\d\d\d\d\d\d-\d$");
        numdoc.setAttribute("maxlength", "10");
        (document.getElementById('notaNumDoc') as HTMLInputElement).innerText = '10 dígitos exactos, incluyendo el "-"';
        document.getElementById("notaNumDoc").style.display = "inline";
        break;
      case '175'://Peru
        options = '<option value="DNI">DNI</option>';
        numdoc.setAttribute("pattern", "[0-9]{8}");
        numdoc.setAttribute("maxlength", "8");
        (document.getElementById('notaNumDoc') as HTMLInputElement).innerText = 'Sólo números, 8 dígitos exactos';
        document.getElementById("notaNumDoc").style.display = "inline";
        break;
      default:
        options = '<option disabled selected value>Selecciona una opción ...</option><option value="CE">Carné de Extranjeria (CE)</option><option value="PAS">Pasaporte</option>';
        numdoc.setAttribute("pattern", "");
        numdoc.setAttribute("maxlength", "30");
        break;
    }
    (document.getElementById('tipdocident') as HTMLInputElement).innerHTML = options;
  }

  formatoFecha() {
    var fecha_nacimiento = (document.getElementById('fecha_nacimiento') as HTMLInputElement).value;
    if (fecha_nacimiento.length <= this.lenthFecha) {
      (document.getElementById('fecha_nacimiento') as HTMLInputElement).value = '';
      this.lenthFecha = 0;
      return;
    }

    if (fecha_nacimiento.length == 4) {
      (document.getElementById('fecha_nacimiento') as HTMLInputElement).value = fecha_nacimiento + "-";

    }
    if (fecha_nacimiento.length == 7) {
      var mes = Number(fecha_nacimiento.split("-")[1]);
      if (mes >= 1 && mes <= 12)
        (document.getElementById('fecha_nacimiento') as HTMLInputElement).value = fecha_nacimiento + "-";
      else
        (document.getElementById('fecha_nacimiento') as HTMLInputElement).value = fecha_nacimiento.substring(0, fecha_nacimiento.length - 2);
    }
    if (fecha_nacimiento.length == 10) {
      var dia = Number(fecha_nacimiento.split("-")[2]);
      if (dia > 31)
        (document.getElementById('fecha_nacimiento') as HTMLInputElement).value = fecha_nacimiento.substring(0, fecha_nacimiento.length - 2);
      else {
        this.validadEdadMaxima(fecha_nacimiento);
      }
    }
    this.lenthFecha = (document.getElementById('fecha_nacimiento') as HTMLInputElement).value.length;
  }

  validadEdadMaxima(fecha_nacimiento: string) {
    var fechaNac = fecha_nacimiento.split("-");
    var edad = this.calculate_age(Number(fechaNac[1]), Number(fechaNac[2]), Number(fechaNac[0]));
    if (edad < 12) {
      (document.getElementById('apellido_paterno') as HTMLInputElement).value = "";
      (document.getElementById('apellido_materno') as HTMLInputElement).value = "";
      (document.getElementById('nombre1') as HTMLInputElement).value = "";
      (document.getElementById('nombre2') as HTMLInputElement).value = "";
      (document.getElementById('email') as HTMLInputElement).value = "";
      (document.getElementById('numdoc') as HTMLInputElement).value = "";
      (document.getElementById('persona') as HTMLInputElement).value = "0";
      (document.getElementById('fecha_nacimiento') as HTMLInputElement).value = "";
      this.toastr.error('La edad minima permitida es de 12 años', '¡Atención!');
    }

    return edad;
  }

  soloNumeros(e) {
    var key = window.event ? e.which : e.keyCode;
    const nacionalidad = (document.getElementById('nacionalidad') as HTMLInputElement);
    switch (nacionalidad.value) {
      case '47'://Chile
        break;
      case '175'://Peru
        if (key < 48 || key > 57) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
  }

  soloNumerosFechaNac(e) {
    var key = window.event ? e.which : e.keyCode;
    if (key < 48 || key > 57) {
      e.preventDefault();
    }
  }

  onConsultaPersona() {
    var nacionalidad = (document.getElementById('nacionalidad') as HTMLInputElement).value;
    var tipdocident = (document.getElementById('tipdocident') as HTMLInputElement).value;
    var numdoc = (document.getElementById('numdoc') as HTMLInputElement).value;
    var evento = (document.getElementById('evento') as HTMLInputElement).value;
    if (nacionalidad != "" && tipdocident != "" && numdoc != "") {
      this.spinner.show();
      var jsonString = '{"nacionalidad":' + nacionalidad + ', "tipdocident":"' + tipdocident + '", "numdoc":"' + numdoc + '"}';
      var request = JSON.parse(jsonString);
      this.sigeService.obtenerPersona(request).subscribe(datos => {
        this.spinner.hide();
        if (datos != null) {
          if (datos.apellido_paterno.toString() !== "false")
            (document.getElementById('apellido_paterno') as HTMLInputElement).value = datos.apellido_paterno;
          if (datos.apellido_materno.toString() !== "false")
            (document.getElementById('apellido_materno') as HTMLInputElement).value = datos.apellido_materno;
          if (datos.nombre1.toString() !== "false")
            (document.getElementById('nombre1') as HTMLInputElement).value = datos.nombre1;
          if (datos.nombre2.toString() !== "false")
            (document.getElementById('nombre2') as HTMLInputElement).value = datos.nombre2;
          if (datos.fecha_nacimiento.toString() !== "false")
            (document.getElementById('fecha_nacimiento') as HTMLInputElement).value = datos.fecha_nacimiento;
          // if (datos.email.indexOf("@") !== -1)
          //   (document.getElementById('email') as HTMLInputElement).value = datos.email;

          (document.getElementById('persona') as HTMLInputElement).value = datos.id.toString();
          var eventoSeleccionado = this.eventos.find(x => x.id == Number(evento));
          if (eventoSeleccionado != null) {
            var participanteEvento = eventoSeleccionado.participantes.find(x => x == datos.id);
            if (participanteEvento != null) {
              (document.getElementById('apellido_paterno') as HTMLInputElement).value = "";
              (document.getElementById('apellido_materno') as HTMLInputElement).value = "";
              (document.getElementById('nombre1') as HTMLInputElement).value = "";
              (document.getElementById('nombre2') as HTMLInputElement).value = "";
              (document.getElementById('email') as HTMLInputElement).value = "";
              (document.getElementById('numdoc') as HTMLInputElement).value = "";
              (document.getElementById('persona') as HTMLInputElement).value = "0";
              (document.getElementById('fecha_nacimiento') as HTMLInputElement).value = "";
              this.toastr.error('Ya te encuentras registrado a este evento', '¡Atención!');
            }else{
              this.nextPrev(1);
            }
          } else {
            this.nextPrev(1);
          }
        } else {
          (document.getElementById('apellido_paterno') as HTMLInputElement).value = "";
          (document.getElementById('apellido_materno') as HTMLInputElement).value = "";
          (document.getElementById('nombre1') as HTMLInputElement).value = "";
          (document.getElementById('nombre2') as HTMLInputElement).value = "";
          (document.getElementById('email') as HTMLInputElement).value = "";
          (document.getElementById('fecha_nacimiento') as HTMLInputElement).value = "";
          (document.getElementById('persona') as HTMLInputElement).value = "0";
          this.nextPrev(1);
        }
      });
    }else{
      this.nextPrev(1);
    }
  }

  onRegistrar() {
    this.spinner.show();
    var nacionalidad = (document.getElementById('nacionalidad') as HTMLInputElement).value;
    var tipdocident = (document.getElementById('tipdocident') as HTMLInputElement).value;
    var numdoc = (document.getElementById('numdoc') as HTMLInputElement).value;
    var evento = (document.getElementById('evento') as HTMLInputElement).value;
    var inputEvento = (document.getElementById('evento') as HTMLSelectElement);
    var nombre_evento = inputEvento[inputEvento.selectedIndex].innerText;
    var apellido_paterno = (document.getElementById('apellido_paterno') as HTMLInputElement).value;
    var apellido_materno = (document.getElementById('apellido_materno') as HTMLInputElement).value;
    var nombre1 = (document.getElementById('nombre1') as HTMLInputElement).value;
    var nombre2 = (document.getElementById('nombre2') as HTMLInputElement).value;
    var email = (document.getElementById('email') as HTMLInputElement).value;
    var persona = (document.getElementById('persona') as HTMLInputElement).value;
    var fecha_nacimiento = (document.getElementById('fecha_nacimiento') as HTMLInputElement).value;
    if (nacionalidad == "" || tipdocident == "" || numdoc == "" || evento == "" || evento == "-1" || apellido_paterno == "" || apellido_materno == "" || nombre1 == "" || email == "" || fecha_nacimiento == "") {
      this.spinner.hide();
      this.toastr.error('Complete los datos obligatorios (*)', '¡Atención!');
      this.validateForm();
      return;
    }
    if (fecha_nacimiento.length != 10) {
      this.spinner.hide();
      this.toastr.error('El formato de fecha no es valido', '¡Atención!');
      return;
    }

    var edad = this.validadEdadMaxima(fecha_nacimiento);
    if (edad < 12) {
      return;
    }

    var personaModel = new PersonaModel;

    // var persona_name = nombre1 + ' ' + apellido_paterno + ' ' + apellido_materno;
    if (Number(persona) == 0) {
      personaModel.nacionalidad = Number(nacionalidad);
      personaModel.tipdocident = tipdocident.toUpperCase();
      personaModel.numdoc = numdoc.toUpperCase();
      personaModel.apellido_paterno = apellido_paterno.toUpperCase();
      personaModel.apellido_materno = apellido_materno.toUpperCase();
      personaModel.nombre1 = nombre1.toUpperCase();
      personaModel.country_id = 175;
      personaModel.state_id = 2124;
      personaModel.substate_id = 2262;
      personaModel.district_id = 3576;
      personaModel.email = email.toUpperCase();
      personaModel.tipoanexo = "CLI";
      personaModel.is_company = false;
      personaModel.codigo_persona = numdoc.toUpperCase() + "C";
      personaModel.asiste_iglesia = true;
      personaModel.fecha_nacimiento = fecha_nacimiento;
      personaModel.name = apellido_paterno.toUpperCase() + " " + apellido_materno.toUpperCase() + " " + nombre1.toUpperCase();
    } else {
      personaModel.apellido_paterno = apellido_paterno.toUpperCase();
      personaModel.apellido_materno = apellido_materno.toUpperCase();
      personaModel.nombre1 = nombre1.toUpperCase();
      personaModel.email = email.toUpperCase();
      personaModel.asiste_iglesia = true;
      personaModel.fecha_nacimiento = fecha_nacimiento;
      personaModel.name = apellido_paterno.toUpperCase() + " " + apellido_materno.toUpperCase() + " " + nombre1.toUpperCase();
    }

    personaModel.active = true;
    if (nombre2 !== "")
      personaModel.name = personaModel.name + " " + nombre2.toUpperCase();

    let request: { persona: PersonaModel, idEvento: number, idPersona: number, nombre_evento: string } = { "persona": personaModel, "idEvento": Number(evento), "idPersona": Number(persona), "nombre_evento": nombre_evento };
    this.sigeService.registrarEvento(request).subscribe(datos => {
      this.spinner.hide();
      if (datos.codigo === 1) {
        this.toastr.success('Se registro su entrada correctamente', 'Exito');
        (document.getElementById('imagenQR') as HTMLInputElement).src = "data:image/png;base64," + datos.dataEntrada.qr_image;
        (document.getElementById('tituloNombre') as HTMLInputElement).innerText = datos.nombre_evento;
        (document.getElementById('entrada_html') as HTMLInputElement).innerHTML = datos.dataEntrada.entrada_html;
        (document.getElementById('nombreModal') as HTMLInputElement).innerText = datos.nombre_persona;
        (document.getElementById('apellido_paterno') as HTMLInputElement).value = "";
        (document.getElementById('apellido_materno') as HTMLInputElement).value = "";
        (document.getElementById('nombre1') as HTMLInputElement).value = "";
        (document.getElementById('nombre2') as HTMLInputElement).value = "";
        (document.getElementById('email') as HTMLInputElement).value = "";
        (document.getElementById('numdoc') as HTMLInputElement).value = "";
        (document.getElementById('persona') as HTMLInputElement).value = "0";
        (document.getElementById('evento') as HTMLInputElement).value = "-1";
        (document.getElementById('tipdocident') as HTMLInputElement).value = "";
        (document.getElementById('nacionalidad') as HTMLInputElement).value = "";
        (document.getElementById('fecha_nacimiento') as HTMLInputElement).value = "";
        this.btnModalPre.nativeElement.click();
      }
      else if (datos.codigo === -2) {
        (document.getElementById('apellido_paterno') as HTMLInputElement).value = "";
        (document.getElementById('apellido_materno') as HTMLInputElement).value = "";
        (document.getElementById('nombre1') as HTMLInputElement).value = "";
        (document.getElementById('nombre2') as HTMLInputElement).value = "";
        (document.getElementById('email') as HTMLInputElement).value = "";
        (document.getElementById('numdoc') as HTMLInputElement).value = "";
        (document.getElementById('persona') as HTMLInputElement).value = "0";
        (document.getElementById('evento') as HTMLInputElement).value = "-1";
        (document.getElementById('tipdocident') as HTMLInputElement).value = "";
        (document.getElementById('nacionalidad') as HTMLInputElement).value = "";
        (document.getElementById('fecha_nacimiento') as HTMLInputElement).value = "";
        this.toastr.error('El evento ya se encuentra con aforo maximo, seleccione otro porfavor.', 'Error');
      }
      else {
        this.toastr.error('Vuelva a intentarlo en unos minutos', 'Error');
      }
    });
  }

  nextPrev(value: number) {
    var x = (document.getElementsByClassName('tab') as HTMLCollection);
    var element = x[this.currentTab] as HTMLElement;
    if (value == 1 && !this.validateForm()) return false;
    element.style.display = "none";
    this.currentTab = this.currentTab + value;
    if (this.currentTab >= x.length) {
      return false;
    }
    this.showTab(this.currentTab);
  }

  validateForm() {
    var x, y, i, valid = true;
    x = document.getElementsByClassName("tab");
    y = x[this.currentTab].getElementsByTagName("input");
    for (i = 0; i < y.length; i++) {
      y[i].classList.remove("invalid");
      if (y[i].value == "" && y[i].required) {
        y[i].className += " invalid";
        valid = false;
      }
    }
    y = x[this.currentTab].getElementsByTagName("select");
    for (i = 0; i < y.length; i++) {
      y[i].classList.remove("invalid");
      if ((y[i].value == "" || y[i].value == "-1") && y[i].required) {
        y[i].className += " invalid";
        valid = false;
      }
    }
    if (valid) {
      document.getElementsByClassName("step")[this.currentTab].className += " finish";
    }
    return valid;
  }

  calculate_age(birth_month, birth_day, birth_year) {
    var today_date = new Date();
    var today_year = today_date.getFullYear();
    var today_month = today_date.getMonth();
    var today_day = today_date.getDate();
    var age = today_year - birth_year;

    if (today_month < (birth_month - 1)) {
      age--;
    }
    if (((birth_month - 1) == today_month) && (today_day < birth_day)) {
      age--;
    }
    return age;
  }

  showTab(n: number) {
    var x = (document.getElementsByClassName('tab') as HTMLCollection);
    var element = x[n] as HTMLElement;
    element.style.display = "block";
    if (n == 0) {
      document.getElementById("prevBtn").style.display = "none";
    } else {
      document.getElementById("prevBtn").style.display = "inline";
    }
    if (n == (x.length - 1)) {
      document.getElementById("page1Submit").style.display = "inline";
      document.getElementById("nextBtn").style.display = "none";
    } else {
      document.getElementById("nextBtn").innerHTML = "Siguiente";
      document.getElementById("page1Submit").style.display = "none";
      document.getElementById("nextBtn").style.display = "inline";
    }
    this.fixStepIndicator(n)
  }

  fixStepIndicator(n: number) {
    var i, x = document.getElementsByClassName("step");
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(" active", "");
    }
    x[n].className += " active";
  }

}
