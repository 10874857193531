import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-en-esto-creemos',
  templateUrl: './en-esto-creemos.component.html',
  styleUrls: ['./en-esto-creemos.component.css']
})
export class EnEstoCreemosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
