import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {

  route: string;

  constructor(location: Location, router: Router) {
    // within our constructor we can define our subscription
    // and then decide what to do when this event is triggered.
    // in this case I simply update my route string.
    router.events.subscribe(val => {
      // tslint:disable-next-line: triple-equals
      if (location.path() != '') {
        window.addEventListener('scroll', this.scroll, true);
      }
    });
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true);
  }



  scroll = (event): void => {
    const navbar = document.getElementsByClassName('navbar-custom');
    [].forEach.call(navbar, function (el) {
      const navHeight = el.offsetHeight;
      const topScroll = window.scrollY;
      if (navbar.length > 0) {
        if (topScroll >= navHeight) {
          el.classList.remove('navbar-transparent');
        } else {
          el.classList.add('navbar-transparent');
        }
      }
    });

  }
}
