import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  HostListener,
  HostBinding,
  Input
} from '@angular/core';

@Directive({
  selector: '[appHighlightOnHover]'
})
export class HighlightOnHoverDirective {

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2) { }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    console.log("0");
  }


  // tslint:disable-next-line: typedef
  @HostListener('mouseenter') mouseover() {
    const withWindows = window.innerWidth;
    let mobileTest = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      mobileTest = true;
    }
    if ((withWindows > 767) && (mobileTest !== true)) {
      this.renderer.addClass(this.elementRef.nativeElement, 'open');
    }
  }

  // tslint:disable-next-line: typedef
  @HostListener('mouseleave') mouseleave() {
    const withWindows = window.innerWidth;
    let mobileTest = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      mobileTest = true;
    }
    if ((withWindows > 767) && (mobileTest !== true)) {
      this.renderer.removeClass(this.elementRef.nativeElement, 'open');
    }
  }

}
